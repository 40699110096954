(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function (setImmediate){
"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; /*
                                                                                                                                                                                                                                                                               * jQuery FlexSlider v2.7.2
                                                                                                                                                                                                                                                                               * Copyright 2012 WooThemes
                                                                                                                                                                                                                                                                               * Contributing Author: Tyler Smith
                                                                                                                                                                                                                                                                               */


var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

;
(function ($) {

    var focused = true;

    //FlexSlider: Object Instance
    $.flexslider = function (el, options) {
        var slider = $(el);

        // making variables public

        //if rtl value was not passed and html is in rtl..enable it by default.
        if (typeof options.rtl == 'undefined' && $('html').attr('dir') == 'rtl') {
            options.rtl = true;
        }
        slider.vars = $.extend({}, $.flexslider.defaults, options);

        var namespace = slider.vars.namespace,
            msGesture = window.navigator && window.navigator.msPointerEnabled && window.MSGesture,
            touch = ("ontouchstart" in window || msGesture || window.DocumentTouch && document instanceof DocumentTouch) && slider.vars.touch,

        // deprecating this idea, as devices are being released with both of these events
        eventType = "click touchend MSPointerUp keyup",
            watchedEvent = "",
            watchedEventClearTimer,
            vertical = slider.vars.direction === "vertical",
            reverse = slider.vars.reverse,
            carousel = slider.vars.itemWidth > 0,
            fade = slider.vars.animation === "fade",
            asNav = slider.vars.asNavFor !== "",
            methods = {};

        // Store a reference to the slider object
        $.data(el, "flexslider", slider);

        // Private slider methods
        methods = {
            init: function init() {
                slider.animating = false;
                // Get current slide and make sure it is a number
                slider.currentSlide = parseInt(slider.vars.startAt ? slider.vars.startAt : 0, 10);
                if (isNaN(slider.currentSlide)) {
                    slider.currentSlide = 0;
                }
                slider.animatingTo = slider.currentSlide;
                slider.atEnd = slider.currentSlide === 0 || slider.currentSlide === slider.last;
                slider.containerSelector = slider.vars.selector.substr(0, slider.vars.selector.search(' '));
                slider.slides = $(slider.vars.selector, slider);
                slider.container = $(slider.containerSelector, slider);
                slider.count = slider.slides.length;
                // SYNC:
                slider.syncExists = $(slider.vars.sync).length > 0;
                // SLIDE:
                if (slider.vars.animation === "slide") {
                    slider.vars.animation = "swing";
                }
                slider.prop = vertical ? "top" : slider.vars.rtl ? "marginRight" : "marginLeft";
                slider.args = {};
                // SLIDESHOW:
                slider.manualPause = false;
                slider.stopped = false;
                //PAUSE WHEN INVISIBLE
                slider.started = false;
                slider.startTimeout = null;
                // TOUCH/USECSS:
                slider.transitions = !slider.vars.video && !fade && slider.vars.useCSS && function () {
                    var obj = document.createElement('div'),
                        props = ['perspectiveProperty', 'WebkitPerspective', 'MozPerspective', 'OPerspective', 'msPerspective'];
                    for (var i in props) {
                        if (obj.style[props[i]] !== undefined) {
                            slider.pfx = props[i].replace('Perspective', '').toLowerCase();
                            slider.prop = "-" + slider.pfx + "-transform";
                            return true;
                        }
                    }
                    return false;
                }();
                slider.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                slider.ensureAnimationEnd = '';
                // CONTROLSCONTAINER:
                if (slider.vars.controlsContainer !== "") slider.controlsContainer = $(slider.vars.controlsContainer).length > 0 && $(slider.vars.controlsContainer);
                // MANUAL:
                if (slider.vars.manualControls !== "") slider.manualControls = $(slider.vars.manualControls).length > 0 && $(slider.vars.manualControls);

                // CUSTOM DIRECTION NAV:
                if (slider.vars.customDirectionNav !== "") slider.customDirectionNav = $(slider.vars.customDirectionNav).length === 2 && $(slider.vars.customDirectionNav);

                // RANDOMIZE:
                if (slider.vars.randomize) {
                    slider.slides.sort(function () {
                        return Math.round(Math.random()) - 0.5;
                    });
                    slider.container.empty().append(slider.slides);
                }

                slider.doMath();

                // INIT
                slider.setup("init");

                // CONTROLNAV:
                if (slider.vars.controlNav) {
                    methods.controlNav.setup();
                }

                // DIRECTIONNAV:
                if (slider.vars.directionNav) {
                    methods.directionNav.setup();
                }

                // KEYBOARD:
                if (slider.vars.keyboard && ($(slider.containerSelector).length === 1 || slider.vars.multipleKeyboard)) {
                    $(document).bind('keyup', function (event) {
                        var keycode = event.keyCode;
                        if (!slider.animating && (keycode === 39 || keycode === 37)) {
                            var target = slider.vars.rtl ? keycode === 37 ? slider.getTarget('next') : keycode === 39 ? slider.getTarget('prev') : false : keycode === 39 ? slider.getTarget('next') : keycode === 37 ? slider.getTarget('prev') : false;
                            slider.flexAnimate(target, slider.vars.pauseOnAction);
                        }
                    });
                }
                // MOUSEWHEEL:
                if (slider.vars.mousewheel) {
                    slider.bind('mousewheel', function (event, delta, deltaX, deltaY) {
                        event.preventDefault();
                        var target = delta < 0 ? slider.getTarget('next') : slider.getTarget('prev');
                        slider.flexAnimate(target, slider.vars.pauseOnAction);
                    });
                }

                // PAUSEPLAY
                if (slider.vars.pausePlay) {
                    methods.pausePlay.setup();
                }

                //PAUSE WHEN INVISIBLE
                if (slider.vars.slideshow && slider.vars.pauseInvisible) {
                    methods.pauseInvisible.init();
                }

                // SLIDSESHOW
                if (slider.vars.slideshow) {
                    if (slider.vars.pauseOnHover) {
                        slider.hover(function () {
                            if (!slider.manualPlay && !slider.manualPause) {
                                slider.pause();
                            }
                        }, function () {
                            if (!slider.manualPause && !slider.manualPlay && !slider.stopped) {
                                slider.play();
                            }
                        });
                    }
                    // initialize animation
                    //If we're visible, or we don't use PageVisibility API
                    if (!slider.vars.pauseInvisible || !methods.pauseInvisible.isHidden()) {
                        slider.vars.initDelay > 0 ? slider.startTimeout = setTimeout(slider.play, slider.vars.initDelay) : slider.play();
                    }
                }

                // ASNAV:
                if (asNav) {
                    methods.asNav.setup();
                }

                // TOUCH
                if (touch && slider.vars.touch) {
                    methods.touch();
                }

                // FADE&&SMOOTHHEIGHT || SLIDE:
                if (!fade || fade && slider.vars.smoothHeight) {
                    $(window).bind("resize orientationchange focus", methods.resize);
                }

                slider.find("img").attr("draggable", "false");

                // API: start() Callback
                setTimeout(function () {
                    slider.vars.start(slider);
                }, 200);
            },
            asNav: {
                setup: function setup() {
                    slider.asNav = true;
                    slider.animatingTo = Math.floor(slider.currentSlide / slider.move);
                    slider.currentItem = slider.currentSlide;
                    slider.slides.removeClass(namespace + "active-slide").eq(slider.currentItem).addClass(namespace + "active-slide");
                    if (!msGesture) {
                        slider.slides.on(eventType, function (e) {
                            e.preventDefault();
                            var $slide = $(this),
                                target = $slide.index();
                            var posFromX;
                            if (slider.vars.rtl) {
                                posFromX = -1 * ($slide.offset().right - $(slider).scrollLeft()); // Find position of slide relative to right of slider container
                            } else {
                                posFromX = $slide.offset().left - $(slider).scrollLeft(); // Find position of slide relative to left of slider container
                            }
                            if (posFromX <= 0 && $slide.hasClass(namespace + 'active-slide')) {
                                slider.flexAnimate(slider.getTarget("prev"), true);
                            } else if (!$(slider.vars.asNavFor).data('flexslider').animating && !$slide.hasClass(namespace + "active-slide")) {
                                slider.direction = slider.currentItem < target ? "next" : "prev";
                                slider.flexAnimate(target, slider.vars.pauseOnAction, false, true, true);
                            }
                        });
                    } else {
                        el._slider = slider;
                        slider.slides.each(function () {
                            var that = this;
                            that._gesture = new MSGesture();
                            that._gesture.target = that;
                            that.addEventListener("MSPointerDown", function (e) {
                                e.preventDefault();
                                if (e.currentTarget._gesture) {
                                    e.currentTarget._gesture.addPointer(e.pointerId);
                                }
                            }, false);
                            that.addEventListener("MSGestureTap", function (e) {
                                e.preventDefault();
                                var $slide = $(this),
                                    target = $slide.index();
                                if (!$(slider.vars.asNavFor).data('flexslider').animating && !$slide.hasClass('active')) {
                                    slider.direction = slider.currentItem < target ? "next" : "prev";
                                    slider.flexAnimate(target, slider.vars.pauseOnAction, false, true, true);
                                }
                            });
                        });
                    }
                }
            },
            controlNav: {
                setup: function setup() {
                    if (!slider.manualControls) {
                        methods.controlNav.setupPaging();
                    } else {
                        // MANUALCONTROLS:
                        methods.controlNav.setupManual();
                    }
                },
                setupPaging: function setupPaging() {
                    var type = slider.vars.controlNav === "thumbnails" ? 'control-thumbs' : 'control-paging',
                        j = 1,
                        item,
                        slide;

                    slider.controlNavScaffold = $('<ol class="' + namespace + 'control-nav ' + namespace + type + '"></ol>');

                    if (slider.pagingCount > 1) {
                        for (var i = 0; i < slider.pagingCount; i++) {
                            slide = slider.slides.eq(i);

                            if (undefined === slide.attr('data-thumb-alt')) {
                                slide.attr('data-thumb-alt', '');
                            }

                            item = $('<a></a>').attr('href', '#').text(j);
                            if (slider.vars.controlNav === "thumbnails") {
                                item = $('<img/>').attr('src', slide.attr('data-thumb'));
                            }

                            if ('' !== slide.attr('data-thumb-alt')) {
                                item.attr('alt', slide.attr('data-thumb-alt'));
                            }

                            if ('thumbnails' === slider.vars.controlNav && true === slider.vars.thumbCaptions) {
                                var captn = slide.attr('data-thumbcaption');
                                if ('' !== captn && undefined !== captn) {
                                    var caption = $('<span></span>').addClass(namespace + 'caption').text(captn);
                                    item.append(caption);
                                }
                            }

                            var liElement = $('<li>');
                            item.appendTo(liElement);
                            liElement.append('</li>');

                            slider.controlNavScaffold.append(liElement);
                            j++;
                        }
                    }

                    // CONTROLSCONTAINER:
                    slider.controlsContainer ? $(slider.controlsContainer).append(slider.controlNavScaffold) : slider.append(slider.controlNavScaffold);
                    methods.controlNav.set();

                    methods.controlNav.active();

                    slider.controlNavScaffold.delegate('a, img', eventType, function (event) {
                        event.preventDefault();

                        if (watchedEvent === "" || watchedEvent === event.type) {
                            var $this = $(this),
                                target = slider.controlNav.index($this);

                            if (!$this.hasClass(namespace + 'active')) {
                                slider.direction = target > slider.currentSlide ? "next" : "prev";
                                slider.flexAnimate(target, slider.vars.pauseOnAction);
                            }
                        }

                        // setup flags to prevent event duplication
                        if (watchedEvent === "") {
                            watchedEvent = event.type;
                        }
                        methods.setToClearWatchedEvent();
                    });
                },
                setupManual: function setupManual() {
                    slider.controlNav = slider.manualControls;
                    methods.controlNav.active();

                    slider.controlNav.bind(eventType, function (event) {
                        event.preventDefault();

                        if (watchedEvent === "" || watchedEvent === event.type) {
                            var $this = $(this),
                                target = slider.controlNav.index($this);

                            if (!$this.hasClass(namespace + 'active')) {
                                target > slider.currentSlide ? slider.direction = "next" : slider.direction = "prev";
                                slider.flexAnimate(target, slider.vars.pauseOnAction);
                            }
                        }

                        // setup flags to prevent event duplication
                        if (watchedEvent === "") {
                            watchedEvent = event.type;
                        }
                        methods.setToClearWatchedEvent();
                    });
                },
                set: function set() {
                    var selector = slider.vars.controlNav === "thumbnails" ? 'img' : 'a';
                    slider.controlNav = $('.' + namespace + 'control-nav li ' + selector, slider.controlsContainer ? slider.controlsContainer : slider);
                },
                active: function active() {
                    slider.controlNav.removeClass(namespace + "active").eq(slider.animatingTo).addClass(namespace + "active");
                },
                update: function update(action, pos) {
                    if (slider.pagingCount > 1 && action === "add") {
                        slider.controlNavScaffold.append($('<li><a href="#">' + slider.count + '</a></li>'));
                    } else if (slider.pagingCount === 1) {
                        slider.controlNavScaffold.find('li').remove();
                    } else {
                        slider.controlNav.eq(pos).closest('li').remove();
                    }
                    methods.controlNav.set();
                    slider.pagingCount > 1 && slider.pagingCount !== slider.controlNav.length ? slider.update(pos, action) : methods.controlNav.active();
                }
            },
            directionNav: {
                setup: function setup() {
                    var directionNavScaffold = $('<ul class="' + namespace + 'direction-nav"><li class="' + namespace + 'nav-prev"><a class="' + namespace + 'prev" href="#">' + slider.vars.prevText + '</a></li><li class="' + namespace + 'nav-next"><a class="' + namespace + 'next" href="#">' + slider.vars.nextText + '</a></li></ul>');

                    // CUSTOM DIRECTION NAV:
                    if (slider.customDirectionNav) {
                        slider.directionNav = slider.customDirectionNav;
                        // CONTROLSCONTAINER:
                    } else if (slider.controlsContainer) {
                        $(slider.controlsContainer).append(directionNavScaffold);
                        slider.directionNav = $('.' + namespace + 'direction-nav li a', slider.controlsContainer);
                    } else {
                        slider.append(directionNavScaffold);
                        slider.directionNav = $('.' + namespace + 'direction-nav li a', slider);
                    }

                    methods.directionNav.update();

                    slider.directionNav.bind(eventType, function (event) {
                        event.preventDefault();
                        var target;

                        if (watchedEvent === "" || watchedEvent === event.type) {
                            target = $(this).hasClass(namespace + 'next') ? slider.getTarget('next') : slider.getTarget('prev');
                            slider.flexAnimate(target, slider.vars.pauseOnAction);
                        }

                        // setup flags to prevent event duplication
                        if (watchedEvent === "") {
                            watchedEvent = event.type;
                        }
                        methods.setToClearWatchedEvent();
                    });
                },
                update: function update() {
                    var disabledClass = namespace + 'disabled';
                    if (slider.pagingCount === 1) {
                        slider.directionNav.addClass(disabledClass).attr('tabindex', '-1');
                    } else if (!slider.vars.animationLoop) {
                        if (slider.animatingTo === 0) {
                            slider.directionNav.removeClass(disabledClass).filter('.' + namespace + "prev").addClass(disabledClass).attr('tabindex', '-1');
                        } else if (slider.animatingTo === slider.last) {
                            slider.directionNav.removeClass(disabledClass).filter('.' + namespace + "next").addClass(disabledClass).attr('tabindex', '-1');
                        } else {
                            slider.directionNav.removeClass(disabledClass).removeAttr('tabindex');
                        }
                    } else {
                        slider.directionNav.removeClass(disabledClass).removeAttr('tabindex');
                    }
                }
            },
            pausePlay: {
                setup: function setup() {
                    var pausePlayScaffold = $('<div class="' + namespace + 'pauseplay"><a href="#"></a></div>');

                    // CONTROLSCONTAINER:
                    if (slider.controlsContainer) {
                        slider.controlsContainer.append(pausePlayScaffold);
                        slider.pausePlay = $('.' + namespace + 'pauseplay a', slider.controlsContainer);
                    } else {
                        slider.append(pausePlayScaffold);
                        slider.pausePlay = $('.' + namespace + 'pauseplay a', slider);
                    }

                    methods.pausePlay.update(slider.vars.slideshow ? namespace + 'pause' : namespace + 'play');

                    slider.pausePlay.bind(eventType, function (event) {
                        event.preventDefault();

                        if (watchedEvent === "" || watchedEvent === event.type) {
                            if ($(this).hasClass(namespace + 'pause')) {
                                slider.manualPause = true;
                                slider.manualPlay = false;
                                slider.pause();
                            } else {
                                slider.manualPause = false;
                                slider.manualPlay = true;
                                slider.play();
                            }
                        }

                        // setup flags to prevent event duplication
                        if (watchedEvent === "") {
                            watchedEvent = event.type;
                        }
                        methods.setToClearWatchedEvent();
                    });
                },
                update: function update(state) {
                    state === "play" ? slider.pausePlay.removeClass(namespace + 'pause').addClass(namespace + 'play').html(slider.vars.playText) : slider.pausePlay.removeClass(namespace + 'play').addClass(namespace + 'pause').html(slider.vars.pauseText);
                }
            },
            touch: function touch() {
                var startX,
                    startY,
                    offset,
                    cwidth,
                    dx,
                    startT,
                    onTouchStart,
                    onTouchMove,
                    _onTouchEnd,
                    scrolling = false,
                    localX = 0,
                    localY = 0,
                    accDx = 0;

                if (!msGesture) {
                    onTouchStart = function onTouchStart(e) {
                        if (slider.animating) {
                            e.preventDefault();
                        } else if (window.navigator.msPointerEnabled || e.touches.length === 1) {
                            slider.pause();
                            // CAROUSEL:
                            cwidth = vertical ? slider.h : slider.w;
                            startT = Number(new Date());
                            // CAROUSEL:

                            // Local vars for X and Y points.
                            localX = e.touches[0].pageX;
                            localY = e.touches[0].pageY;

                            offset = carousel && reverse && slider.animatingTo === slider.last ? 0 : carousel && reverse ? slider.limit - (slider.itemW + slider.vars.itemMargin) * slider.move * slider.animatingTo : carousel && slider.currentSlide === slider.last ? slider.limit : carousel ? (slider.itemW + slider.vars.itemMargin) * slider.move * slider.currentSlide : reverse ? (slider.last - slider.currentSlide + slider.cloneOffset) * cwidth : (slider.currentSlide + slider.cloneOffset) * cwidth;
                            startX = vertical ? localY : localX;
                            startY = vertical ? localX : localY;
                            el.addEventListener('touchmove', onTouchMove, false);
                            el.addEventListener('touchend', _onTouchEnd, false);
                        }
                    };

                    onTouchMove = function onTouchMove(e) {
                        // Local vars for X and Y points.

                        localX = e.touches[0].pageX;
                        localY = e.touches[0].pageY;

                        dx = vertical ? startX - localY : (slider.vars.rtl ? -1 : 1) * (startX - localX);
                        scrolling = vertical ? Math.abs(dx) < Math.abs(localX - startY) : Math.abs(dx) < Math.abs(localY - startY);
                        var fxms = 500;

                        if (!scrolling || Number(new Date()) - startT > fxms) {
                            e.preventDefault();
                            if (!fade && slider.transitions) {
                                if (!slider.vars.animationLoop) {
                                    dx = dx / (slider.currentSlide === 0 && dx < 0 || slider.currentSlide === slider.last && dx > 0 ? Math.abs(dx) / cwidth + 2 : 1);
                                }
                                slider.setProps(offset + dx, "setTouch");
                            }
                        }
                    };

                    _onTouchEnd = function onTouchEnd(e) {
                        // finish the touch by undoing the touch session
                        el.removeEventListener('touchmove', onTouchMove, false);

                        if (slider.animatingTo === slider.currentSlide && !scrolling && !(dx === null)) {
                            var updateDx = reverse ? -dx : dx,
                                target = updateDx > 0 ? slider.getTarget('next') : slider.getTarget('prev');

                            if (slider.canAdvance(target) && (Number(new Date()) - startT < 550 && Math.abs(updateDx) > 50 || Math.abs(updateDx) > cwidth / 2)) {
                                slider.flexAnimate(target, slider.vars.pauseOnAction);
                            } else {
                                if (!fade) {
                                    slider.flexAnimate(slider.currentSlide, slider.vars.pauseOnAction, true);
                                }
                            }
                        }
                        el.removeEventListener('touchend', _onTouchEnd, false);

                        startX = null;
                        startY = null;
                        dx = null;
                        offset = null;
                    };

                    el.addEventListener('touchstart', onTouchStart, false);
                } else {
                    var onMSPointerDown = function onMSPointerDown(e) {
                        e.stopPropagation();
                        if (slider.animating) {
                            e.preventDefault();
                        } else {
                            slider.pause();
                            el._gesture.addPointer(e.pointerId);
                            accDx = 0;
                            cwidth = vertical ? slider.h : slider.w;
                            startT = Number(new Date());
                            // CAROUSEL:

                            offset = carousel && reverse && slider.animatingTo === slider.last ? 0 : carousel && reverse ? slider.limit - (slider.itemW + slider.vars.itemMargin) * slider.move * slider.animatingTo : carousel && slider.currentSlide === slider.last ? slider.limit : carousel ? (slider.itemW + slider.vars.itemMargin) * slider.move * slider.currentSlide : reverse ? (slider.last - slider.currentSlide + slider.cloneOffset) * cwidth : (slider.currentSlide + slider.cloneOffset) * cwidth;
                        }
                    };

                    var onMSGestureChange = function onMSGestureChange(e) {
                        e.stopPropagation();
                        var slider = e.target._slider;
                        if (!slider) {
                            return;
                        }
                        var transX = -e.translationX,
                            transY = -e.translationY;

                        //Accumulate translations.
                        accDx = accDx + (vertical ? transY : transX);
                        dx = (slider.vars.rtl ? -1 : 1) * accDx;
                        scrolling = vertical ? Math.abs(accDx) < Math.abs(-transX) : Math.abs(accDx) < Math.abs(-transY);

                        if (e.detail === e.MSGESTURE_FLAG_INERTIA) {
                            setImmediate(function () {
                                el._gesture.stop();
                            });

                            return;
                        }

                        if (!scrolling || Number(new Date()) - startT > 500) {
                            e.preventDefault();
                            if (!fade && slider.transitions) {
                                if (!slider.vars.animationLoop) {
                                    dx = accDx / (slider.currentSlide === 0 && accDx < 0 || slider.currentSlide === slider.last && accDx > 0 ? Math.abs(accDx) / cwidth + 2 : 1);
                                }
                                slider.setProps(offset + dx, "setTouch");
                            }
                        }
                    };

                    var onMSGestureEnd = function onMSGestureEnd(e) {
                        e.stopPropagation();
                        var slider = e.target._slider;
                        if (!slider) {
                            return;
                        }
                        if (slider.animatingTo === slider.currentSlide && !scrolling && !(dx === null)) {
                            var updateDx = reverse ? -dx : dx,
                                target = updateDx > 0 ? slider.getTarget('next') : slider.getTarget('prev');

                            if (slider.canAdvance(target) && (Number(new Date()) - startT < 550 && Math.abs(updateDx) > 50 || Math.abs(updateDx) > cwidth / 2)) {
                                slider.flexAnimate(target, slider.vars.pauseOnAction);
                            } else {
                                if (!fade) {
                                    slider.flexAnimate(slider.currentSlide, slider.vars.pauseOnAction, true);
                                }
                            }
                        }

                        startX = null;
                        startY = null;
                        dx = null;
                        offset = null;
                        accDx = 0;
                    };

                    el.style.msTouchAction = "none";
                    el._gesture = new MSGesture();
                    el._gesture.target = el;
                    el.addEventListener("MSPointerDown", onMSPointerDown, false);
                    el._slider = slider;
                    el.addEventListener("MSGestureChange", onMSGestureChange, false);
                    el.addEventListener("MSGestureEnd", onMSGestureEnd, false);
                }
            },
            resize: function resize() {
                if (!slider.animating && slider.is(':visible')) {
                    if (!carousel) {
                        slider.doMath();
                    }

                    if (fade) {
                        // SMOOTH HEIGHT:
                        methods.smoothHeight();
                    } else if (carousel) {
                        //CAROUSEL:
                        slider.slides.width(slider.computedW);
                        slider.update(slider.pagingCount);
                        slider.setProps();
                    } else if (vertical) {
                        //VERTICAL:
                        slider.viewport.height(slider.h);
                        slider.setProps(slider.h, "setTotal");
                    } else {
                        // SMOOTH HEIGHT:
                        if (slider.vars.smoothHeight) {
                            methods.smoothHeight();
                        }
                        slider.newSlides.width(slider.computedW);
                        slider.setProps(slider.computedW, "setTotal");
                    }
                }
            },
            smoothHeight: function smoothHeight(dur) {
                if (!vertical || fade) {
                    var $obj = fade ? slider : slider.viewport;
                    dur ? $obj.animate({ "height": slider.slides.eq(slider.animatingTo).innerHeight() }, dur) : $obj.innerHeight(slider.slides.eq(slider.animatingTo).innerHeight());
                }
            },
            sync: function sync(action) {
                var $obj = $(slider.vars.sync).data("flexslider"),
                    target = slider.animatingTo;

                switch (action) {
                    case "animate":
                        $obj.flexAnimate(target, slider.vars.pauseOnAction, false, true);break;
                    case "play":
                        if (!$obj.playing && !$obj.asNav) {
                            $obj.play();
                        }break;
                    case "pause":
                        $obj.pause();break;
                }
            },
            uniqueID: function uniqueID($clone) {
                // Append _clone to current level and children elements with id attributes
                $clone.filter('[id]').add($clone.find('[id]')).each(function () {
                    var $this = $(this);
                    $this.attr('id', $this.attr('id') + '_clone');
                });
                return $clone;
            },
            pauseInvisible: {
                visProp: null,
                init: function init() {
                    var visProp = methods.pauseInvisible.getHiddenProp();
                    if (visProp) {
                        var evtname = visProp.replace(/[H|h]idden/, '') + 'visibilitychange';
                        document.addEventListener(evtname, function () {
                            if (methods.pauseInvisible.isHidden()) {
                                if (slider.startTimeout) {
                                    clearTimeout(slider.startTimeout); //If clock is ticking, stop timer and prevent from starting while invisible
                                } else {
                                    slider.pause(); //Or just pause
                                }
                            } else {
                                if (slider.started) {
                                    slider.play(); //Initiated before, just play
                                } else {
                                    if (slider.vars.initDelay > 0) {
                                        setTimeout(slider.play, slider.vars.initDelay);
                                    } else {
                                        slider.play(); //Didn't init before: simply init or wait for it
                                    }
                                }
                            }
                        });
                    }
                },
                isHidden: function isHidden() {
                    var prop = methods.pauseInvisible.getHiddenProp();
                    if (!prop) {
                        return false;
                    }
                    return document[prop];
                },
                getHiddenProp: function getHiddenProp() {
                    var prefixes = ['webkit', 'moz', 'ms', 'o'];
                    // if 'hidden' is natively supported just return it
                    if ('hidden' in document) {
                        return 'hidden';
                    }
                    // otherwise loop over all the known prefixes until we find one
                    for (var i = 0; i < prefixes.length; i++) {
                        if (prefixes[i] + 'Hidden' in document) {
                            return prefixes[i] + 'Hidden';
                        }
                    }
                    // otherwise it's not supported
                    return null;
                }
            },
            setToClearWatchedEvent: function setToClearWatchedEvent() {
                clearTimeout(watchedEventClearTimer);
                watchedEventClearTimer = setTimeout(function () {
                    watchedEvent = "";
                }, 3000);
            }
        };

        // public methods
        slider.flexAnimate = function (target, pause, override, withSync, fromNav) {
            if (!slider.vars.animationLoop && target !== slider.currentSlide) {
                slider.direction = target > slider.currentSlide ? "next" : "prev";
            }

            if (asNav && slider.pagingCount === 1) slider.direction = slider.currentItem < target ? "next" : "prev";

            if (!slider.animating && (slider.canAdvance(target, fromNav) || override) && slider.is(":visible")) {
                if (asNav && withSync) {
                    var master = $(slider.vars.asNavFor).data('flexslider');
                    slider.atEnd = target === 0 || target === slider.count - 1;
                    master.flexAnimate(target, true, false, true, fromNav);
                    slider.direction = slider.currentItem < target ? "next" : "prev";
                    master.direction = slider.direction;

                    if (Math.ceil((target + 1) / slider.visible) - 1 !== slider.currentSlide && target !== 0) {
                        slider.currentItem = target;
                        slider.slides.removeClass(namespace + "active-slide").eq(target).addClass(namespace + "active-slide");
                        target = Math.floor(target / slider.visible);
                    } else {
                        slider.currentItem = target;
                        slider.slides.removeClass(namespace + "active-slide").eq(target).addClass(namespace + "active-slide");
                        return false;
                    }
                }

                slider.animating = true;
                slider.animatingTo = target;

                // SLIDESHOW:
                if (pause) {
                    slider.pause();
                }

                // API: before() animation Callback
                slider.vars.before(slider);

                // SYNC:
                if (slider.syncExists && !fromNav) {
                    methods.sync("animate");
                }

                // CONTROLNAV
                if (slider.vars.controlNav) {
                    methods.controlNav.active();
                }

                // !CAROUSEL:
                // CANDIDATE: slide active class (for add/remove slide)
                if (!carousel) {
                    slider.slides.removeClass(namespace + 'active-slide').eq(target).addClass(namespace + 'active-slide');
                }

                // INFINITE LOOP:
                // CANDIDATE: atEnd
                slider.atEnd = target === 0 || target === slider.last;

                // DIRECTIONNAV:
                if (slider.vars.directionNav) {
                    methods.directionNav.update();
                }

                if (target === slider.last) {
                    // API: end() of cycle Callback
                    slider.vars.end(slider);
                    // SLIDESHOW && !INFINITE LOOP:
                    if (!slider.vars.animationLoop) {
                        slider.pause();
                    }
                }

                // SLIDE:
                if (!fade) {
                    var dimension = vertical ? slider.slides.filter(':first').height() : slider.computedW,
                        margin,
                        slideString,
                        calcNext;

                    // INFINITE LOOP / REVERSE:
                    if (carousel) {
                        margin = slider.vars.itemMargin;
                        calcNext = (slider.itemW + margin) * slider.move * slider.animatingTo;
                        slideString = calcNext > slider.limit && slider.visible !== 1 ? slider.limit : calcNext;
                    } else if (slider.currentSlide === 0 && target === slider.count - 1 && slider.vars.animationLoop && slider.direction !== "next") {
                        slideString = reverse ? (slider.count + slider.cloneOffset) * dimension : 0;
                    } else if (slider.currentSlide === slider.last && target === 0 && slider.vars.animationLoop && slider.direction !== "prev") {
                        slideString = reverse ? 0 : (slider.count + 1) * dimension;
                    } else {
                        slideString = reverse ? (slider.count - 1 - target + slider.cloneOffset) * dimension : (target + slider.cloneOffset) * dimension;
                    }
                    slider.setProps(slideString, "", slider.vars.animationSpeed);
                    if (slider.transitions) {
                        if (!slider.vars.animationLoop || !slider.atEnd) {
                            slider.animating = false;
                            slider.currentSlide = slider.animatingTo;
                        }

                        // Unbind previous transitionEnd events and re-bind new transitionEnd event
                        slider.container.unbind("webkitTransitionEnd transitionend");
                        slider.container.bind("webkitTransitionEnd transitionend", function () {
                            clearTimeout(slider.ensureAnimationEnd);
                            slider.wrapup(dimension);
                        });

                        // Insurance for the ever-so-fickle transitionEnd event
                        clearTimeout(slider.ensureAnimationEnd);
                        slider.ensureAnimationEnd = setTimeout(function () {
                            slider.wrapup(dimension);
                        }, slider.vars.animationSpeed + 100);
                    } else {
                        slider.container.animate(slider.args, slider.vars.animationSpeed, slider.vars.easing, function () {
                            slider.wrapup(dimension);
                        });
                    }
                } else {
                    // FADE:
                    if (!touch) {
                        slider.slides.eq(slider.currentSlide).css({ "zIndex": 1 }).animate({ "opacity": 0 }, slider.vars.animationSpeed, slider.vars.easing);
                        slider.slides.eq(target).css({ "zIndex": 2 }).animate({ "opacity": 1 }, slider.vars.animationSpeed, slider.vars.easing, slider.wrapup);
                    } else {
                        slider.slides.eq(slider.currentSlide).css({ "opacity": 0, "zIndex": 1 });
                        slider.slides.eq(target).css({ "opacity": 1, "zIndex": 2 });
                        slider.wrapup(dimension);
                    }
                }
                // SMOOTH HEIGHT:
                if (slider.vars.smoothHeight) {
                    methods.smoothHeight(slider.vars.animationSpeed);
                }
            }
        };
        slider.wrapup = function (dimension) {
            // SLIDE:
            if (!fade && !carousel) {
                if (slider.currentSlide === 0 && slider.animatingTo === slider.last && slider.vars.animationLoop) {
                    slider.setProps(dimension, "jumpEnd");
                } else if (slider.currentSlide === slider.last && slider.animatingTo === 0 && slider.vars.animationLoop) {
                    slider.setProps(dimension, "jumpStart");
                }
            }
            slider.animating = false;
            slider.currentSlide = slider.animatingTo;
            // API: after() animation Callback
            slider.vars.after(slider);
        };

        // SLIDESHOW:
        slider.animateSlides = function () {
            if (!slider.animating && focused) {
                slider.flexAnimate(slider.getTarget("next"));
            }
        };
        // SLIDESHOW:
        slider.pause = function () {
            clearInterval(slider.animatedSlides);
            slider.animatedSlides = null;
            slider.playing = false;
            // PAUSEPLAY:
            if (slider.vars.pausePlay) {
                methods.pausePlay.update("play");
            }
            // SYNC:
            if (slider.syncExists) {
                methods.sync("pause");
            }
        };
        // SLIDESHOW:
        slider.play = function () {
            if (slider.playing) {
                clearInterval(slider.animatedSlides);
            }
            slider.animatedSlides = slider.animatedSlides || setInterval(slider.animateSlides, slider.vars.slideshowSpeed);
            slider.started = slider.playing = true;
            // PAUSEPLAY:
            if (slider.vars.pausePlay) {
                methods.pausePlay.update("pause");
            }
            // SYNC:
            if (slider.syncExists) {
                methods.sync("play");
            }
        };
        // STOP:
        slider.stop = function () {
            slider.pause();
            slider.stopped = true;
        };
        slider.canAdvance = function (target, fromNav) {
            // ASNAV:
            var last = asNav ? slider.pagingCount - 1 : slider.last;
            return fromNav ? true : asNav && slider.currentItem === slider.count - 1 && target === 0 && slider.direction === "prev" ? true : asNav && slider.currentItem === 0 && target === slider.pagingCount - 1 && slider.direction !== "next" ? false : target === slider.currentSlide && !asNav ? false : slider.vars.animationLoop ? true : slider.atEnd && slider.currentSlide === 0 && target === last && slider.direction !== "next" ? false : slider.atEnd && slider.currentSlide === last && target === 0 && slider.direction === "next" ? false : true;
        };
        slider.getTarget = function (dir) {
            slider.direction = dir;
            if (dir === "next") {
                return slider.currentSlide === slider.last ? 0 : slider.currentSlide + 1;
            } else {
                return slider.currentSlide === 0 ? slider.last : slider.currentSlide - 1;
            }
        };

        // SLIDE:
        slider.setProps = function (pos, special, dur) {
            var target = function () {
                var posCheck = pos ? pos : (slider.itemW + slider.vars.itemMargin) * slider.move * slider.animatingTo,
                    posCalc = function () {
                    if (carousel) {
                        return special === "setTouch" ? pos : reverse && slider.animatingTo === slider.last ? 0 : reverse ? slider.limit - (slider.itemW + slider.vars.itemMargin) * slider.move * slider.animatingTo : slider.animatingTo === slider.last ? slider.limit : posCheck;
                    } else {
                        switch (special) {
                            case "setTotal":
                                return reverse ? (slider.count - 1 - slider.currentSlide + slider.cloneOffset) * pos : (slider.currentSlide + slider.cloneOffset) * pos;
                            case "setTouch":
                                return reverse ? pos : pos;
                            case "jumpEnd":
                                return reverse ? pos : slider.count * pos;
                            case "jumpStart":
                                return reverse ? slider.count * pos : pos;
                            default:
                                return pos;
                        }
                    }
                }();

                return posCalc * (slider.vars.rtl ? 1 : -1) + "px";
            }();

            if (slider.transitions) {
                if (slider.isFirefox) {
                    target = vertical ? "translate3d(0," + target + ",0)" : "translate3d(" + (parseInt(target) + 'px') + ",0,0)";
                } else {
                    target = vertical ? "translate3d(0," + target + ",0)" : "translate3d(" + ((slider.vars.rtl ? -1 : 1) * parseInt(target) + 'px') + ",0,0)";
                }
                dur = dur !== undefined ? dur / 1000 + "s" : "0s";
                slider.container.css("-" + slider.pfx + "-transition-duration", dur);
                slider.container.css("transition-duration", dur);
            }

            slider.args[slider.prop] = target;
            if (slider.transitions || dur === undefined) {
                slider.container.css(slider.args);
            }

            slider.container.css('transform', target);
        };

        slider.setup = function (type) {
            // SLIDE:
            if (!fade) {
                var sliderOffset, arr;

                if (type === "init") {
                    slider.viewport = $('<div class="' + namespace + 'viewport"></div>').css({ "overflow": "hidden", "position": "relative" }).appendTo(slider).append(slider.container);
                    // INFINITE LOOP:
                    slider.cloneCount = 0;
                    slider.cloneOffset = 0;
                    // REVERSE:
                    if (reverse) {
                        arr = $.makeArray(slider.slides).reverse();
                        slider.slides = $(arr);
                        slider.container.empty().append(slider.slides);
                    }
                }
                // INFINITE LOOP && !CAROUSEL:
                if (slider.vars.animationLoop && !carousel) {
                    slider.cloneCount = 2;
                    slider.cloneOffset = 1;
                    // clear out old clones
                    if (type !== "init") {
                        slider.container.find('.clone').remove();
                    }
                    slider.container.append(methods.uniqueID(slider.slides.first().clone().addClass('clone')).attr('aria-hidden', 'true')).prepend(methods.uniqueID(slider.slides.last().clone().addClass('clone')).attr('aria-hidden', 'true'));
                }
                slider.newSlides = $(slider.vars.selector, slider);

                sliderOffset = reverse ? slider.count - 1 - slider.currentSlide + slider.cloneOffset : slider.currentSlide + slider.cloneOffset;
                // VERTICAL:
                if (vertical && !carousel) {
                    slider.container.height((slider.count + slider.cloneCount) * 200 + "%").css("position", "absolute").width("100%");
                    setTimeout(function () {
                        slider.newSlides.css({ "display": "block" });
                        slider.doMath();
                        slider.viewport.height(slider.h);
                        slider.setProps(sliderOffset * slider.h, "init");
                    }, type === "init" ? 100 : 0);
                } else {
                    slider.container.width((slider.count + slider.cloneCount) * 200 + "%");
                    slider.setProps(sliderOffset * slider.computedW, "init");
                    setTimeout(function () {
                        slider.doMath();
                        if (slider.vars.rtl) {
                            if (slider.isFirefox) {
                                slider.newSlides.css({ "width": slider.computedW, "marginRight": slider.computedM, "float": "right", "display": "block" });
                            } else {
                                slider.newSlides.css({ "width": slider.computedW, "marginRight": slider.computedM, "float": "left", "display": "block" });
                            }
                        } else {
                            slider.newSlides.css({ "width": slider.computedW, "marginRight": slider.computedM, "float": "left", "display": "block" });
                        }
                        // SMOOTH HEIGHT:
                        if (slider.vars.smoothHeight) {
                            methods.smoothHeight();
                        }
                    }, type === "init" ? 100 : 0);
                }
            } else {
                // FADE:
                if (slider.vars.rtl) {
                    slider.slides.css({ "width": "100%", "float": 'right', "marginLeft": "-100%", "position": "relative" });
                } else {
                    slider.slides.css({ "width": "100%", "float": 'left', "marginRight": "-100%", "position": "relative" });
                }
                if (type === "init") {
                    if (!touch) {
                        //slider.slides.eq(slider.currentSlide).fadeIn(slider.vars.animationSpeed, slider.vars.easing);
                        if (slider.vars.fadeFirstSlide == false) {
                            slider.slides.css({ "opacity": 0, "display": "block", "zIndex": 1 }).eq(slider.currentSlide).css({ "zIndex": 2 }).css({ "opacity": 1 });
                        } else {
                            slider.slides.css({ "opacity": 0, "display": "block", "zIndex": 1 }).eq(slider.currentSlide).css({ "zIndex": 2 }).animate({ "opacity": 1 }, slider.vars.animationSpeed, slider.vars.easing);
                        }
                    } else {
                        slider.slides.css({ "opacity": 0, "display": "block", "webkitTransition": "opacity " + slider.vars.animationSpeed / 1000 + "s ease", "zIndex": 1 }).eq(slider.currentSlide).css({ "opacity": 1, "zIndex": 2 });
                    }
                }
                // SMOOTH HEIGHT:
                if (slider.vars.smoothHeight) {
                    methods.smoothHeight();
                }
            }
            // !CAROUSEL:
            // CANDIDATE: active slide
            if (!carousel) {
                slider.slides.removeClass(namespace + "active-slide").eq(slider.currentSlide).addClass(namespace + "active-slide");
            }

            //FlexSlider: init() Callback
            slider.vars.init(slider);
        };

        slider.doMath = function () {
            var slide = slider.slides.first(),
                slideMargin = slider.vars.itemMargin,
                minItems = slider.vars.minItems,
                maxItems = slider.vars.maxItems;

            slider.w = slider.viewport === undefined ? slider.width() : slider.viewport.width();
            if (slider.isFirefox) {
                slider.w = slider.width();
            }
            slider.h = slide.height();
            slider.boxPadding = slide.outerWidth() - slide.width();

            // CAROUSEL:
            if (carousel) {
                slider.itemT = slider.vars.itemWidth + slideMargin;
                slider.itemM = slideMargin;
                slider.minW = minItems ? minItems * slider.itemT : slider.w;
                slider.maxW = maxItems ? maxItems * slider.itemT - slideMargin : slider.w;
                slider.itemW = slider.minW > slider.w ? (slider.w - slideMargin * (minItems - 1)) / minItems : slider.maxW < slider.w ? (slider.w - slideMargin * (maxItems - 1)) / maxItems : slider.vars.itemWidth > slider.w ? slider.w : slider.vars.itemWidth;

                slider.visible = Math.floor(slider.w / slider.itemW);
                slider.move = slider.vars.move > 0 && slider.vars.move < slider.visible ? slider.vars.move : slider.visible;
                slider.pagingCount = Math.ceil((slider.count - slider.visible) / slider.move + 1);
                slider.last = slider.pagingCount - 1;
                slider.limit = slider.pagingCount === 1 ? 0 : slider.vars.itemWidth > slider.w ? slider.itemW * (slider.count - 1) + slideMargin * (slider.count - 1) : (slider.itemW + slideMargin) * slider.count - slider.w - slideMargin;
            } else {
                slider.itemW = slider.w;
                slider.itemM = slideMargin;
                slider.pagingCount = slider.count;
                slider.last = slider.count - 1;
            }
            slider.computedW = slider.itemW - slider.boxPadding;
            slider.computedM = slider.itemM;
        };

        slider.update = function (pos, action) {
            slider.doMath();

            // update currentSlide and slider.animatingTo if necessary
            if (!carousel) {
                if (pos < slider.currentSlide) {
                    slider.currentSlide += 1;
                } else if (pos <= slider.currentSlide && pos !== 0) {
                    slider.currentSlide -= 1;
                }
                slider.animatingTo = slider.currentSlide;
            }

            // update controlNav
            if (slider.vars.controlNav && !slider.manualControls) {
                if (action === "add" && !carousel || slider.pagingCount > slider.controlNav.length) {
                    methods.controlNav.update("add");
                } else if (action === "remove" && !carousel || slider.pagingCount < slider.controlNav.length) {
                    if (carousel && slider.currentSlide > slider.last) {
                        slider.currentSlide -= 1;
                        slider.animatingTo -= 1;
                    }
                    methods.controlNav.update("remove", slider.last);
                }
            }
            // update directionNav
            if (slider.vars.directionNav) {
                methods.directionNav.update();
            }
        };

        slider.addSlide = function (obj, pos) {
            var $obj = $(obj);

            slider.count += 1;
            slider.last = slider.count - 1;

            // append new slide
            if (vertical && reverse) {
                pos !== undefined ? slider.slides.eq(slider.count - pos).after($obj) : slider.container.prepend($obj);
            } else {
                pos !== undefined ? slider.slides.eq(pos).before($obj) : slider.container.append($obj);
            }

            // update currentSlide, animatingTo, controlNav, and directionNav
            slider.update(pos, "add");

            // update slider.slides
            slider.slides = $(slider.vars.selector + ':not(.clone)', slider);
            // re-setup the slider to accomdate new slide
            slider.setup();

            //FlexSlider: added() Callback
            slider.vars.added(slider);
        };
        slider.removeSlide = function (obj) {
            var pos = isNaN(obj) ? slider.slides.index($(obj)) : obj;

            // update count
            slider.count -= 1;
            slider.last = slider.count - 1;

            // remove slide
            if (isNaN(obj)) {
                $(obj, slider.slides).remove();
            } else {
                vertical && reverse ? slider.slides.eq(slider.last).remove() : slider.slides.eq(obj).remove();
            }

            // update currentSlide, animatingTo, controlNav, and directionNav
            slider.doMath();
            slider.update(pos, "remove");

            // update slider.slides
            slider.slides = $(slider.vars.selector + ':not(.clone)', slider);
            // re-setup the slider to accomdate new slide
            slider.setup();

            // FlexSlider: removed() Callback
            slider.vars.removed(slider);
        };

        //FlexSlider: Initialize
        methods.init();
    };

    // Ensure the slider isn't focussed if the window loses focus.
    $(window).blur(function (e) {
        focused = false;
    }).focus(function (e) {
        focused = true;
    });

    //FlexSlider: Default Settings
    $.flexslider.defaults = {
        namespace: "flex-", //{NEW} String: Prefix string attached to the class of every element generated by the plugin
        selector: ".slides > li", //{NEW} Selector: Must match a simple pattern. '{container} > {slide}' -- Ignore pattern at your own peril
        animation: "fade", //String: Select your animation type, "fade" or "slide"
        easing: "swing", //{NEW} String: Determines the easing method used in jQuery transitions. jQuery easing plugin is supported!
        direction: "horizontal", //String: Select the sliding direction, "horizontal" or "vertical"
        reverse: false, //{NEW} Boolean: Reverse the animation direction
        animationLoop: true, //Boolean: Should the animation loop? If false, directionNav will received "disable" classes at either end
        smoothHeight: false, //{NEW} Boolean: Allow height of the slider to animate smoothly in horizontal mode
        startAt: 0, //Integer: The slide that the slider should start on. Array notation (0 = first slide)
        slideshow: true, //Boolean: Animate slider automatically
        slideshowSpeed: 7000, //Integer: Set the speed of the slideshow cycling, in milliseconds
        animationSpeed: 600, //Integer: Set the speed of animations, in milliseconds
        initDelay: 0, //{NEW} Integer: Set an initialization delay, in milliseconds
        randomize: false, //Boolean: Randomize slide order
        fadeFirstSlide: true, //Boolean: Fade in the first slide when animation type is "fade"
        thumbCaptions: false, //Boolean: Whether or not to put captions on thumbnails when using the "thumbnails" controlNav.

        // Usability features
        pauseOnAction: true, //Boolean: Pause the slideshow when interacting with control elements, highly recommended.
        pauseOnHover: false, //Boolean: Pause the slideshow when hovering over slider, then resume when no longer hovering
        pauseInvisible: true, //{NEW} Boolean: Pause the slideshow when tab is invisible, resume when visible. Provides better UX, lower CPU usage.
        useCSS: true, //{NEW} Boolean: Slider will use CSS3 transitions if available
        touch: true, //{NEW} Boolean: Allow touch swipe navigation of the slider on touch-enabled devices
        video: false, //{NEW} Boolean: If using video in the slider, will prevent CSS3 3D Transforms to avoid graphical glitches

        // Primary Controls
        controlNav: true, //Boolean: Create navigation for paging control of each slide? Note: Leave true for manualControls usage
        directionNav: true, //Boolean: Create navigation for previous/next navigation? (true/false)
        prevText: "Previous", //String: Set the text for the "previous" directionNav item
        nextText: "Next", //String: Set the text for the "next" directionNav item

        // Secondary Navigation
        keyboard: true, //Boolean: Allow slider navigating via keyboard left/right keys
        multipleKeyboard: false, //{NEW} Boolean: Allow keyboard navigation to affect multiple sliders. Default behavior cuts out keyboard navigation with more than one slider present.
        mousewheel: false, //{UPDATED} Boolean: Requires jquery.mousewheel.js (https://github.com/brandonaaron/jquery-mousewheel) - Allows slider navigating via mousewheel
        pausePlay: false, //Boolean: Create pause/play dynamic element
        pauseText: "Pause", //String: Set the text for the "pause" pausePlay item
        playText: "Play", //String: Set the text for the "play" pausePlay item

        // Special properties
        controlsContainer: "", //{UPDATED} jQuery Object/Selector: Declare which container the navigation elements should be appended too. Default container is the FlexSlider element. Example use would be $(".flexslider-container"). Property is ignored if given element is not found.
        manualControls: "", //{UPDATED} jQuery Object/Selector: Declare custom control navigation. Examples would be $(".flex-control-nav li") or "#tabs-nav li img", etc. The number of elements in your controlNav should match the number of slides/tabs.
        customDirectionNav: "", //{NEW} jQuery Object/Selector: Custom prev / next button. Must be two jQuery elements. In order to make the events work they have to have the classes "prev" and "next" (plus namespace)
        sync: "", //{NEW} Selector: Mirror the actions performed on this slider with another slider. Use with care.
        asNavFor: "", //{NEW} Selector: Internal property exposed for turning the slider into a thumbnail navigation for another slider

        // Carousel Options
        itemWidth: 0, //{NEW} Integer: Box-model width of individual carousel items, including horizontal borders and padding.
        itemMargin: 0, //{NEW} Integer: Margin between carousel items.
        minItems: 1, //{NEW} Integer: Minimum number of carousel items that should be visible. Items will resize fluidly when below this.
        maxItems: 0, //{NEW} Integer: Maxmimum number of carousel items that should be visible. Items will resize fluidly when above this limit.
        move: 0, //{NEW} Integer: Number of carousel items that should move on animation. If 0, slider will move all visible items.
        allowOneSlide: true, //{NEW} Boolean: Whether or not to allow a slider comprised of a single slide

        // Browser Specific
        isFirefox: false, // {NEW} Boolean: Set to true when Firefox is the browser used.

        // Callback API
        start: function start() {}, //Callback: function(slider) - Fires when the slider loads the first slide
        before: function before() {}, //Callback: function(slider) - Fires asynchronously with each slider animation
        after: function after() {}, //Callback: function(slider) - Fires after each slider animation completes
        end: function end() {}, //Callback: function(slider) - Fires when the slider reaches the last slide (asynchronous)
        added: function added() {}, //{NEW} Callback: function(slider) - Fires after a slide is added
        removed: function removed() {}, //{NEW} Callback: function(slider) - Fires after a slide is removed
        init: function init() {}, //{NEW} Callback: function(slider) - Fires after the slider is initially setup
        rtl: false //{NEW} Boolean: Whether or not to enable RTL mode
    };

    //FlexSlider: Plugin Function
    $.fn.flexslider = function (options) {
        if (options === undefined) {
            options = {};
        }

        if ((typeof options === "undefined" ? "undefined" : _typeof(options)) === "object") {
            return this.each(function () {
                var $this = $(this),
                    selector = options.selector ? options.selector : ".slides > li",
                    $slides = $this.find(selector);

                if ($slides.length === 1 && options.allowOneSlide === false || $slides.length === 0) {
                    $slides.fadeIn(400);
                    if (options.start) {
                        options.start($this);
                    }
                } else if ($this.data('flexslider') === undefined) {
                    new $.flexslider(this, options);
                }
            });
        } else {
            // Helper strings to quickly perform functions on the slider
            var $slider = $(this).data('flexslider');
            switch (options) {
                case "play":
                    $slider.play();break;
                case "pause":
                    $slider.pause();break;
                case "stop":
                    $slider.stop();break;
                case "next":
                    $slider.flexAnimate($slider.getTarget("next"), true);break;
                case "prev":
                case "previous":
                    $slider.flexAnimate($slider.getTarget("prev"), true);break;
                default:
                    if (typeof options === "number") {
                        $slider.flexAnimate(options, true);
                    }
            }
        }
    };
})(_jquery2.default);

}).call(this,require("timers").setImmediate)
},{"jquery":"jquery","timers":52}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    selector: ".js-accordion",
    init: init
};


function init() {
    (0, _jquery2.default)(".js-accordion").each(function () {

        // if a panelist component, open first panel on load, unless in EE (where all are open by default)
        if (!(0, _jquery2.default)("body").hasClass("experience-editor") && (0, _jquery2.default)(this).hasClass("js-panelist-accordion")) {
            var $firstPanel = (0, _jquery2.default)((0, _jquery2.default)(this).find(".js-accordion-toggle")[0]);
            openPanel($firstPanel);
        }

        // rightpoint jquery upgrade change - changed click event to on_click
        // bind clicks on toggles
        (0, _jquery2.default)(this).find(".js-accordion-toggle").on('click', function () {
            openPanel((0, _jquery2.default)(this));
        });
    });
}

function openPanel($toggle) {
    // Expand or collapse this panel
    $toggle.toggleClass("is-expanded");
    $toggle.next().slideToggle("fast");

    // Hide the other panels
    $toggle.siblings(".js-accordion-toggle").each(function (index, item) {
        (0, _jquery2.default)(item).removeClass("is-expanded");
        (0, _jquery2.default)(item).next().slideUp("fast");
    });
}

},{"jquery":"jquery"}],3:[function(require,module,exports){
"use strict";

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _jquery2.default)(document).ready(function () {
    GetVideosFromPlaylist();
}); //rightpoint jquery upgrade change - imported jquery as jquery link was removed from indiviual rendering and this js requires jquery
//java script files added by Divya-AER-ENHC0019627 - Playback api change

function GetVideosFromPlaylist() {

    var playlistId = (0, _jquery2.default)("#FeaturedVideoPlaylistID").val();
    var accountId = (0, _jquery2.default)("#FeaturedVideoPlayerAccountId").val();
    var maxLimit = (0, _jquery2.default)("#FeaturedMaxPlaylistVideos").val();
    if (maxLimit === undefined) {
        maxLimit = 3;
    }
    var videoPageBaeUrl = (0, _jquery2.default)("#FeaturedVideoPageUrl").val();
    var policykey = (0, _jquery2.default)('#FeaturedVideoPolicyKey').val();
    var baseURL = (0, _jquery2.default)("#PlaybackApiBaseURL").val();
    var apiurl = baseURL + "accounts/" + accountId + "/playlists/" + playlistId + "?limit=" + maxLimit;

    if (baseURL !== undefined && baseURL != null && baseURL !== '' && accountId !== undefined && accountId != null && accountId != '' && policykey !== undefined && policykey != null && policykey != '' && playlistId !== undefined && playlistId != null && playlistId != '') {

        var reqHeader = { "BCOV-Policy": policykey };

        // call the playback api with the above details  
        _jquery2.default.ajax({
            url: apiurl,
            headers: reqHeader,
            method: 'GET',
            dataType: 'JSON',
            data: {},
            success: function success(data) {
                // on success bind the videos to html and add the combined html to Main div (#featuredVideoComponent)
                var obj = data.videos;
                _jquery2.default.each(obj, function (key, value) {
                    console.log(value.id);
                    var htmlelem = '<div class="featured-videos-slide__wrapper" ><a class="featured-videos-slide" href="data.poster"><img class=" featured-videos-slide__image" src="posterimage" alt="data.title" /><div class="featured-videos-slide__caption"><h6>data.name</h6></div></a></div>';
                    htmlelem = htmlelem.toString();
                    var name = value.name;
                    var videoid = value.id;
                    var posterImage = value.poster;
                    var url = videoPageBaeUrl + "?videoId=" + videoid;
                    htmlelem = htmlelem.replace('data.name', name);
                    if (value.poster_sources[1] !== undefined) {
                        htmlelem = htmlelem.replace('posterimage', value.poster_sources[1].src);
                    }
                    htmlelem = htmlelem.replace('data.poster', url);
                    htmlelem = htmlelem.replace('data.title', name);
                    (0, _jquery2.default)("#featuredVideoComponent").append(htmlelem);
                });
            },
            error: function error(_error) {
                console.log(_error);
            }
        });
    }
}

},{"jquery":"jquery"}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = getSearchParam;
function getSearchParam(param) {
    // Returns a value from the search query, if it is specified
    var searchParams = new URLSearchParams(window.location.search);

    return searchParams.has(param) ? searchParams.get(param) : false;
}

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = getValidOptionFromList;
// Accepts a default value (usually from state), a value taken from the url query params, and an array of valid options
// Returns a value that is present in a list of valid options, or the default value as fallback

function getValidOptionFromList(currentValue, queryValue, validOptions) {

    // check for existence, truthiness, value and validity
    var queryIsTruthy = queryValue;
    var queryIsNotEmpty = queryValue !== "";
    var queryIsValid = validOptions.includes(queryValue);

    return queryIsTruthy && queryIsNotEmpty && queryIsValid ? queryValue : currentValue;
}

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _momentTimezone = require("moment-timezone");

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

require("moment/locale/ar-custom.js");

require("moment/locale/de.js");

require("moment/locale/el.js");

require("moment/locale/es.js");

require("moment/locale/fr.js");

require("moment/locale/id.js");

require("moment/locale/ja.js");

require("moment/locale/ko.js");

require("moment/locale/pt.js");

require("moment/locale/ru.js");

require("moment/locale/vi.js");

require("moment/locale/zh-cn.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.moment = _momentTimezone2.default; // Arabic (Custom) 
/*  (NOTE: Copy the ar-custom.js file from this directory to  node_modules/moment/locale after you've obtained this folder after with npm install. 
    If you don't do this, you will get a console &/or gulp error that ar-custom cannot be found.) */
// German
// Greek
// Spanish
// French
// Indonesian
// Japanese
// Korean
// Portuguese
// Russian
// Vietnamese
// Chinese (China)
// note: en-us is provided by default


exports.default = _momentTimezone2.default;

},{"moment-timezone":"moment-timezone","moment/locale/ar-custom.js":"moment/locale/ar-custom.js","moment/locale/de.js":"moment/locale/de.js","moment/locale/el.js":"moment/locale/el.js","moment/locale/es.js":"moment/locale/es.js","moment/locale/fr.js":"moment/locale/fr.js","moment/locale/id.js":"moment/locale/id.js","moment/locale/ja.js":"moment/locale/ja.js","moment/locale/ko.js":"moment/locale/ko.js","moment/locale/pt.js":"moment/locale/pt.js","moment/locale/ru.js":"moment/locale/ru.js","moment/locale/vi.js":"moment/locale/vi.js","moment/locale/zh-cn.js":"moment/locale/zh-cn.js"}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

require("slick-carousel");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    selector: ".js-hero-slideshow",
    init: init
};


function init() {
    if ((0, _jquery2.default)(".js-hero-slideshow").length > 0) {

        var isRtl = (0, _jquery2.default)("html").attr("lang") === "ar";

        (0, _jquery2.default)(".js-hero-slideshow").slick({
            dots: true,
            autoplay: (0, _jquery2.default)("body").hasClass("experience-editor") ? false : true,
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
            rtl: isRtl
        });
    }
}

},{"jquery":"jquery","slick-carousel":"slick-carousel"}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

require("slick-carousel");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    selector: ".js-homepage-featured-videos",
    init: init
};


function init() {
    if ((0, _jquery2.default)(".js-homepage-featured-videos").length > 0) {

        var isRtl = (0, _jquery2.default)("html").attr("lang") === "ar";

        // handle re-slicking if slider is destroyed and needs to be recreated
        (0, _jquery2.default)(".js-homepage-featured-videos").on("destroy", function (event, slick) {
            var carousel = (0, _jquery2.default)(this),
                reinit = function reinit() {
                // check if we're at a smaller screen size than where the slider was destroyed
                if (slick.activeBreakpoint < window.innerWidth) {
                    return;
                }

                // Re-initialize with the old settings
                carousel.slick(slick.options);

                // Remove this event listener.
                window.removeEventListener(reinit);
            };

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        }).slick({
            arrows: false,
            dots: true,
            infinite: false,
            mobileFirst: true,
            rtl: isRtl,
            responsive: [{
                // switches to horizontal list here 
                breakpoint: 920,
                settings: "unslick"
            }]
        });
    }
}

},{"jquery":"jquery","slick-carousel":"slick-carousel"}],9:[function(require,module,exports){
"use strict";

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

require("core-js/fn/array/includes.js");

require("whatwg-fetch");

var _urlSearchParams = require("url-search-params");

var _urlSearchParams2 = _interopRequireDefault(_urlSearchParams);

var _promisePolyfill = require("promise-polyfill");

var _promisePolyfill2 = _interopRequireDefault(_promisePolyfill);

var _stickyHeader = require("./sticky-header.js");

var _stickyHeader2 = _interopRequireDefault(_stickyHeader);

var _accordion = require("./accordion.js");

var _accordion2 = _interopRequireDefault(_accordion);

var _homepageFeaturedVideos = require("./homepage-featured-videos.js");

var _homepageFeaturedVideos2 = _interopRequireDefault(_homepageFeaturedVideos);

var _videoBannerSlider = require("./video-banner-slider.js");

var _videoBannerSlider2 = _interopRequireDefault(_videoBannerSlider);

var _heroSlideshow = require("./hero-slideshow.js");

var _heroSlideshow2 = _interopRequireDefault(_heroSlideshow);

var _loeDropdown = require("./loe-dropdown.js");

var _loeDropdown2 = _interopRequireDefault(_loeDropdown);

var _app = require("./schedule-app/app.js");

var _app2 = _interopRequireDefault(_app);

var _app3 = require("./seminar-listing-app/app.js");

var _app4 = _interopRequireDefault(_app3);

var _app5 = require("./video-search-app/app.js");

var _app6 = _interopRequireDefault(_app5);

var _app7 = require("./speeches-search-app/app.js");

var _app8 = _interopRequireDefault(_app7);

require("./featured-video-component.js");

require("./Flexslider/jquery.flexslider.js");

require("./videopage-featured-video.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//import "core-js/fn/array/from.js";
//import "./closest.js";
/* import libraries & plugins */
(0, _jquery2.default)(document).ready(function () {

    // Polyfills
    if (!window.Promise) {
        window.Promise = _promisePolyfill2.default;
    }

    if (!window.URLSearchParams) {
        window.URLSearchParams = _urlSearchParams2.default;
    }

    // Sticky header
    if ((0, _jquery2.default)(_stickyHeader2.default.selector).length > 0) {
        _stickyHeader2.default.init();
    }

    // Hero Slideshow
    if ((0, _jquery2.default)(_heroSlideshow2.default.selector).length > 0) {
        _heroSlideshow2.default.init();
    }

    // Homepage Featured Videos
    if ((0, _jquery2.default)(_homepageFeaturedVideos2.default.selector).length > 0) {
        _homepageFeaturedVideos2.default.init();
    }

    // Video Banner Slider
    if ((0, _jquery2.default)(_videoBannerSlider2.default.selector).length > 0) {
        _videoBannerSlider2.default.init();
    }

    // LoE dropdown
    if ((0, _jquery2.default)(_loeDropdown2.default.selector).length > 0) {
        _loeDropdown2.default.init();
    }

    // Accordions
    if ((0, _jquery2.default)(_accordion2.default.selector).length > 0) {
        _accordion2.default.init();
    }

    // Schedule Search App
    if ((0, _jquery2.default)(_app2.default.selector).length > 0) {
        _app2.default.init();
    }

    // Seminar Listing App
    if ((0, _jquery2.default)(_app4.default.selector).length > 0) {
        _app4.default.init();
    }

    // Video Search App
    if ((0, _jquery2.default)(_app6.default.selector).length > 0) {
        _app6.default.init();
    }

    // Speeches Search App
    if ((0, _jquery2.default)(_app8.default.selector).length > 0) {
        _app8.default.init();
    }
});

//rightpoint jquery upgrade change - moved below js files from indiviual renderings to here to include in  index.generated.js file


/* import components */


/* import polyfills */
//import "core-js/fn/object/assign.js";
//import "core-js/fn/array/find.js";
//import "core-js/fn/array/find-index.js";
//import "core-js/fn/array/fill.js";

},{"./Flexslider/jquery.flexslider.js":1,"./accordion.js":2,"./featured-video-component.js":3,"./hero-slideshow.js":7,"./homepage-featured-videos.js":8,"./loe-dropdown.js":10,"./schedule-app/app.js":20,"./seminar-listing-app/app.js":27,"./speeches-search-app/app.js":36,"./sticky-header.js":37,"./video-banner-slider.js":38,"./video-search-app/app.js":49,"./videopage-featured-video.js":50,"core-js/fn/array/includes.js":"core-js/fn/array/includes.js","jquery":"jquery","promise-polyfill":"promise-polyfill","url-search-params":"url-search-params","whatwg-fetch":"whatwg-fetch"}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    selector: ".js-loe-langs",
    init: init
};


function init() {
    // prevent navigation to the top/current item in the language tree
    (0, _jquery2.default)(".js-loe-langs > li").on("click", function (event) {
        event.preventDefault();
    });

    (0, _jquery2.default)(".js-loe-langs > li > ul > li").on("click", function (event) {
        event.stopPropagation();
    });
}

},{"jquery":"jquery"}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AddToCalendarButton = function (_PureComponent) {
  _inherits(AddToCalendarButton, _PureComponent);

  function AddToCalendarButton() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, AddToCalendarButton);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AddToCalendarButton.__proto__ || Object.getPrototypeOf(AddToCalendarButton)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
      var _this$props = _this.props,
          eventObj = _this$props.eventObj,
          meetingsTimezone = _this$props.meetingsTimezone;


      var defaultDuration = 1; // if no end time set, number of hours to use as default

      var startTime = eventObj.calStartDate;

      var formattedEndDateAndTime = eventObj.calEndDate;
      var description = eventObj.titleLink == null ? "" : eventObj.titleLink.indexOf("http") === 0 ? eventObj.titleLink : location.origin + eventObj.titleLink;

      return _react2.default.createElement(
        "div",
        { className: "cal addeventatc" },
        _react2.default.createElement(
          "span",
          { className: "start" },
          startTime
        ),
        _react2.default.createElement(
          "span",
          { className: "end" },
          formattedEndDateAndTime
        ),
        _react2.default.createElement(
          "span",
          { className: "timezone" },
          meetingsTimezone
        ),
        _react2.default.createElement(
          "span",
          { className: "title" },
          eventObj.title
        ),
        _react2.default.createElement(
          "span",
          { className: "description" },
          description
        ),
        _react2.default.createElement(
          "span",
          { className: "location" },
          eventObj.location
        )
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return AddToCalendarButton;
}(_react.PureComponent);

AddToCalendarButton.propTypes = {
  eventObj: (0, _propTypes.shape)({
    description: _propTypes.string,
    endDate: _propTypes.string,
    isTBD: _propTypes.bool,
    location: _propTypes.string,
    startDate: _propTypes.string,
    title: _propTypes.string,
    calStartDate: _propTypes.string,
    calEndDate: _propTypes.string
  }),
  meetingsTimezone: _propTypes.string
};
exports.default = AddToCalendarButton;

},{"prop-types":"prop-types","react":"react"}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _momentLocales = require("../helpers/momentLocales.js");

var _momentLocales2 = _interopRequireDefault(_momentLocales);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DayFilter = function (_PureComponent) {
	_inherits(DayFilter, _PureComponent);

	function DayFilter() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, DayFilter);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = DayFilter.__proto__ || Object.getPrototypeOf(DayFilter)).call.apply(_ref, [this].concat(args))), _this), _this.makeOptionLabel = function (day) {
			var localeString = _this.props.localeString;


			_momentLocales2.default.locale(localeString);
			return (0, _momentLocales2.default)(day).format("LL");
		}, _this.handleChange = function (event) {
			var updateSelectedDate = _this.props.updateSelectedDate;


			updateSelectedDate(event.target.value);
		}, _this.render = function () {
			var _this$props = _this.props,
			    selectedDay = _this$props.selectedDay,
			    defaultDateSelectionLabel = _this$props.defaultDateSelectionLabel,
			    daysList = _this$props.daysList;


			var optionList = daysList.map(function (day, index) {
				var label = _this.makeOptionLabel(day);
				return _react2.default.createElement(
					"option",
					{ key: index, value: day },
					label
				);
			});

			return _react2.default.createElement(
				"div",
				{ className: "filtr" },
				_react2.default.createElement(
					"select",
					{ className: "custom-select", value: selectedDay || "", onChange: _this.handleChange },
					_react2.default.createElement(
						"option",
						{ value: "" },
						defaultDateSelectionLabel
					),
					optionList
				)
			);
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return DayFilter;
}(_react.PureComponent);

DayFilter.propTypes = {
	localeString: _propTypes.string,
	selectedDay: _propTypes.string,
	defaultDateSelectionLabel: _propTypes.string,
	daysList: _propTypes.array,
	updateSelectedDate: _propTypes.func
};
exports.default = DayFilter;

},{"../helpers/momentLocales.js":6,"prop-types":"prop-types","react":"react"}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _momentLocales = require("../helpers/momentLocales.js");

var _momentLocales2 = _interopRequireDefault(_momentLocales);

var _propTypes = require("prop-types");

var _AddToCalendarButton = require("./AddToCalendarButton.jsx");

var _AddToCalendarButton2 = _interopRequireDefault(_AddToCalendarButton);

var _Productions = require("./Productions.jsx");

var _Productions2 = _interopRequireDefault(_Productions);

var _PanelistComponent = require("./PanelistComponent.jsx");

var _PanelistComponent2 = _interopRequireDefault(_PanelistComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var EventCard = function (_PureComponent) {
    _inherits(EventCard, _PureComponent);

    function EventCard() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, EventCard);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = EventCard.__proto__ || Object.getPrototypeOf(EventCard)).call.apply(_ref, [this].concat(args))), _this), _this.renderTimeRange = function () {
            var _this$props = _this.props,
                localeString = _this$props.localeString,
                eventObj = _this$props.eventObj,
                scheduleTBDLabel = _this$props.scheduleTBDLabel;


            if (!eventObj.startDate) {
                return null;
            } else if (eventObj.isTBD) {
                return _react2.default.createElement(
                    "h3",
                    null,
                    scheduleTBDLabel
                );
            } else {
                _momentLocales2.default.locale(localeString);

                var startTime = (0, _momentLocales2.default)(eventObj.startDate).format("LT");

                var endTime = eventObj.endDate && eventObj.endDate !== "" ? (0, _momentLocales2.default)(eventObj.endDate).format("LT") : null;

                if (endTime === null) {
                    return _react2.default.createElement(
                        "h3",
                        null,
                        startTime
                    );
                } else {
                    return _react2.default.createElement(
                        "h3",
                        null,
                        startTime,
                        " -",
                        _react2.default.createElement("br", null),
                        endTime
                    );
                }
            }
        }, _this.renderLocation = function () {
            var location = _this.props.eventObj.location;


            if (location && location !== "") {
                return _react2.default.createElement(
                    "p",
                    null,
                    location
                );
            } else {
                return null;
            }
        }, _this.renderTitle = function () {
            var _this$props$eventObj = _this.props.eventObj,
                title = _this$props$eventObj.title,
                titleLink = _this$props$eventObj.titleLink;


            if (!title || title == "") {
                return null;
            } else if (!titleLink || titleLink === "" || titleLink === "#") {
                return _react2.default.createElement(
                    "h3",
                    { className: "info__title" },
                    title
                );
            } else {
                return _react2.default.createElement(
                    "h3",
                    { className: "info__title" },
                    _react2.default.createElement(
                        "a",
                        { href: titleLink },
                        title
                    )
                );
            }
        }, _this.renderBottomDescription = function () {
            var bottomDescription = _this.props.eventObj.bottomDescription;


            if (bottomDescription && bottomDescription !== "") {
                return _react2.default.createElement("div", { className: "info__bottom-description", dangerouslySetInnerHTML: _this.createMarkup(bottomDescription) });
            } else {
                return null;
            }
        }, _this.renderDescription = function () {
            var description = _this.props.eventObj.description;


            if (description && description !== "") {
                return _react2.default.createElement("div", { className: "info__description", dangerouslySetInnerHTML: _this.createMarkup(description) });
            } else {
                return null;
            }
        }, _this.createMarkup = function (htmlString) {
            return { __html: htmlString };
        }, _this.renderOpeningRemarks = function () {
            var openingRemarks = _this.props.eventObj.openingRemarks;


            if (openingRemarks && openingRemarks.length > 0) {
                var label = openingRemarks[0].panelistPosition;

                var speakers = openingRemarks.length > 1 ? _this.renderListOfSpeakers(openingRemarks) : _this.renderSingleSpeaker(openingRemarks[0].panelistName, openingRemarks[0].panelistTitle);

                return _react2.default.createElement(
                    "div",
                    { className: "opening-remarks" },
                    label,
                    ": ",
                    speakers
                );
            } else {
                return null;
            }
        }, _this.renderListOfSpeakers = function (speakersArray) {
            var items = speakersArray.map(function (speaker, index) {
                var speakerItem = _this.renderSingleSpeaker(speaker.panelistName, speaker.panelistTitle);

                return _react2.default.createElement(
                    "li",
                    { key: index },
                    speakerItem
                );
            });

            return _react2.default.createElement(
                "ul",
                null,
                items
            );
        }, _this.renderSingleSpeaker = function (name, title) {
            var separator = name && name !== "" && title && title !== "" ? ", " : "";

            return _react2.default.createElement(
                "span",
                null,
                _react2.default.createElement(
                    "strong",
                    null,
                    name
                ),
                separator,
                title
            );
        }, _this.renderPanelistComponents = function () {
            var eventObj = _this.props.eventObj;


            if (eventObj.panelistComponent && eventObj.panelistComponent.length > 0) {
                return eventObj.panelistComponent.map(function (item, index) {
                    return _react2.default.createElement(_PanelistComponent2.default, _extends({
                        key: index
                    }, item, {
                        renderSingleSpeaker: _this.renderSingleSpeaker,
                        renderListOfSpeakers: _this.renderListOfSpeakers
                    }));
                });
            } else {
                return null;
            }
        }, _this.render = function () {
            var _this$props2 = _this.props,
                eventObj = _this$props2.eventObj,
                meetingsTimezone = _this$props2.meetingsTimezone;


            return _react2.default.createElement(
                "div",
                { className: "event-card details" },
                _react2.default.createElement(_AddToCalendarButton2.default, {
                    eventObj: eventObj,
                    meetingsTimezone: meetingsTimezone
                }),
                _react2.default.createElement(
                    "div",
                    { className: "time" },
                    _this.renderTimeRange(),
                    _this.renderLocation()
                ),
                _react2.default.createElement(
                    "div",
                    { className: "info" },
                    _this.renderTitle(),
                    _this.renderDescription(),
                    _this.renderOpeningRemarks(),
                    _this.renderPanelistComponents(),
                    _this.renderBottomDescription(),
                    _react2.default.createElement(_Productions2.default, {
                        productions: eventObj.productions
                    })
                )
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return EventCard;
}(_react.PureComponent);

EventCard.propTypes = {
    eventObj: (0, _propTypes.shape)({
        bottomDescription: _propTypes.string,
        description: _propTypes.string,
        endDate: _propTypes.string,
        isTBD: _propTypes.bool,
        location: _propTypes.string,
        openingRemarks: _propTypes.array,
        panelistComponent: _propTypes.array,
        productions: _propTypes.array,
        sessionHashtags: _propTypes.string,
        sponsor: _propTypes.string,
        sponsorCode: _propTypes.string,
        startDate: _propTypes.string,
        title: _propTypes.string,
        titleLink: _propTypes.string
    }),
    localeString: _propTypes.string,
    meetingsTimezone: _propTypes.string,
    scheduleTBDLabel: _propTypes.string
};
exports.default = EventCard;

},{"../helpers/momentLocales.js":6,"./AddToCalendarButton.jsx":11,"./PanelistComponent.jsx":14,"./Productions.jsx":15,"prop-types":"prop-types","react":"react"}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PanelistComponent = function (_PureComponent) {
    _inherits(PanelistComponent, _PureComponent);

    function PanelistComponent() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, PanelistComponent);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = PanelistComponent.__proto__ || Object.getPrototypeOf(PanelistComponent)).call.apply(_ref, [this].concat(args))), _this), _this.renderTitle = function () {
            var componentTitle = _this.props.componentTitle;


            if (componentTitle && componentTitle !== "") {
                return _react2.default.createElement(
                    "p",
                    { className: "panelist-component__title" },
                    componentTitle
                );
            }
        }, _this.renderPositions = function () {
            var positions = _this.props.positions;


            if (positions && positions.length > 0) {
                var panelists = positions.filter(function (position) {
                    return position.positionCode === "Panelist";
                });
                var panelistsEl = _this.renderRole(panelists[0]);

                var specialRoles = positions.filter(function (position) {
                    return position.positionCode !== "Panelist";
                });
                var specialEls = specialRoles.map(function (roleObj, index) {
                    return _react2.default.createElement(
                        "div",
                        { key: index, className: "panelist-component__special-roles" },
                        _this.renderRole(roleObj)
                    );
                });

                return _react2.default.createElement(
                    "div",
                    { className: "panelist-component__roles" },
                    specialEls,
                    panelistsEl
                );
            } else {
                return null;
            }
        }, _this.renderRole = function (roleObj) {
            var _this$props = _this.props,
                renderListOfSpeakers = _this$props.renderListOfSpeakers,
                renderSingleSpeaker = _this$props.renderSingleSpeaker;


            if (roleObj && roleObj.positionMemebers.length > 0) {
                var label = roleObj.positionName;

                var speakers = roleObj.positionMemebers.length > 1 ? renderListOfSpeakers(roleObj.positionMemebers) : renderSingleSpeaker(roleObj.positionMemebers[0].panelistName, roleObj.positionMemebers[0].panelistTitle);

                if (label && speakers) {
                    return _react2.default.createElement(
                        "div",
                        { className: "panelist-component__role" },
                        label,
                        ": ",
                        speakers
                    );
                } else {
                    return _react2.default.createElement(
                        "div",
                        { className: "panelist-component__role" },
                        label,
                        speakers
                    );
                }
            } else {
                return null;
            }
        }, _this.render = function () {
            return _react2.default.createElement(
                "div",
                { className: "panelist-component" },
                _this.renderTitle(),
                _this.renderPositions()
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return PanelistComponent;
}(_react.PureComponent);

PanelistComponent.propTypes = {
    componentTitle: _propTypes.string,
    positions: _propTypes.array,
    renderSingleSpeaker: _propTypes.func,
    renderListOfSpeakers: _propTypes.func
};
exports.default = PanelistComponent;

},{"prop-types":"prop-types","react":"react"}],15:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Productions = function (_PureComponent) {
	_inherits(Productions, _PureComponent);

	function Productions() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, Productions);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Productions.__proto__ || Object.getPrototypeOf(Productions)).call.apply(_ref, [this].concat(args))), _this), _this.returnSingleProduction = function (productionObj, index) {
			var iconClass = "icon-" + productionObj.cssClassName;
			var classes = "sched-icons " + iconClass;

			var target = productionObj.target && productionObj.target !== "" ? productionObj.target : "_self";

			return _react2.default.createElement(
				"a",
				{ key: index, href: productionObj.link, className: classes, target: target },
				productionObj.name
			);
		}, _this.render = function () {
			var productions = _this.props.productions;


			if (productions && productions.length > 0) {

				var listItems = productions.map(function (production, index) {
					return _this.returnSingleProduction(production, index);
				});

				return _react2.default.createElement(
					"div",
					{ className: "productions" },
					listItems
				);
			} else {
				return null;
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return Productions;
}(_react.PureComponent);

Productions.propTypes = {
	productions: _propTypes.array
};
exports.default = Productions;

},{"prop-types":"prop-types","react":"react"}],16:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _momentTimezone = require("moment-timezone");

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _propTypes = require("prop-types");

var _DayFilter = require("./DayFilter.jsx");

var _DayFilter2 = _interopRequireDefault(_DayFilter);

var _SponsorFilter = require("./SponsorFilter.jsx");

var _SponsorFilter2 = _interopRequireDefault(_SponsorFilter);

var _SingleDay = require("./SingleDay.jsx");

var _SingleDay2 = _interopRequireDefault(_SingleDay);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ScheduleApp = function (_PureComponent) {
	_inherits(ScheduleApp, _PureComponent);

	function ScheduleApp() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, ScheduleApp);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ScheduleApp.__proto__ || Object.getPrototypeOf(ScheduleApp)).call.apply(_ref, [this].concat(args))), _this), _this.componentDidUpdate = function () {
			// reinitialize add event to catch any new DOM nodes
			addeventatc.refresh();
		}, _this.groupEventsByDay = function () {
			var _this$props = _this.props,
			    daysList = _this$props.daysList,
			    events = _this$props.events;


			if (!events || events.length === 0) {
				return [];
			} else {
				return daysList.map(function (day, index) {
					var dayMoment = (0, _momentTimezone2.default)(day);
					var month = dayMoment.format("M");
					var date = dayMoment.format("D");

					var thisDaysEvents = events.filter(function (event) {
						if (event.startDate) {
							var eventMoment = (0, _momentTimezone2.default)(event.startDate);
							var eventMonth = eventMoment.format("M");
							var eventDate = eventMoment.format("D");
							return eventMonth === month && eventDate === date;
						} else {
							return;
						}
					});

					return {
						day: day,
						dayMoment: dayMoment,
						events: thisDaysEvents
					};
				});
			}
		}, _this.getCurrentDayAtMeeting = function () {
			var meetingsTimezone = _this.props.meetingsTimezone;

			// returns todays date, adjust for the the timezone of the meeting's location,
			// formatted the same way the dates are formatted in our daysList

			var formattedTimezone = meetingsTimezone.replace(/\\/g, "/");
			var currentClientDate = (0, _momentTimezone2.default)();
			return currentClientDate.tz(formattedTimezone).format("YYYY-MM-DD[T00:00:00]");
		}, _this.renderResultsList = function () {
			var _this$props2 = _this.props,
			    events = _this$props2.events,
			    localeString = _this$props2.localeString,
			    meetingsTimezone = _this$props2.meetingsTimezone,
			    isFetching = _this$props2.isFetching,
			    scheduleNoResultsLabel = _this$props2.scheduleNoResultsLabel,
			    scheduleTBDLabel = _this$props2.scheduleTBDLabel;


			if (isFetching) {
				return _react2.default.createElement(
					"div",
					{ className: "schedule__loading" },
					_react2.default.createElement("img", { src: "/Assets/AMSM/images/loading.gif" })
				);
			} else {
				var eventsByDay = _this.groupEventsByDay();

				var list = eventsByDay.length > 0 ? eventsByDay.map(function (dayInfo, index) {
					return _react2.default.createElement(_SingleDay2.default, _extends({
						key: index,
						localeString: localeString,
						meetingsTimezone: meetingsTimezone,
						scheduleTBDLabel: scheduleTBDLabel,
						isToday: dayInfo.day === _this.getCurrentDayAtMeeting()
					}, dayInfo));
				}) : _react2.default.createElement(
					"div",
					{ className: "schedule__no-results" },
					scheduleNoResultsLabel
				);

				return _react2.default.createElement(
					"div",
					{ className: "day-listing" },
					list
				);
			}
		}, _this.render = function () {
			var _this$props3 = _this.props,
			    localeString = _this$props3.localeString,
			    selectedDay = _this$props3.selectedDay,
			    defaultDateSelectionLabel = _this$props3.defaultDateSelectionLabel,
			    defaultSponsorLabel = _this$props3.defaultSponsorLabel,
			    daysList = _this$props3.daysList,
			    updateSelectedDate = _this$props3.updateSelectedDate,
			    selectedSponsor = _this$props3.selectedSponsor,
			    sponsors = _this$props3.sponsors,
			    updateSelectedSponsor = _this$props3.updateSelectedSponsor,
			    printPDFLabel = _this$props3.printPDFLabel,
			    downloadPDFLink = _this$props3.downloadPDFLink;


			var results = _this.renderResultsList();

			return _react2.default.createElement(
				"div",
				{ className: "schedule-app" },
				_react2.default.createElement(_DayFilter2.default, {
					localeString: localeString,
					selectedDay: selectedDay,
					defaultDateSelectionLabel: defaultDateSelectionLabel,
					daysList: daysList,
					updateSelectedDate: updateSelectedDate
				}),
				_react2.default.createElement(_SponsorFilter2.default, {
					selectedSponsor: selectedSponsor,
					defaultSponsorLabel: defaultSponsorLabel,
					sponsors: sponsors,
					updateSelectedSponsor: updateSelectedSponsor
				}),
				_react2.default.createElement(
					"div",
					{ className: "print-pdf" },
					_react2.default.createElement(
						"a",
						{ download: true, href: downloadPDFLink },
						_react2.default.createElement(
							"span",
							null,
							printPDFLabel
						)
					)
				),
				results
			);
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return ScheduleApp;
}(_react.PureComponent);

ScheduleApp.propTypes = {
	localeString: _propTypes.string,
	meetingsTimezone: _propTypes.string,
	selectedDay: _propTypes.string,
	defaultDateSelectionLabel: _propTypes.string,
	daysList: _propTypes.array,
	updateSelectedDate: _propTypes.func,
	selectedSponsor: _propTypes.string,
	defaultSponsorLabel: _propTypes.string,
	sponsors: _propTypes.array,
	updateSelectedSponsor: _propTypes.func,
	scheduleNoResultsLabel: _propTypes.string,
	scheduleTBDLabel: _propTypes.string,
	events: _propTypes.array,
	printPDFLabel: _propTypes.string,
	downloadPDFLink: _propTypes.string,
	isFetching: _propTypes.bool
};
exports.default = ScheduleApp;

},{"./DayFilter.jsx":12,"./SingleDay.jsx":18,"./SponsorFilter.jsx":19,"moment-timezone":"moment-timezone","prop-types":"prop-types","react":"react"}],17:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _propTypes = require("prop-types");

var _getSearchParam = require("../helpers/getSearchParam.js");

var _getSearchParam2 = _interopRequireDefault(_getSearchParam);

var _ScheduleApp = require("./ScheduleApp.jsx");

var _ScheduleApp2 = _interopRequireDefault(_ScheduleApp);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ScheduleAppContainer = function (_Component) {
  _inherits(ScheduleAppContainer, _Component);

  function ScheduleAppContainer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ScheduleAppContainer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ScheduleAppContainer.__proto__ || Object.getPrototypeOf(ScheduleAppContainer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isFetching: false
    }, _this.componentWillMount = function () {
      _this.hydrateState();
    }, _this.shouldComponentUpdate = function (nextProps, nextState) {
      var _this$state = _this.state,
          selectedSponsor = _this$state.selectedSponsor,
          selectedDay = _this$state.selectedDay,
          events = _this$state.events;

      // shallow comparisons

      var sponsorChanged = selectedSponsor !== nextState.selectedSponsor;
      var dayChanged = selectedDay !== nextState.selectedDay;

      // deep comparison
      var eventsChanged = !_ramda2.default.equals(events, nextState.events);

      return sponsorChanged || dayChanged || eventsChanged;
    }, _this.hydrateState = function () {
      var _this$props = _this.props,
          meetingsDateTimes = _this$props.meetingsDateTimes,
          sponsors = _this$props.sponsors,
          initialJson = _this$props.initialJson,
          meetingsTimezone = _this$props.meetingsTimezone;


      var searchParams = new URLSearchParams(window.location);

      // set sponsor from url, if it's specified and a valid option
      var sponsorQuery = (0, _getSearchParam2.default)("sponsor");

      var validSponsor = sponsorQuery ? sponsors.filter(function (sponsor) {
        return sponsor.key === sponsorQuery;
      }) : null;
      var sponsorToSet = validSponsor && validSponsor.length > 0 ? validSponsor[0].key : null;

      // set date from url, if it's specified and a valid option
      var dateQuery = (0, _getSearchParam2.default)("date");
      var cleanDateQuery = dateQuery ? dateQuery.split("T")[0] + "T00:00:00" : null;
      var validDate = cleanDateQuery ? meetingsDateTimes.filter(function (date) {
        return date === cleanDateQuery;
      }) : null;
      var dateToSet = validDate && validDate.length > 0 ? validDate[0] : null;

      // set timezone from props, reassigning the default if left blank
      var timezoneToSet = meetingsTimezone === "" ? "America/New_York" : meetingsTimezone;

      _this.setState({
        daysList: meetingsDateTimes.sort(),
        selectedDay: dateToSet,
        selectedSponsor: sponsorToSet,
        meetingsTimezone: timezoneToSet,
        events: initialJson
      });
    }, _this.fetchData = function () {
      _this.setState({
        isFetching: true
      });

      // update the url visible to the user
      _this.updateUrl();

      var serviceWithParams = _this.generateServiceUrl();

      return fetch(serviceWithParams).then(function (response) {
        return response.json();
      }).then(function (data) {
        _this.setState({
          events: data,
          isFetching: false
        });
      }).catch(function (error) {
        console.error("There has been a problem with your fetch operation: " + error.message);
      });
    }, _this.updateUrl = function () {
      var _this$state2 = _this.state,
          selectedDay = _this$state2.selectedDay,
          selectedSponsor = _this$state2.selectedSponsor;

      // update the url to reflect our state,
      // and don't wipe out any existing (sitecore) params

      var params = new URLSearchParams(location.search);

      if (selectedDay) {
        var cleanDate = selectedDay.split("T")[0];
        params.set("date", cleanDate);
      } else {
        params.delete("date");
      }

      if (selectedSponsor) {
        params.set("sponsor", selectedSponsor);
      } else {
        params.delete("sponsor");
      }

      window.history.replaceState({}, "", location.pathname + "?" + params);
    }, _this.generateServiceUrl = function () {
      var serviceUrl = _this.props.serviceUrl;

      // set up the url for the service request

      var joinedParamsForService = [_this.generatePropsParams(), _this.generateStateParams()].join("&");
      return serviceUrl + "?" + joinedParamsForService;
    }, _this.generatePropsParams = function () {
      var _this$props2 = _this.props,
          homeNodeGUID = _this$props2.homeNodeGUID,
          localeString = _this$props2.localeString,
          databaseName = _this$props2.databaseName;


      var homeNodeParam = homeNodeGUID ? "homeNode={" + homeNodeGUID + "}" : "homeNode=";

      var languageParam = localeString ? "language=" + localeString : "language=";

      var databaseParam = databaseName ? "databaseName=" + databaseName : "databaseName=";

      return [homeNodeParam, languageParam, databaseParam].join("&");
    }, _this.generateStateParams = function () {
      var _this$state3 = _this.state,
          selectedDay = _this$state3.selectedDay,
          selectedSponsor = _this$state3.selectedSponsor;


      var dateParam = selectedDay ? "date=" + selectedDay : "";

      var sponsorParam = selectedSponsor ? "sponsor=" + selectedSponsor : "";

      return [dateParam, sponsorParam].join("&");
    }, _this.generatePDFLink = function () {
      var printPDFUrl = _this.props.printPDFUrl;


      if (window.location.search === "") {
        var pdfLink = window.location.href + "?p=1";
        return printPDFUrl + "?" + "url=" + encodeURIComponent(pdfLink);
      } else {
        var currentParams = new URLSearchParams(location.search);
        currentParams.delete("date");
        var _pdfLink = window.location.origin + window.location.pathname + "?" + currentParams.toString() + "&p=1";
        return printPDFUrl + "?" + "url=" + encodeURIComponent(_pdfLink);
      }
    }, _this.updateSelectedDate = function (newDate) {
      _this.setState({
        selectedDay: newDate
      }, function () {
        _this.fetchData();
      });
    }, _this.updateSelectedSponsor = function (newSponsor) {
      _this.setState({
        selectedSponsor: newSponsor
      }, function () {
        _this.fetchData();
      });
    }, _this.render = function () {
      return _react2.default.createElement(_ScheduleApp2.default, {
        localeString: _this.props.localeString,
        meetingsTimezone: _this.state.meetingsTimezone,
        selectedDay: _this.state.selectedDay,
        defaultDateSelectionLabel: _this.props.defaultDateSelectionLabel,
        daysList: _this.state.daysList,
        updateSelectedDate: _this.updateSelectedDate,
        selectedSponsor: _this.state.selectedSponsor,
        defaultSponsorLabel: _this.props.defaultSponsorLabel,
        sponsors: _this.props.sponsors,
        updateSelectedSponsor: _this.updateSelectedSponsor,
        scheduleNoResultsLabel: _this.props.scheduleNoResultsLabel,
        scheduleTBDLabel: _this.props.scheduleTBDLabel,
        events: _this.state.events,
        printPDFLabel: _this.props.printPDFLabel,
        downloadPDFLink: _this.generatePDFLink(),
        isFetching: _this.state.isFetching
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return ScheduleAppContainer;
}(_react.Component);

ScheduleAppContainer.propTypes = {
  serviceUrl: _propTypes.string,
  homeNodeGUID: _propTypes.string,
  printPDFUrl: _propTypes.string,
  localeString: _propTypes.string,
  printPDFLabel: _propTypes.string,
  printDevice: _propTypes.string,
  defaultDateSelectionLabel: _propTypes.string,
  defaultSponsorLabel: _propTypes.string,
  scheduleNoResultsLabel: _propTypes.string,
  scheduleTBDLabel: _propTypes.string,
  meetingsTimezone: _propTypes.string,
  sponsors: _propTypes.array,
  meetingsDateTimes: _propTypes.array,
  initialJson: _propTypes.array,
  databaseName: _propTypes.string
};
exports.default = ScheduleAppContainer;

},{"../helpers/getSearchParam.js":4,"./ScheduleApp.jsx":16,"prop-types":"prop-types","ramda":"ramda","react":"react"}],18:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

var _momentLocales = require("../helpers/momentLocales.js");

var _momentLocales2 = _interopRequireDefault(_momentLocales);

var _propTypes = require("prop-types");

var _EventCard = require("./EventCard.jsx");

var _EventCard2 = _interopRequireDefault(_EventCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SingleDay = function (_Component) {
  _inherits(SingleDay, _Component);

  function SingleDay() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SingleDay);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SingleDay.__proto__ || Object.getPrototypeOf(SingleDay)).call.apply(_ref, [this].concat(args))), _this), _this.componentDidMount = function () {
      setTimeout(function () {
        var isToday = this.props.isToday;

        if (isToday) {
          this.scrollToToday();
        }
      }.bind(_this), 1000);
    }, _this.scrollToToday = function () {
      var day = _this.props.day;


      var todayId = (0, _momentLocales2.default)(day).format("[date]YYYYMMDD");
      var scrollToPoint = (0, _jquery2.default)("#" + todayId).offset().top;

      (0, _jquery2.default)("html, body").animate({
        scrollTop: scrollToPoint
      }, 1000);
    }, _this.orderEvents = function () {
      var events = _this.props.events;


      return events.sort(function (a, b) {
        if (a.startDate > b.startDate) {
          return 1;
        } else if (a.startDate === b.startDate) {
          // for events with same start time
          // the ones with no defined end time goes first
          // followed by the ones that end earliest
          if (a.endDate > b.endDate) {
            return 1;
          } else if (a.endDate === b.endDate) {
            return 0;
          } else {
            return -1;
          }
        } else {
          return -1;
        }
      });
    }, _this.renderEventList = function (events) {
      var _this$props = _this.props,
          localeString = _this$props.localeString,
          meetingsTimezone = _this$props.meetingsTimezone,
          scheduleTBDLabel = _this$props.scheduleTBDLabel;


      var orderedEvents = _this.orderEvents(events);

      return orderedEvents.map(function (event, index) {
        return _react2.default.createElement(_EventCard2.default, {
          key: index,
          eventObj: event,
          localeString: localeString,
          meetingsTimezone: meetingsTimezone,
          scheduleTBDLabel: scheduleTBDLabel
        });
      });
    }, _this.render = function () {
      var _this$props2 = _this.props,
          localeString = _this$props2.localeString,
          dayMoment = _this$props2.dayMoment,
          events = _this$props2.events,
          meetingsTimezone = _this$props2.meetingsTimezone;


      if (events.length > 0) {
        // resetting global moment
        _momentLocales2.default.locale(localeString);
        var weekday = dayMoment.locale(localeString).format("dddd");
        var month = dayMoment.locale(localeString).format("MMMM");
        var date = dayMoment.locale(localeString).format("D");

        var eventList = _this.renderEventList(events);

        var todayId = dayMoment.format("[date]YYYYMMDD");

        if (localeString === "en") {
          return _react2.default.createElement(
            "div",
            { id: todayId },
            _react2.default.createElement(
              "div",
              { className: "date-block" },
              _react2.default.createElement(
                "h5",
                null,
                weekday,
                ",",
                _react2.default.createElement("br", null),
                month
              ),
              _react2.default.createElement(
                "p",
                null,
                date
              )
            ),
            eventList
          );
        } else {
          return _react2.default.createElement(
            "div",
            { id: todayId },
            _react2.default.createElement(
              "div",
              { className: "date-block" },
              _react2.default.createElement(
                "h5",
                null,
                weekday
              ),
              _react2.default.createElement(
                "p",
                null,
                date
              ),
              _react2.default.createElement(
                "h5",
                null,
                month
              )
            ),
            eventList
          );
        }
      } else {
        return null;
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return SingleDay;
}(_react.Component);

SingleDay.propTypes = {
  localeString: _propTypes.string,
  meetingsTimezone: _propTypes.string,
  dayMoment: _propTypes.object,
  day: _propTypes.string,
  events: _propTypes.array,
  scheduleTBDLabel: _propTypes.string,
  isToday: _propTypes.bool
};
exports.default = SingleDay;

},{"../helpers/momentLocales.js":6,"./EventCard.jsx":13,"jquery":"jquery","prop-types":"prop-types","react":"react"}],19:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SponsorFilter = function (_PureComponent) {
	_inherits(SponsorFilter, _PureComponent);

	function SponsorFilter() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, SponsorFilter);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SponsorFilter.__proto__ || Object.getPrototypeOf(SponsorFilter)).call.apply(_ref, [this].concat(args))), _this), _this.handleChange = function (event) {
			var updateSelectedSponsor = _this.props.updateSelectedSponsor;


			updateSelectedSponsor(event.target.value);
		}, _this.render = function () {
			var _this$props = _this.props,
			    selectedSponsor = _this$props.selectedSponsor,
			    defaultSponsorLabel = _this$props.defaultSponsorLabel,
			    sponsors = _this$props.sponsors;


			if (sponsors && sponsors.length > 0) {
				var optionList = sponsors.map(function (item, index) {
					return _react2.default.createElement(
						"option",
						{ key: index, value: item.key },
						item.value
					);
				});

				return _react2.default.createElement(
					"div",
					{ className: "filtr" },
					_react2.default.createElement(
						"select",
						{ className: "custom-select", value: selectedSponsor || "", onChange: _this.handleChange },
						_react2.default.createElement(
							"option",
							{ value: "" },
							defaultSponsorLabel
						),
						optionList
					)
				);
			} else {
				return null;
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return SponsorFilter;
}(_react.PureComponent);

SponsorFilter.propTypes = {
	selectedSponsor: _propTypes.string,
	defaultSponsorLabel: _propTypes.string,
	sponsors: _propTypes.array,
	updateSelectedSponsor: _propTypes.func
};
exports.default = SponsorFilter;

},{"prop-types":"prop-types","react":"react"}],20:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

var _ScheduleAppContainer = require("./ScheduleAppContainer.jsx");

var _ScheduleAppContainer2 = _interopRequireDefault(_ScheduleAppContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var scheduleAppClass = ".js-schedule-app";

exports.default = {
    selector: scheduleAppClass,
    init: init
};


function init() {
    (0, _jquery2.default)(scheduleAppClass).each(function (index, el) {
        _reactDom2.default.render(_react2.default.createElement(_ScheduleAppContainer2.default, _extends({
            id: index
        }, window.backendScheduleProps)), el);
    });
}

},{"./ScheduleAppContainer.jsx":17,"jquery":"jquery","react":"react","react-dom":"react-dom"}],21:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _momentLocales = require("../helpers/momentLocales.js");

var _momentLocales2 = _interopRequireDefault(_momentLocales);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DayFilter = function (_PureComponent) {
	_inherits(DayFilter, _PureComponent);

	function DayFilter() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, DayFilter);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = DayFilter.__proto__ || Object.getPrototypeOf(DayFilter)).call.apply(_ref, [this].concat(args))), _this), _this.makeOptionLabel = function (day) {
			var localeString = _this.props.localeString;


			_momentLocales2.default.locale(localeString);
			return (0, _momentLocales2.default)(day).format("LL");
		}, _this.handleChange = function (event) {
			var updateSelectedDate = _this.props.updateSelectedDate;


			updateSelectedDate(event.target.value);
		}, _this.render = function () {
			var _this$props = _this.props,
			    selectedDate = _this$props.selectedDate,
			    defaultDateSelectionLabel = _this$props.defaultDateSelectionLabel,
			    daysList = _this$props.daysList;


			var optionList = daysList.map(function (day, index) {
				var label = _this.makeOptionLabel(day);
				return _react2.default.createElement(
					"option",
					{ key: index, value: day },
					label
				);
			});

			return _react2.default.createElement(
				"div",
				{ className: "filtr" },
				_react2.default.createElement(
					"select",
					{ className: "custom-select", value: selectedDate || "", onChange: _this.handleChange },
					_react2.default.createElement(
						"option",
						{ value: "" },
						defaultDateSelectionLabel
					),
					optionList
				)
			);
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return DayFilter;
}(_react.PureComponent);

DayFilter.propTypes = {
	localeString: _propTypes.string,
	selectedDate: _propTypes.string,
	defaultDateSelectionLabel: _propTypes.string,
	daysList: _propTypes.array,
	updateSelectedDate: _propTypes.func
};
exports.default = DayFilter;

},{"../helpers/momentLocales.js":6,"prop-types":"prop-types","react":"react"}],22:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _momentLocales = require("../helpers/momentLocales.js");

var _momentLocales2 = _interopRequireDefault(_momentLocales);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SeminarCard = function (_PureComponent) {
    _inherits(SeminarCard, _PureComponent);

    function SeminarCard() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SeminarCard);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SeminarCard.__proto__ || Object.getPrototypeOf(SeminarCard)).call.apply(_ref, [this].concat(args))), _this), _this.renderTitle = function () {
            var _this$props = _this.props,
                isSeminar = _this$props.isSeminar,
                title = _this$props.title,
                titleLink = _this$props.titleLink;


            if (isSeminar) {
                return _react2.default.createElement(
                    "h4",
                    null,
                    _react2.default.createElement(
                        "a",
                        { href: titleLink, target: "_blank" },
                        title
                    )
                );
            } else if (title !== "") {
                return _react2.default.createElement(
                    "h4",
                    null,
                    title
                );
            } else {
                return null;
            }
        }, _this.renderDate = function () {
            var _this$props2 = _this.props,
                startDate = _this$props2.startDate,
                localeString = _this$props2.localeString;


            _momentLocales2.default.locale(localeString);
            return startDate && startDate !== "" ? (0, _momentLocales2.default)(startDate).format("dddd ll") : null;
        }, _this.renderTimeRange = function () {
            var _this$props3 = _this.props,
                isTBD = _this$props3.isTBD,
                startDate = _this$props3.startDate,
                endDate = _this$props3.endDate,
                seminarTBDLabel = _this$props3.seminarTBDLabel,
                localeString = _this$props3.localeString;


            if (isTBD) {
                return seminarTBDLabel;
            } else if (startDate) {
                _momentLocales2.default.locale(localeString);

                var startTime = (0, _momentLocales2.default)(startDate).format("LT");
                var endTime = endDate && endDate !== "" ? " - " + (0, _momentLocales2.default)(endDate).format("LT") : "";

                return startTime + endTime;
            } else {
                return null;
            }
        }, _this.renderLocation = function () {
            var location = _this.props.location;


            if (location && location !== "") {
                return _react2.default.createElement(
                    "p",
                    null,
                    location
                );
            } else {
                return null;
            }
        }, _this.renderSingleModerator = function (moderatorObj) {
            var name = moderatorObj.panelistName ? moderatorObj.panelistName : null;

            var title = moderatorObj.panelistTitle ? ", " + moderatorObj.panelistTitle : null;

            return _react2.default.createElement(
                "span",
                null,
                name,
                title
            );
        }, _this.renderModerators = function () {
            /* Note: typo "positionMemebers" is intentional, that's how it comes in from the BE */
            var _this$props4 = _this.props,
                panelistComponent = _this$props4.panelistComponent,
                pluralModeratorsLabel = _this$props4.pluralModeratorsLabel;


            if (panelistComponent && panelistComponent.length > 0) {
                // grab only moderators, exclude other roles
                var moderatorObjects = panelistComponent.map(function (item, index) {
                    if (item.positions && item.positions.length > 0) {
                        return item.positions.filter(function (position) {
                            return position.positionCode === "Moderator" && position.positionMemebers && position.positionMemebers.length > 0;
                        });
                    }
                }).filter(function (item) {
                    return item.length > 0;
                });

                if (moderatorObjects && moderatorObjects.length > 0) {
                    // pull out a moderator's positionName before concat
                    var label = moderatorObjects[0][0].positionName ? moderatorObjects[0][0].positionName + ":" : null;

                    // merge all moderator objects together into a single object, concatting any shared keys
                    var mergedModeratorObjects = moderatorObjects.reduce(function (prev, cur) {
                        return _ramda2.default.mergeDeepWith(_ramda2.default.concat, prev, cur);
                    });

                    // grab only the members from the merged objects
                    var mergedModeratorMembers = mergedModeratorObjects[0].positionMemebers ? mergedModeratorObjects[0].positionMemebers : null;

                    if (mergedModeratorMembers.length > 1) {
                        // multiple moderators
                        var moderatorList = mergedModeratorMembers.map(function (item, index) {
                            return _react2.default.createElement(
                                "li",
                                { key: index, className: "moderators__item" },
                                _this.renderSingleModerator(item)
                            );
                        });
                        return _react2.default.createElement(
                            "ul",
                            { className: "moderators" },
                            _react2.default.createElement(
                                "strong",
                                { className: "moderators__label" },
                                pluralModeratorsLabel,
                                ":"
                            ),
                            moderatorList
                        );
                    } else if (mergedModeratorMembers.length === 1) {
                        // single moderator
                        return _react2.default.createElement(
                            "p",
                            { "class": "moderators" },
                            _react2.default.createElement(
                                "strong",
                                null,
                                label
                            ),
                            " ",
                            _this.renderSingleModerator(mergedModeratorMembers[0])
                        );
                    } else {
                        // no moderators
                        return null;
                    }
                } else {
                    // no moderators
                    return null;
                }
            } else {
                // no panelist components
                return null;
            }
        }, _this.renderHashtags = function () {
            var _this$props5 = _this.props,
                sessionHashtags = _this$props5.sessionHashtags,
                hashtagLabel = _this$props5.hashtagLabel;


            var baseTwitterLink = "http://twitter.com/hashtag/";

            var hashtagList = sessionHashtags && sessionHashtags != "" ? sessionHashtags.replace(" ", "").split(",") : null;

            if (hashtagList && hashtagList.length > 0) {
                var hashtagLinks = hashtagList.map(function (hashtag, index) {
                    return _react2.default.createElement(
                        "a",
                        { key: index, href: baseTwitterLink + hashtag, target: "_blank", className: "seminar-hashtag" },
                        "#",
                        hashtag
                    );
                });

                return _react2.default.createElement(
                    "p",
                    null,
                    hashtagLabel,
                    " ",
                    hashtagLinks
                );
            } else {
                return null;
            }
        }, _this.render = function () {
            var _this$props6 = _this.props,
                title = _this$props6.title,
                titleLink = _this$props6.titleLink,
                isTBD = _this$props6.isTBD,
                startDate = _this$props6.startDate,
                endDate = _this$props6.endDate,
                location = _this$props6.location,
                sessionHashtags = _this$props6.sessionHashtags;


            return _react2.default.createElement(
                "div",
                { className: "sem-info" },
                _this.renderTitle(),
                _react2.default.createElement(
                    "p",
                    { className: "date" },
                    _this.renderDate()
                ),
                _react2.default.createElement(
                    "p",
                    { className: "hour" },
                    _this.renderTimeRange()
                ),
                _this.renderLocation(),
                _this.renderModerators(),
                _this.renderHashtags()
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return SeminarCard;
}(_react.PureComponent);

SeminarCard.propTypes = {
    description: _propTypes.string,
    endDate: _propTypes.string,
    isSeminar: _propTypes.bool,
    isTBD: _propTypes.bool,
    location: _propTypes.string,
    openingRemarks: _propTypes.array,
    panelistComponent: _propTypes.array,
    productions: _propTypes.array,
    sessionHashtags: _propTypes.string,
    sponsor: _propTypes.string,
    sponsorCode: _propTypes.string,
    startDate: _propTypes.string,
    title: _propTypes.string,
    titleLink: _propTypes.string,
    hashtagLabel: _propTypes.string,
    seminarTBDLabel: _propTypes.string,
    localeString: _propTypes.string,
    pluralModeratorsLabel: _propTypes.string
};
exports.default = SeminarCard;

},{"../helpers/momentLocales.js":6,"prop-types":"prop-types","ramda":"ramda","react":"react"}],23:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SeminarGroup = function (_PureComponent) {
    _inherits(SeminarGroup, _PureComponent);

    function SeminarGroup() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SeminarGroup);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SeminarGroup.__proto__ || Object.getPrototypeOf(SeminarGroup)).call.apply(_ref, [this].concat(args))), _this), _this.renderPDFLink = function () {
            var pdfLink = _this.props.pdfLink;


            if (pdfLink && pdfLink !== "") {
                return _react2.default.createElement("a", { href: pdfLink, className: "pdf" });
            } else {
                return null;
            }
        }, _this.render = function () {
            var _this$props = _this.props,
                label = _this$props.label,
                cssClass = _this$props.cssClass,
                seminars = _this$props.seminars,
                renderSeminars = _this$props.renderSeminars;


            if (seminars.length > 0) {
                return _react2.default.createElement(
                    "div",
                    { className: cssClass },
                    _react2.default.createElement(
                        "h2",
                        null,
                        label,
                        _this.renderPDFLink()
                    ),
                    renderSeminars(seminars)
                );
            } else {
                return null;
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return SeminarGroup;
}(_react.PureComponent);

SeminarGroup.propTypes = {
    label: _propTypes.string,
    cssClass: _propTypes.string,
    pdfLink: _propTypes.string,
    value: _propTypes.string,
    seminars: _propTypes.array,
    renderSeminars: _propTypes.func
};
exports.default = SeminarGroup;

},{"prop-types":"prop-types","react":"react"}],24:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _DayFilter = require("./DayFilter.jsx");

var _DayFilter2 = _interopRequireDefault(_DayFilter);

var _SponsorFilter = require("./SponsorFilter.jsx");

var _SponsorFilter2 = _interopRequireDefault(_SponsorFilter);

var _SeminarGroup = require("./SeminarGroup.jsx");

var _SeminarGroup2 = _interopRequireDefault(_SeminarGroup);

var _SeminarCard = require("./SeminarCard.jsx");

var _SeminarCard2 = _interopRequireDefault(_SeminarCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SeminarListingApp = function (_PureComponent) {
    _inherits(SeminarListingApp, _PureComponent);

    function SeminarListingApp() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SeminarListingApp);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SeminarListingApp.__proto__ || Object.getPrototypeOf(SeminarListingApp)).call.apply(_ref, [this].concat(args))), _this), _this.getSingleSponsorProps = function (sponsorValue) {
            var _this$props = _this.props,
                seminars = _this$props.seminars,
                sponsors = _this$props.sponsors;


            var sponsorInfo = sponsors.filter(function (sponsor) {
                return sponsor.key === sponsorValue;
            });

            var sponsorSeminars = sponsorInfo.length > 0 ? seminars.filter(function (seminar) {
                return sponsorInfo[0].key === seminar.sponsorCode;
            }) : [];

            return {
                label: _this.getSponsorLabel(sponsorValue),
                cssClass: _this.getSponsorClass(sponsorValue),
                pdfLink: _this.getSponsorPDF(sponsorValue),
                key: sponsorInfo.key,
                value: sponsorInfo.value,
                seminars: sponsorSeminars,
                renderSeminars: _this.renderSeminars
            };
        }, _this.getJointProps = function () {
            var seminars = _this.props.seminars;


            var jointCode = "JOINT";

            var sponsorSeminars = seminars.filter(function (seminar) {
                return jointCode === seminar.sponsorCode;
            });

            return {
                label: _this.getSponsorLabel(jointCode),
                cssClass: _this.getSponsorClass(jointCode),
                pdfLink: _this.getSponsorPDF(jointCode),
                key: jointCode,
                value: jointCode,
                seminars: sponsorSeminars,
                renderSeminars: _this.renderSeminars
            };
        }, _this.getSponsorLabel = function (sponsorValue) {
            var _this$props2 = _this.props,
                imfLabel = _this$props2.imfLabel,
                jointSeminarLabel = _this$props2.jointSeminarLabel,
                worldBankLabel = _this$props2.worldBankLabel;


            if (sponsorValue === "IMF") {
                return imfLabel;
            } else if (sponsorValue === "WBG") {
                return worldBankLabel;
            } else {
                return jointSeminarLabel;
            }
        }, _this.getSponsorClass = function (sponsorValue) {
            if (sponsorValue === "IMF") {
                return "seminar-group imf-sems single-sponsor";
            } else if (sponsorValue === "WBG") {
                return "seminar-group wbg-sems single-sponsor";
            } else {
                return "seminar-group joint-sems";
            }
        }, _this.getSponsorPDF = function (sponsorValue) {
            var _this$props3 = _this.props,
                seminarIMFPDFLink = _this$props3.seminarIMFPDFLink,
                seminarWorldBankPDFLink = _this$props3.seminarWorldBankPDFLink,
                seminarJointPDFLink = _this$props3.seminarJointPDFLink;


            if (sponsorValue === "IMF") {
                return seminarIMFPDFLink;
            } else if (sponsorValue === "WBG") {
                return seminarWorldBankPDFLink;
            } else {
                return seminarJointPDFLink;
            }
        }, _this.renderLoader = function () {
            var isFetching = _this.props.isFetching;


            if (isFetching) {
                return _react2.default.createElement(
                    "div",
                    { className: "schedule__loading" },
                    _react2.default.createElement("img", { src: "/Assets/AMSM/images/loading.gif" })
                );
            } else {
                return null;
            }
        }, _this.renderNoResults = function () {
            var _this$props4 = _this.props,
                seminars = _this$props4.seminars,
                scheduleNoResultsLabel = _this$props4.scheduleNoResultsLabel;


            if (seminars.length > 0) {
                return null;
            } else {
                return _react2.default.createElement(
                    "div",
                    { className: "seminars__no-results" },
                    scheduleNoResultsLabel
                );
            }
        }, _this.renderSeminars = function (seminars) {
            var _this$props5 = _this.props,
                hashtagLabel = _this$props5.hashtagLabel,
                seminarTBDLabel = _this$props5.seminarTBDLabel,
                localeString = _this$props5.localeString,
                pluralModeratorsLabel = _this$props5.pluralModeratorsLabel;


            if (seminars.length > 0) {
                return seminars.map(function (seminar, index) {
                    return _react2.default.createElement(_SeminarCard2.default, _extends({
                        key: index
                    }, seminar, {
                        hashtagLabel: hashtagLabel,
                        seminarTBDLabel: seminarTBDLabel,
                        localeString: localeString,
                        pluralModeratorsLabel: pluralModeratorsLabel
                    }));
                });
            } else {
                return null;
            }
        }, _this.render = function () {
            var _this$props6 = _this.props,
                sponsorLead = _this$props6.sponsorLead,
                selectedDate = _this$props6.selectedDate,
                selectedSponsor = _this$props6.selectedSponsor,
                updateSelectedDate = _this$props6.updateSelectedDate,
                updateSelectedSponsor = _this$props6.updateSelectedSponsor,
                defaultDateSelectionLabel = _this$props6.defaultDateSelectionLabel,
                defaultSponsorLabel = _this$props6.defaultSponsorLabel,
                daysList = _this$props6.daysList,
                sponsors = _this$props6.sponsors,
                localeString = _this$props6.localeString;


            var loader = _this.renderLoader();
            var noResults = _this.renderNoResults();

            var jointProps = _this.getJointProps();
            var imfProps = _this.getSingleSponsorProps("IMF");
            var wbgProps = _this.getSingleSponsorProps("WBG");

            var leadProps = sponsorLead === "IMF" ? imfProps : wbgProps;
            var secondProps = sponsorLead === "IMF" ? wbgProps : imfProps;

            return _react2.default.createElement(
                "div",
                { className: "seminar-listing flex" },
                _react2.default.createElement(
                    "div",
                    { className: "seminar-listing__filters" },
                    _react2.default.createElement(_DayFilter2.default, {
                        selectedDate: selectedDate,
                        defaultDateSelectionLabel: defaultDateSelectionLabel,
                        daysList: daysList,
                        updateSelectedDate: updateSelectedDate,
                        localeString: localeString
                    }),
                    _react2.default.createElement(_SponsorFilter2.default, {
                        selectedSponsor: selectedSponsor,
                        defaultSponsorLabel: defaultSponsorLabel,
                        sponsorsList: sponsors,
                        updateSelectedSponsor: updateSelectedSponsor
                    })
                ),
                _react2.default.createElement(
                    "div",
                    { className: "seminar-listing__results" },
                    loader,
                    noResults,
                    _react2.default.createElement(_SeminarGroup2.default, jointProps),
                    _react2.default.createElement(_SeminarGroup2.default, leadProps),
                    _react2.default.createElement(_SeminarGroup2.default, secondProps)
                )
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return SeminarListingApp;
}(_react.PureComponent);

SeminarListingApp.propTypes = {
    selectedDate: _propTypes.string,
    selectedSponsor: _propTypes.string,
    seminars: _propTypes.array,
    daysList: _propTypes.array,
    updateSelectedDate: _propTypes.func,
    updateSelectedSponsor: _propTypes.func,
    isFetching: _propTypes.bool,
    defaultDateSelectionLabel: _propTypes.string,
    defaultSponsorLabel: _propTypes.string,
    hashtagLabel: _propTypes.string,
    imfLabel: _propTypes.string,
    jointSeminarLabel: _propTypes.string,
    scheduleNoResultsLabel: _propTypes.string,
    sponsorLead: _propTypes.string,
    sponsors: _propTypes.array,
    worldBankLabel: _propTypes.string,
    seminarTBDLabel: _propTypes.string,
    seminarIMFPDFLink: _propTypes.string,
    seminarWorldBankPDFLink: _propTypes.string,
    seminarJointPDFLink: _propTypes.string,
    localeString: _propTypes.string,
    pluralModeratorsLabel: _propTypes.string
};
exports.default = SeminarListingApp;

},{"./DayFilter.jsx":21,"./SeminarCard.jsx":22,"./SeminarGroup.jsx":23,"./SponsorFilter.jsx":26,"prop-types":"prop-types","react":"react"}],25:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _propTypes = require("prop-types");

var _getSearchParam = require("../helpers/getSearchParam.js");

var _getSearchParam2 = _interopRequireDefault(_getSearchParam);

var _SeminarListingApp = require("./SeminarListingApp.jsx");

var _SeminarListingApp2 = _interopRequireDefault(_SeminarListingApp);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SeminarListingContainer = function (_Component) {
  _inherits(SeminarListingContainer, _Component);

  function SeminarListingContainer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SeminarListingContainer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SeminarListingContainer.__proto__ || Object.getPrototypeOf(SeminarListingContainer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isFetching: false
    }, _this.componentWillMount = function () {
      _this.hydrateState();
    }, _this.shouldComponentUpdate = function (nextProps, nextState) {
      var _this$state = _this.state,
          selectedSponsor = _this$state.selectedSponsor,
          selectedDate = _this$state.selectedDate,
          seminars = _this$state.seminars;

      // shallow comparisons

      var sponsorChanged = selectedSponsor !== nextState.selectedSponsor;
      var dateChanged = selectedDate !== nextState.selectedDate;

      // deep comparison
      var seminarsChanged = !_ramda2.default.equals(seminars, nextState.seminars);

      return sponsorChanged || dateChanged || seminarsChanged;
    }, _this.hydrateState = function () {
      var _this$props = _this.props,
          meetingsDateTimes = _this$props.meetingsDateTimes,
          sponsors = _this$props.sponsors,
          initialJson = _this$props.initialJson,
          meetingsTimezone = _this$props.meetingsTimezone;
      var _this$state2 = _this.state,
          selectedDate = _this$state2.selectedDate,
          selectedSponsor = _this$state2.selectedSponsor;


      var searchParams = new URLSearchParams(window.location);

      // set sponsor from url, if it's specified and a valid option
      var sponsorQuery = (0, _getSearchParam2.default)("sponsor");
      console.log(sponsorQuery);
      var validSponsor = sponsorQuery ? sponsors.filter(function (sponsor) {
        return sponsor.key === sponsorQuery;
      }) : null;
      var sponsorToSet = validSponsor && validSponsor.length > 0 ? validSponsor[0].key : null;

      // set date from url, if it's specified and a valid option
      var dateQuery = (0, _getSearchParam2.default)("date");
      var cleanDateQuery = dateQuery ? dateQuery.split("T")[0] + "T00:00:00" : null;
      var validDate = cleanDateQuery ? meetingsDateTimes.filter(function (date) {
        return date === cleanDateQuery;
      }) : null;
      var dateToSet = validDate && validDate.length > 0 ? validDate[0] : null;

      // set timezone from props, reassigning the default if left blank
      var timezoneToSet = meetingsTimezone === "" ? "America/New_York" : meetingsTimezone;

      _this.setState({
        daysList: meetingsDateTimes.sort(),
        selectedDate: dateToSet,
        selectedSponsor: sponsorToSet,
        meetingsTimezone: timezoneToSet,
        seminars: _this.sortSeminarsByDate(initialJson)
      });
    }, _this.fetchData = function () {
      _this.setState({
        isFetching: true
      });

      // update the url visible to the user
      _this.updateUrl();

      var serviceWithParams = _this.generateServiceUrl();

      return fetch(serviceWithParams).then(function (response) {
        return response.json();
      }).then(function (data) {
        _this.setState({
          seminars: _this.sortSeminarsByDate(data),
          isFetching: false
        });
      }).catch(function (error) {
        console.error("There has been a problem with your fetch operation: " + error.message);
      });
    }, _this.sortSeminarsByDate = function (seminars) {
      // pull out seminars without dates, they mess up sorting (shouldn't exist anyway)
      var noDateSeminars = seminars.filter(function (seminar) {
        return !seminar.startDate;
      });

      var seminarsWithStartDates = seminars.filter(function (seminar) {
        return seminar.startDate;
      });

      var sortedSeminars = seminarsWithStartDates.sort(function (a, b) {
        // if TBD, we need to use a different field to compare by
        var dateTimeA = a.isTBD ? a.meetingDay : a.startDate;
        var dateTimeB = b.isTBD ? b.meetingDay : b.startDate;

        // if start time is the same, earlier end time goes first
        if (dateTimeA === dateTimeB) {
          // if no end time is set, use meeting day (which has time 00:00:00)
          var endTimeA = a.endDate ? a.endDate : a.meetingDay;
          var endTimeB = b.endDate ? b.endDate : b.meetingDay;
          return new Date(endTimeA) - new Date(endTimeB);
        } else {
          // sort earliest to latest
          return new Date(dateTimeA) - new Date(dateTimeB);
        }
      });

      return noDateSeminars.concat(sortedSeminars);
    }, _this.updateUrl = function () {
      var _this$state3 = _this.state,
          selectedDate = _this$state3.selectedDate,
          selectedSponsor = _this$state3.selectedSponsor;

      // update the url to reflect our state,
      // and don't wipe out any existing (sitecore) params

      var params = new URLSearchParams(location.search);

      if (selectedDate) {
        var cleanDate = selectedDate.split("T")[0];
        params.set("date", cleanDate);
      } else {
        params.delete("date");
      }

      if (selectedSponsor) {
        params.set("sponsor", selectedSponsor);
      } else {
        params.delete("sponsor");
      }

      window.history.replaceState({}, "", location.pathname + "?" + params);
    }, _this.generateServiceUrl = function () {
      var serviceUrl = _this.props.serviceUrl;

      // set up the url for the service request

      var joinedParamsForService = [_this.generatePropsParams(), _this.generateStateParams()].join("&");
      return serviceUrl + "?" + joinedParamsForService;
    }, _this.generatePropsParams = function () {
      var _this$props2 = _this.props,
          homeNodeGUID = _this$props2.homeNodeGUID,
          localeString = _this$props2.localeString,
          databaseName = _this$props2.databaseName;


      var homeNodeParam = homeNodeGUID ? "homeNode={" + homeNodeGUID + "}" : "homeNode=";

      var languageParam = localeString ? "language=" + localeString : "language=";

      var databaseParam = databaseName ? "databaseName=" + databaseName : "databaseName=";

      return [homeNodeParam, languageParam, databaseParam].join("&");
    }, _this.generateStateParams = function () {
      var _this$state4 = _this.state,
          selectedDate = _this$state4.selectedDate,
          selectedSponsor = _this$state4.selectedSponsor;


      var dateParam = selectedDate ? "date=" + selectedDate : "";

      var sponsorParam = selectedSponsor ? "sponsor=" + selectedSponsor : "sponsor=";

      return [dateParam, sponsorParam].join("&");
    }, _this.updateSelectedDate = function (newDate) {
      _this.setState({
        selectedDate: newDate
      }, function () {
        _this.fetchData();
      });
    }, _this.updateSelectedSponsor = function (newSponsor) {
      _this.setState({
        selectedSponsor: newSponsor
      }, function () {
        _this.fetchData();
      });
    }, _this.render = function () {
      return _react2.default.createElement(_SeminarListingApp2.default, {
        selectedDate: _this.state.selectedDate,
        selectedSponsor: _this.state.selectedSponsor,
        seminars: _this.state.seminars,
        daysList: _this.state.daysList,
        updateSelectedDate: _this.updateSelectedDate,
        updateSelectedSponsor: _this.updateSelectedSponsor,
        isFetching: _this.state.isFetching,
        defaultDateSelectionLabel: _this.props.defaultDateSelectionLabel,
        defaultSponsorLabel: _this.props.defaultSponsorLabel,
        hashtagLabel: _this.props.hashtagLabel,
        imfLabel: _this.props.imfLabel,
        jointSeminarLabel: _this.props.jointSeminarLabel,
        localeString: _this.props.localeString,
        meetingsDateTimes: _this.props.meetingsDateTimes,
        meetingsTimezone: _this.state.meetingsTimezone,
        printDevice: _this.props.printDevice,
        printPDFLabel: _this.props.printPDFLabel,
        printPDFUrl: _this.props.printPDFUrl,
        scheduleNoResultsLabel: _this.props.scheduleNoResultsLabel,
        sponsorLead: _this.props.sponsorLead,
        sponsors: _this.props.sponsors,
        worldBankLabel: _this.props.worldBankLabel,
        seminarTBDLabel: _this.props.seminarTBDLabel,
        seminarIMFPDFLink: _this.props.seminarIMFPDFLink,
        seminarWorldBankPDFLink: _this.props.seminarWorldBankPDFLink,
        seminarJointPDFLink: _this.props.seminarJointPDFLink,
        pluralModeratorsLabel: _this.props.pluralModeratorsLabel
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return SeminarListingContainer;
}(_react.Component);

SeminarListingContainer.propTypes = {
  defaultDateSelectionLabel: _propTypes.string,
  defaultSponsorLabel: _propTypes.string,
  hashtagLabel: _propTypes.string,
  homeNodeGUID: _propTypes.string,
  imfLabel: _propTypes.string,
  initialJson: _propTypes.array,
  jointSeminarLabel: _propTypes.string,
  localeString: _propTypes.string,
  meetingsDateTimes: _propTypes.array,
  meetingsTimezone: _propTypes.string,
  printDevice: _propTypes.string,
  printPDFLabel: _propTypes.string,
  printPDFUrl: _propTypes.string,
  scheduleNoResultsLabel: _propTypes.string,
  serviceUrl: _propTypes.string,
  sponsorLead: _propTypes.string,
  sponsors: _propTypes.array,
  worldBankLabel: _propTypes.string,
  seminarTBDLabel: _propTypes.string,
  seminarIMFPDFLink: _propTypes.string,
  seminarWorldBankPDFLink: _propTypes.string,
  seminarJointPDFLink: _propTypes.string,
  databaseName: _propTypes.string,
  pluralModeratorsLabel: _propTypes.string
};
exports.default = SeminarListingContainer;

},{"../helpers/getSearchParam.js":4,"./SeminarListingApp.jsx":24,"prop-types":"prop-types","ramda":"ramda","react":"react"}],26:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SponsorFilter = function (_PureComponent) {
	_inherits(SponsorFilter, _PureComponent);

	function SponsorFilter() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, SponsorFilter);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SponsorFilter.__proto__ || Object.getPrototypeOf(SponsorFilter)).call.apply(_ref, [this].concat(args))), _this), _this.handleChange = function (event) {
			var updateSelectedSponsor = _this.props.updateSelectedSponsor;

			updateSelectedSponsor(event.target.value);
		}, _this.render = function () {
			var _this$props = _this.props,
			    selectedSponsor = _this$props.selectedSponsor,
			    defaultSponsorLabel = _this$props.defaultSponsorLabel,
			    sponsorsList = _this$props.sponsorsList;


			if (sponsorsList && sponsorsList.length > 0) {
				var optionList = sponsorsList.map(function (item, index) {
					return _react2.default.createElement(
						"option",
						{ key: index, value: item.key },
						item.value
					);
				});

				return _react2.default.createElement(
					"div",
					{ className: "filtr" },
					_react2.default.createElement(
						"select",
						{ className: "custom-select", value: selectedSponsor || "", onChange: _this.handleChange },
						_react2.default.createElement(
							"option",
							{ value: "" },
							defaultSponsorLabel
						),
						optionList
					)
				);
			} else {
				return null;
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return SponsorFilter;
}(_react.PureComponent);

SponsorFilter.propTypes = {
	selectedSponsor: _propTypes.string,
	defaultSponsorLabel: _propTypes.string,
	sponsorsList: _propTypes.array,
	updateSelectedSponsor: _propTypes.func
};
exports.default = SponsorFilter;

},{"prop-types":"prop-types","react":"react"}],27:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

var _SeminarListingContainer = require("./SeminarListingContainer.jsx");

var _SeminarListingContainer2 = _interopRequireDefault(_SeminarListingContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var seminarAppClass = ".js-seminar-listing-app";

exports.default = {
    selector: seminarAppClass,
    init: init
};


function init() {
    (0, _jquery2.default)(seminarAppClass).each(function (index, el) {
        _reactDom2.default.render(_react2.default.createElement(_SeminarListingContainer2.default, _extends({
            id: index
        }, window.backendSeminarListingProps)), el);
    });
}

},{"./SeminarListingContainer.jsx":25,"jquery":"jquery","react":"react","react-dom":"react-dom"}],28:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ReturnToMain = function (_PureComponent) {
    _inherits(ReturnToMain, _PureComponent);

    function ReturnToMain() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, ReturnToMain);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ReturnToMain.__proto__ || Object.getPrototypeOf(ReturnToMain)).call.apply(_ref, [this].concat(args))), _this), _this.componentWillMount = function () {
            var selectedKeyword = _this.props.selectedKeyword;


            _this.setState({
                localKeyword: selectedKeyword
            });
        }, _this.componentWillReceiveProps = function (nextProps) {
            var localKeyword = _this.state.localKeyword;


            if (nextProps.selectedKeyword !== localKeyword) {
                _this.setState({
                    localKeyword: nextProps.selectedKeyword
                });
            }
        }, _this.handleChange = function (event) {
            _this.setState({
                localKeyword: event.target.value
            });
        }, _this.handleSubmit = function (event) {
            var updateSelectedKeyword = _this.props.updateSelectedKeyword;
            var localKeyword = _this.state.localKeyword;


            event.preventDefault();

            updateSelectedKeyword(localKeyword);
        }, _this.render = function () {
            var _this$props = _this.props,
                selectedKeyword = _this$props.selectedKeyword,
                searchButtonLabel = _this$props.searchButtonLabel,
                keywordPlaceholderLabel = _this$props.keywordPlaceholderLabel;
            var localKeyword = _this.state.localKeyword;


            return _react2.default.createElement(
                "form",
                { className: "speech-fields", onSubmit: _this.handleSubmit },
                _react2.default.createElement("input", { className: "filtr filtr--keyword", type: "text", placeholder: keywordPlaceholderLabel, value: localKeyword || "", onChange: _this.handleChange }),
                _react2.default.createElement(
                    "button",
                    { type: "submit", className: "speech-fields__keyword-submit text" },
                    searchButtonLabel
                )
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return ReturnToMain;
}(_react.PureComponent);

ReturnToMain.propTypes = {
    selectedKeyword: _propTypes.string,
    keywordPlaceholderLabel: _propTypes.string,
    searchButtonLabel: _propTypes.string,
    updateSelectedKeyword: _propTypes.func
};
exports.default = ReturnToMain;

},{"prop-types":"prop-types","react":"react"}],29:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _Speech = require("./Speech.jsx");

var _Speech2 = _interopRequireDefault(_Speech);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Results = function (_PureComponent) {
	_inherits(Results, _PureComponent);

	function Results() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, Results);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Results.__proto__ || Object.getPrototypeOf(Results)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
			var _this$props = _this.props,
			    results = _this$props.results,
			    noResultsFoundLabel = _this$props.noResultsFoundLabel,
			    fullTextLabel = _this$props.fullTextLabel,
			    isFetching = _this$props.isFetching,
			    videoPageUrl = _this$props.videoPageUrl;


			if (isFetching) {
				return _react2.default.createElement(
					"section",
					{ className: "result-list" },
					_react2.default.createElement("img", { src: "/Assets/AMSM/images/loading.gif" })
				);
			} else if (results && results.length > 0) {
				var list = results.map(function (item, index) {
					return _react2.default.createElement(_Speech2.default, _extends({
						key: index,
						fullTextLabel: fullTextLabel,
						videoPageUrl: videoPageUrl
					}, item));
				});

				return _react2.default.createElement(
					"section",
					{ className: "result-list" },
					list
				);
			} else {
				return _react2.default.createElement(
					"section",
					{ className: "result-list" },
					noResultsFoundLabel
				);
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return Results;
}(_react.PureComponent);

Results.propTypes = {
	results: _propTypes.array,
	noResultsFoundLabel: _propTypes.string,
	fullTextLabel: _propTypes.string,
	isFetching: _propTypes.bool,
	localeString: _propTypes.string,
	videoPageUrl: _propTypes.string
};
exports.default = Results;

},{"./Speech.jsx":33,"prop-types":"prop-types","react":"react"}],30:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _ResultsSummary = require("./ResultsSummary.jsx");

var _ResultsSummary2 = _interopRequireDefault(_ResultsSummary);

var _YearFilter = require("./YearFilter.jsx");

var _YearFilter2 = _interopRequireDefault(_YearFilter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ResultsControls = function (_PureComponent) {
    _inherits(ResultsControls, _PureComponent);

    function ResultsControls() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, ResultsControls);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ResultsControls.__proto__ || Object.getPrototypeOf(ResultsControls)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
            var _this$props = _this.props,
                isFetching = _this$props.isFetching,
                hasSearched = _this$props.hasSearched,
                resultsSummaryProps = _this$props.resultsSummaryProps,
                yearPaginationProps = _this$props.yearPaginationProps;


            if (!hasSearched || isFetching) {
                return null;
            } else {
                return _react2.default.createElement(
                    "div",
                    { className: "hold pg-results" },
                    _react2.default.createElement(_ResultsSummary2.default, resultsSummaryProps),
                    _react2.default.createElement(_YearFilter2.default, yearPaginationProps)
                );
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return ResultsControls;
}(_react.PureComponent);

ResultsControls.propTypes = {
    hasSearched: _propTypes.bool,
    isFetching: _propTypes.bool,
    resultsSummaryProps: _propTypes.object,
    yearPaginationProps: _propTypes.object
};
exports.default = ResultsControls;

},{"./ResultsSummary.jsx":31,"./YearFilter.jsx":35,"prop-types":"prop-types","react":"react"}],31:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ResultsSummary = function (_PureComponent) {
	_inherits(ResultsSummary, _PureComponent);

	function ResultsSummary() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, ResultsSummary);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ResultsSummary.__proto__ || Object.getPrototypeOf(ResultsSummary)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
			var _this$props = _this.props,
			    totalResults = _this$props.totalResults,
			    numberOfSearchLabel = _this$props.numberOfSearchLabel,
			    noResultsFoundLabel = _this$props.noResultsFoundLabel,
			    selectedKeyword = _this$props.selectedKeyword,
			    sortedByDateLabel = _this$props.sortedByDateLabel;


			if (totalResults === 0) {
				return _react2.default.createElement(
					"p",
					{ className: "results" },
					noResultsFoundLabel
				);
			} else {
				var tokensReplacedString = numberOfSearchLabel.replace("{0}", '"' + selectedKeyword + '"').replace("{1}", totalResults);

				return _react2.default.createElement(
					"p",
					{ className: "results" },
					tokensReplacedString,
					" ",
					sortedByDateLabel,
					"."
				);
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return ResultsSummary;
}(_react.PureComponent);

ResultsSummary.propTypes = {
	totalResults: _propTypes.number,
	numberOfSearchLabel: _propTypes.string,
	sortedByDateLabel: _propTypes.string,
	selectedKeyword: _propTypes.string,
	fullTextLabel: _propTypes.string,
	noResultsFoundLabel: _propTypes.string
};
exports.default = ResultsSummary;

},{"prop-types":"prop-types","react":"react"}],32:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ReturnToMain = function (_PureComponent) {
    _inherits(ReturnToMain, _PureComponent);

    function ReturnToMain() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, ReturnToMain);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ReturnToMain.__proto__ || Object.getPrototypeOf(ReturnToMain)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
            var _this$props = _this.props,
                hasSearched = _this$props.hasSearched,
                returnToMainLabel = _this$props.returnToMainLabel,
                clearAll = _this$props.clearAll;


            if (hasSearched) {
                return _react2.default.createElement(
                    "div",
                    null,
                    _react2.default.createElement(
                        "button",
                        { className: "clear-all", onClick: clearAll },
                        returnToMainLabel
                    )
                );
            } else {
                return null;
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return ReturnToMain;
}(_react.PureComponent);

ReturnToMain.propTypes = {
    hasSearched: _propTypes.bool,
    returnToMainLabel: _propTypes.string,
    clearAll: _propTypes.func
};
exports.default = ReturnToMain;

},{"prop-types":"prop-types","react":"react"}],33:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Speech = function (_PureComponent) {
    _inherits(Speech, _PureComponent);

    function Speech() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, Speech);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Speech.__proto__ || Object.getPrototypeOf(Speech)).call.apply(_ref, [this].concat(args))), _this), _this.renderImage = function () {
            var speakerImageUrl = _this.props.speakerImageUrl;


            if (speakerImageUrl && speakerImageUrl !== "") {
                return _react2.default.createElement("img", { src: speakerImageUrl });
            } else {
                return null;
            }
        }, _this.renderCountries = function () {
            var countries = _this.props.countries;


            if (countries && countries.length > 0) {
                var countriesList = countries.map(function (country, index) {
                    return _react2.default.createElement(
                        "p",
                        { key: index },
                        country
                    );
                });

                return _react2.default.createElement(
                    "div",
                    { className: "speech__countries" },
                    countriesList
                );
            } else {
                return null;
            }
        }, _this.renderTitle = function () {
            var _this$props = _this.props,
                title = _this$props.title,
                pdfLink = _this$props.pdfLink,
                videoPageUrl = _this$props.videoPageUrl,
                videoId = _this$props.videoId;


            if (title && title !== "") {
                // if vid specified, it gets link priority
                if (videoId && videoPageUrl) {
                    var link = videoPageUrl + "?videoId=" + videoId;
                    return _react2.default.createElement(
                        "p",
                        { className: "speech__title" },
                        _react2.default.createElement(
                            "a",
                            { href: link },
                            title,
                            _react2.default.createElement("span", { className: "icon-video-camera" })
                        )
                    );
                    // if no vid, link to pdf
                } else if (pdfLink && pdfLink !== "") {
                    return _react2.default.createElement(
                        "p",
                        { className: "speech__title" },
                        _react2.default.createElement(
                            "a",
                            { href: pdfLink },
                            title
                        )
                    );
                    // if no vid or pdf, no link
                } else {
                    return _react2.default.createElement(
                        "p",
                        { className: "speech__title" },
                        title
                    );
                }
            } else {
                return null;
            }
        }, _this.renderFullTextList = function () {
            var _this$props2 = _this.props,
                documents = _this$props2.documents,
                fullTextLabel = _this$props2.fullTextLabel,
                pdfLink = _this$props2.pdfLink,
                videoId = _this$props2.videoId;


            if (documents && documents.length > 0) {
                if (documents.length === 1) {
                    if (!videoId) {
                        return;
                    }
                }

                var filteredDocuments = documents.filter(function (document) {
                    return document.pdfLink && document.pdfLink !== "";
                });

                if (filteredDocuments.length > 0) {
                    var list = filteredDocuments.map(function (document, index) {
                        return _react2.default.createElement(
                            "a",
                            { key: index, href: document.pdfLink },
                            document.languageName
                        );
                    });

                    return _react2.default.createElement(
                        "div",
                        { className: "speech__row" },
                        _react2.default.createElement(
                            "p",
                            { className: "speech__full-text-list" },
                            fullTextLabel,
                            list
                        )
                    );
                }
            } else {
                return null;
            }
        }, _this.render = function () {
            return _react2.default.createElement(
                "div",
                { className: "hold speech" },
                _react2.default.createElement(
                    "div",
                    { className: "speech__row" },
                    _react2.default.createElement(
                        "div",
                        { className: "speech__img-container" },
                        _this.renderImage()
                    ),
                    _react2.default.createElement(
                        "div",
                        { className: "speech__text-container" },
                        _this.renderCountries(),
                        _this.renderTitle()
                    )
                ),
                _this.renderFullTextList()
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return Speech;
}(_react.PureComponent);

Speech.propTypes = {
    countries: _propTypes.array,
    documents: _propTypes.array,
    fullTextLabel: _propTypes.string,
    pdfLink: _propTypes.string,
    speakerImageUrl: _propTypes.string,
    title: _propTypes.string,
    videoId: _propTypes.string,
    videoPageUrl: _propTypes.string,
    year: _propTypes.number
};
exports.default = Speech;

},{"prop-types":"prop-types","react":"react"}],34:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _propTypes = require("prop-types");

var _getSearchParam = require("../helpers/getSearchParam.js");

var _getSearchParam2 = _interopRequireDefault(_getSearchParam);

var _KeywordFilter = require("./KeywordFilter.jsx");

var _KeywordFilter2 = _interopRequireDefault(_KeywordFilter);

var _ResultsControls = require("./ResultsControls.jsx");

var _ResultsControls2 = _interopRequireDefault(_ResultsControls);

var _ReturnToMain = require("./ReturnToMain.jsx");

var _ReturnToMain2 = _interopRequireDefault(_ReturnToMain);

var _Results = require("./Results.jsx");

var _Results2 = _interopRequireDefault(_Results);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SpeechesSearchAppContainer = function (_Component) {
    _inherits(SpeechesSearchAppContainer, _Component);

    function SpeechesSearchAppContainer() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SpeechesSearchAppContainer);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SpeechesSearchAppContainer.__proto__ || Object.getPrototypeOf(SpeechesSearchAppContainer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            selectedKeyword: "",
            selectedYear: null,
            results: null,
            totalResults: 0,
            isFetching: false,
            hasSearched: false
        }, _this.componentWillMount = function () {
            var mergedState = _ramda2.default.mergeAll([_this.state, _this.hydrateState()]);

            _this.setState(mergedState);
        }, _this.hydrateState = function () {
            var _this$state = _this.state,
                selectedKeyword = _this$state.selectedKeyword,
                selectedYear = _this$state.selectedYear;
            var _this$props = _this.props,
                years = _this$props.years,
                initialJson = _this$props.initialJson,
                totalResults = _this$props.totalResults,
                currentMeetingYear = _this$props.currentMeetingYear;


            var searchParams = new URLSearchParams(window.location);

            // check URL for query params
            var yearQuery = (0, _getSearchParam2.default)("year");
            var keywordQuery = (0, _getSearchParam2.default)("keywords");

            // determine whether to display results controls
            var hasSearched = yearQuery !== false || keywordQuery !== false;

            return {
                selectedYear: parseInt(yearQuery) || currentMeetingYear,
                selectedKeyword: keywordQuery || selectedKeyword,
                hasSearched: hasSearched,
                years: _this.sortYears(years),
                results: initialJson,
                totalResults: totalResults
            };
        }, _this.sortYears = function (years) {
            var currentMeetingYear = _this.props.currentMeetingYear;


            if (!years || years.length === 0) {
                return [currentMeetingYear];
            } else {
                // strip any "1s" (unset years), then reverse sort 
                var cleanYears = years.filter(function (year) {
                    return year > 1;
                });
                var diff = function diff(a, b) {
                    return a - b;
                };
                return _ramda2.default.reverse(_ramda2.default.sort(diff, cleanYears));
            }
        }, _this.fetchData = function () {
            var selectedYear = _this.state.selectedYear;


            _this.setState({
                isFetching: true
            });

            var serviceWithParams = _this.generateServiceUrl();

            return fetch(serviceWithParams).then(function (response) {
                return response.json();
            }).then(function (data) {
                _this.setState({
                    results: data.results,
                    totalResults: data.totalResults,
                    years: _this.sortYears(data.Years),
                    selectedYear: data.Years.length === 1 ? data.Years[0] : selectedYear,
                    isFetching: false
                });
            }).catch(function (error) {
                console.error("There has been a problem with your fetch operation: " + error.message);
            });
        }, _this.generateServiceUrl = function () {
            var _this$props2 = _this.props,
                serviceUrl = _this$props2.serviceUrl,
                localeString = _this$props2.localeString,
                databaseName = _this$props2.databaseName,
                currentMeetingYear = _this$props2.currentMeetingYear;
            var _this$state2 = _this.state,
                selectedKeyword = _this$state2.selectedKeyword,
                selectedYear = _this$state2.selectedYear,
                hasSearched = _this$state2.hasSearched;


            var languageParam = localeString ? "language=" + localeString : "language=";

            var keywordParam = selectedKeyword ? "keywords=" + selectedKeyword : "keywords=";

            var yearParam = selectedYear ? "year=" + selectedYear : "year=";

            var perPageParam = "perPage=100"; // not configurable, but required by service

            var databaseParam = databaseName ? "databaseName=" + databaseName : "databaseName=";

            var numberedOrderParam = selectedYear === currentMeetingYear ? "numberedOrder=true" : "numberedOrder=false";

            var joinedParams = [languageParam, keywordParam, yearParam, perPageParam, databaseParam, numberedOrderParam].join("&");

            return serviceUrl + "?" + joinedParams;
        }, _this.updateVisibleUrl = function (paramString, newValue) {
            // update, add or remove a parameter
            // without wiping out any existing params
            var params = new URLSearchParams(location.search);

            if (newValue && newValue !== "") {
                params.set(paramString, newValue);
            } else {
                params.delete(paramString);
            }

            window.history.replaceState({}, '', location.pathname + "?" + params);
        }, _this.updateSelectedKeyword = function (newKeyword) {
            var selectedKeyword = _this.state.selectedKeyword;


            if (newKeyword === "" || !newKeyword) {
                _this.clearAll();
            } else {
                _this.updateVisibleUrl("keywords", newKeyword);

                var hasSearched = newKeyword !== "";

                _this.setState({
                    selectedKeyword: newKeyword,
                    selectedYear: null,
                    hasSearched: hasSearched
                }, function () {
                    _this.fetchData();
                });
            }
        }, _this.updateSelectedYear = function (newYear) {
            var selectedYear = _this.state.selectedYear;
            var currentMeetingYear = _this.props.currentMeetingYear;


            _this.updateVisibleUrl("year", newYear);

            var hasSearched = newYear !== null;

            _this.setState({
                selectedYear: newYear,
                hasSearched: hasSearched
            }, function () {
                _this.fetchData();
            });
        }, _this.clearAll = function () {
            var currentMeetingYear = _this.props.currentMeetingYear;


            var params = new URLSearchParams(location.search);
            params.delete("year");
            params.delete("keywords");
            window.history.replaceState({}, '', location.pathname + "?" + params);

            _this.setState({
                hasSearched: false,
                selectedYear: currentMeetingYear,
                selectedKeyword: ""
            }, function () {
                _this.fetchData();
            });
        }, _this.render = function () {
            var _this$props3 = _this.props,
                numberOfSearchLabel = _this$props3.numberOfSearchLabel,
                returnToMainLabel = _this$props3.returnToMainLabel,
                searchButtonLabel = _this$props3.searchButtonLabel,
                keywordPlaceholderLabel = _this$props3.keywordPlaceholderLabel,
                sortedByDateLabel = _this$props3.sortedByDateLabel,
                fullTextLabel = _this$props3.fullTextLabel,
                noResultsFoundLabel = _this$props3.noResultsFoundLabel,
                localeString = _this$props3.localeString,
                videoPageUrl = _this$props3.videoPageUrl;
            var _this$state3 = _this.state,
                selectedKeyword = _this$state3.selectedKeyword,
                selectedYear = _this$state3.selectedYear,
                years = _this$state3.years,
                results = _this$state3.results,
                totalResults = _this$state3.totalResults,
                isFetching = _this$state3.isFetching,
                hasSearched = _this$state3.hasSearched;


            var resultsSummaryProps = {
                totalResults: totalResults,
                numberOfSearchLabel: numberOfSearchLabel,
                sortedByDateLabel: sortedByDateLabel,
                selectedKeyword: selectedKeyword
            };

            var yearPaginationProps = {
                selectedYear: selectedYear,
                updateSelectedYear: _this.updateSelectedYear,
                years: years,
                localeString: localeString
            };

            return _react2.default.createElement(
                "section",
                { className: "speeches-search-app one-col" },
                _react2.default.createElement(_KeywordFilter2.default, {
                    selectedKeyword: selectedKeyword,
                    keywordPlaceholderLabel: keywordPlaceholderLabel,
                    searchButtonLabel: searchButtonLabel,
                    updateSelectedKeyword: _this.updateSelectedKeyword
                }),
                _react2.default.createElement(_ResultsControls2.default, {
                    hasSearched: hasSearched,
                    isFetching: isFetching,
                    resultsSummaryProps: resultsSummaryProps,
                    yearPaginationProps: yearPaginationProps
                }),
                _react2.default.createElement(_ReturnToMain2.default, {
                    hasSearched: hasSearched,
                    returnToMainLabel: returnToMainLabel,
                    clearAll: _this.clearAll
                }),
                _react2.default.createElement(_Results2.default, {
                    results: results,
                    noResultsFoundLabel: noResultsFoundLabel,
                    fullTextLabel: fullTextLabel,
                    isFetching: isFetching,
                    localeString: localeString,
                    videoPageUrl: videoPageUrl
                })
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return SpeechesSearchAppContainer;
}(_react.Component);

SpeechesSearchAppContainer.propTypes = {
    localeString: _propTypes.string,
    numberOfSearchLabel: _propTypes.string,
    returnToMainLabel: _propTypes.string,
    searchButtonLabel: _propTypes.string,
    keywordPlaceholderLabel: _propTypes.string,
    sortedByDateLabel: _propTypes.string,
    fullTextLabel: _propTypes.string,
    currentMeetingYear: _propTypes.number,
    years: _propTypes.array,
    serviceUrl: _propTypes.string,
    noResultsFoundLabel: _propTypes.string,
    videoPageUrl: _propTypes.string,
    initialJson: _propTypes.array,
    totalResults: _propTypes.number,
    databaseName: _propTypes.string
};
exports.default = SpeechesSearchAppContainer;

},{"../helpers/getSearchParam.js":4,"./KeywordFilter.jsx":28,"./Results.jsx":29,"./ResultsControls.jsx":30,"./ReturnToMain.jsx":32,"prop-types":"prop-types","ramda":"ramda","react":"react"}],35:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var YearFilter = function (_PureComponent) {
    _inherits(YearFilter, _PureComponent);

    function YearFilter() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, YearFilter);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = YearFilter.__proto__ || Object.getPrototypeOf(YearFilter)).call.apply(_ref, [this].concat(args))), _this), _this.renderYears = function (yearsToShow) {
            var _this$props = _this.props,
                selectedYear = _this$props.selectedYear,
                updateSelectedYear = _this$props.updateSelectedYear;


            return yearsToShow.map(function (year, index) {
                if (year === selectedYear) {
                    return _react2.default.createElement(
                        "li",
                        { key: index, className: "year--selected" },
                        _react2.default.createElement(
                            "button",
                            null,
                            _this.returnLocaleYear(year)
                        )
                    );
                } else if (selectedYear && year !== selectedYear) {
                    return _react2.default.createElement(
                        "li",
                        { key: index, className: "year--not-selected" },
                        _react2.default.createElement(
                            "button",
                            { onClick: _this.handleYearClick, value: year },
                            _this.returnLocaleYear(year)
                        )
                    );
                } else {
                    return _react2.default.createElement(
                        "li",
                        { key: index, className: "year" },
                        _react2.default.createElement(
                            "button",
                            { onClick: _this.handleYearClick, value: year },
                            _this.returnLocaleYear(year)
                        )
                    );
                }
            });
        }, _this.returnLocaleYear = function (year) {
            var localeString = _this.props.localeString;


            var yearDate = new Date("01/01/" + year);
            return yearDate.toLocaleDateString(localeString, { year: 'numeric' });
        }, _this.renderNavButton = function (navObj) {
            var cssClass = navObj.itemClass ? "nav " + navObj.itemClass : "nav";

            return _react2.default.createElement(
                "li",
                { className: cssClass },
                _react2.default.createElement("button", { className: navObj.iconClass, onClick: _this.handleYearClick, value: navObj.goToYear, disabled: navObj.disabledCondition })
            );
        }, _this.getYearsToShow = function () {
            var _this$props2 = _this.props,
                selectedYear = _this$props2.selectedYear,
                years = _this$props2.years;

            // only show 5 years at a time

            if (years.length > 5) {

                if (selectedYear) {
                    var midpoint = years.indexOf(selectedYear);

                    // define safe slice points so the slice doesn't go out of array bounds
                    var beforeStart = midpoint - 2 < 0 ? 0 : midpoint - 2;
                    var afterStart = midpoint + 1 > years.length - 1 ? years.length - 1 : midpoint + 1;
                    var afterEnd = midpoint + 3 > years.length - 1 ? years.length - 1 : midpoint + 3;

                    var before = years.slice(beforeStart, midpoint);
                    var after = years.slice(afterStart, afterEnd);

                    var symmetricalList = before.concat(selectedYear, after);

                    if (symmetricalList.length < 5) {
                        var startPoint = years.indexOf(symmetricalList[0]);
                        var endPoint = years.indexOf(symmetricalList[symmetricalList.length - 1]);

                        if (startPoint === 0) {
                            return years.slice(0, 5);
                        } else {
                            return years.slice(Math.max(years.length - 5, 0));
                        }
                    } else {
                        return symmetricalList;
                    }
                } else {
                    return years.slice(0, 5);
                }
            } else {
                return years;
            }
        }, _this.handleYearClick = function (event) {
            var updateSelectedYear = _this.props.updateSelectedYear;


            updateSelectedYear(parseInt(event.target.value));
        }, _this.render = function () {
            var _this$props3 = _this.props,
                years = _this$props3.years,
                selectedYear = _this$props3.selectedYear;


            if (years && years.length > 0) {
                var yearsToShow = _this.getYearsToShow();
                var yearsList = _this.renderYears(yearsToShow);
                var firstYear = years[0];
                var lastYear = years[years.length - 1];

                var firstButton = _this.renderNavButton({
                    yearsToShow: yearsToShow,
                    goToYear: firstYear,
                    disabledCondition: selectedYear && firstYear === selectedYear,
                    iconClass: "icon-first",
                    itemClass: "nav--first"
                });

                var prevButton = _this.renderNavButton({
                    yearsToShow: yearsToShow,
                    goToYear: selectedYear ? years[years.indexOf(selectedYear) - 1] : firstYear,
                    disabledCondition: selectedYear && firstYear === selectedYear,
                    iconClass: "icon-backward2",
                    itemClass: "nav--prev"
                });

                var nextButton = _this.renderNavButton({
                    yearsToShow: yearsToShow,
                    goToYear: selectedYear ? years[years.indexOf(selectedYear) + 1] : lastYear,
                    disabledCondition: selectedYear && lastYear === selectedYear,
                    iconClass: "icon-forward3",
                    itemClass: "nav--next"
                });

                var lastButton = _this.renderNavButton({
                    yearsToShow: yearsToShow,
                    goToYear: lastYear,
                    disabledCondition: selectedYear && lastYear === selectedYear,
                    iconClass: "icon-last",
                    itemClass: "nav--last"
                });

                var cssClass = selectedYear ? "speeches__pagination speeches__pagination--year-selected" : "speeches__pagination speeches__pagination--no-selection";

                return _react2.default.createElement(
                    "ul",
                    { className: cssClass },
                    firstButton,
                    prevButton,
                    yearsList,
                    nextButton,
                    lastButton
                );
            } else {
                return null;
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return YearFilter;
}(_react.PureComponent);

YearFilter.propTypes = {
    selectedYear: _propTypes.number,
    years: _propTypes.array,
    updateSelectedYear: _propTypes.func,
    localeString: _propTypes.string
};
exports.default = YearFilter;

},{"prop-types":"prop-types","react":"react"}],36:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

var _SpeechesSearchAppContainer = require("./SpeechesSearchAppContainer.jsx");

var _SpeechesSearchAppContainer2 = _interopRequireDefault(_SpeechesSearchAppContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var speechesSearchAppClass = ".js-speeches-search-app";

exports.default = {
    selector: speechesSearchAppClass,
    init: init
};


function init() {
    (0, _jquery2.default)(speechesSearchAppClass).each(function (index, el) {
        _reactDom2.default.render(_react2.default.createElement(_SpeechesSearchAppContainer2.default, _extends({
            id: index
        }, window.backendSpeechesSearchProps)), el);
    });
}

},{"./SpeechesSearchAppContainer.jsx":34,"jquery":"jquery","react":"react","react-dom":"react-dom"}],37:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    selector: ".js-header",
    init: init
};


function init() {
    // stickiness will turn on after scrolling past the header
    // on load, get the height of the header
    var headerHeight = (0, _jquery2.default)(".js-header").height() || 1; // fallback value, will mutate

    // on resize, reset the header height
    var resizeTimer;
    (0, _jquery2.default)(window).on("resize", function () {
        // only check height when resize stops
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            headerHeight = (0, _jquery2.default)(".js-header").height();
        }, 250);
    });

    // rightpoint jquery upgrade change - changed scroll event to on_scroll
    (0, _jquery2.default)(window).on('scroll', function () {
        if ((0, _jquery2.default)(this).scrollTop() > headerHeight) {
            (0, _jquery2.default)('.sticky-wrapper').addClass('is-sticky');
        } else {
            (0, _jquery2.default)('.sticky-wrapper').removeClass('is-sticky');
        }
    });
}

},{"jquery":"jquery"}],38:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

require("slick-carousel");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    selector: ".js-video-banner-slider",
    init: init
};


function init() {
    (0, _jquery2.default)(".js-video-banner-slider").each(function (index, item) {

        var isRtl = (0, _jquery2.default)("html").attr("lang") === "ar";

        (0, _jquery2.default)(item).slick({
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev"><span>' + videoBannerSliderPreviousLabel + '</span></button>',
            nextArrow: '<button type="button" class="slick-next"><span>' + videoBannerSliderNextLabel + '</span></button>',
            dots: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 10000,
            rtl: isRtl,
            responsive: [{
                breakpoint: 736,
                settings: {
                    arrows: true,
                    dots: false,
                    centerMode: true,
                    centerPadding: '60px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            }]
        });
    });
}

},{"jquery":"jquery","slick-carousel":"slick-carousel"}],39:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ClearAllButton = function (_PureComponent) {
    _inherits(ClearAllButton, _PureComponent);

    function ClearAllButton() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, ClearAllButton);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ClearAllButton.__proto__ || Object.getPrototypeOf(ClearAllButton)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
            var _this$props = _this.props,
                selectedDate = _this$props.selectedDate,
                selectedContentType = _this$props.selectedContentType,
                selectedLanguage = _this$props.selectedLanguage,
                selectedKeyword = _this$props.selectedKeyword,
                clearAllLabel = _this$props.clearAllLabel,
                clearAllFilters = _this$props.clearAllFilters;


            if ((!selectedDate || selectedDate === "") && (!selectedContentType || selectedContentType === "") && (!selectedLanguage || selectedLanguage === "") && (!selectedKeyword || selectedKeyword === "")) {
                return null;
            } else {
                return _react2.default.createElement(
                    "button",
                    { className: "filtr filtr--clear-all", onClick: clearAllFilters },
                    clearAllLabel
                );
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return ClearAllButton;
}(_react.PureComponent);

ClearAllButton.propTypes = {
    selectedDate: _propTypes.string,
    selectedContentType: _propTypes.string,
    selectedLanguage: _propTypes.string,
    selectedKeyword: _propTypes.string,
    clearAllLabel: _propTypes.string,
    clearAllFilters: _propTypes.func
};
exports.default = ClearAllButton;

},{"prop-types":"prop-types","react":"react"}],40:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ContentTypeFilter = function (_PureComponent) {
	_inherits(ContentTypeFilter, _PureComponent);

	function ContentTypeFilter() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, ContentTypeFilter);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ContentTypeFilter.__proto__ || Object.getPrototypeOf(ContentTypeFilter)).call.apply(_ref, [this].concat(args))), _this), _this.handleChange = function (event) {
			var updateSelectedContentType = _this.props.updateSelectedContentType;


			updateSelectedContentType(event.target.value);
		}, _this.render = function () {
			var _this$props = _this.props,
			    selectedContentType = _this$props.selectedContentType,
			    defaultContentTypeSelectionLabel = _this$props.defaultContentTypeSelectionLabel,
			    contentTypes = _this$props.contentTypes;


			var optionList = contentTypes.map(function (item, index) {
				return _react2.default.createElement(
					"option",
					{ key: index, value: item },
					item
				);
			});

			return _react2.default.createElement(
				"div",
				{ className: "filtr filtr--content-type" },
				_react2.default.createElement(
					"select",
					{ value: selectedContentType || "", onChange: _this.handleChange },
					_react2.default.createElement(
						"option",
						{ value: "" },
						defaultContentTypeSelectionLabel
					),
					optionList
				)
			);
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return ContentTypeFilter;
}(_react.PureComponent);

ContentTypeFilter.propTypes = {
	contentTypes: _propTypes.array,
	defaultContentTypeSelectionLabel: _propTypes.string,
	selectedContentType: _propTypes.string,
	updateSelectedContentType: _propTypes.func
};
exports.default = ContentTypeFilter;

},{"prop-types":"prop-types","react":"react"}],41:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _momentLocales = require("../helpers/momentLocales.js");

var _momentLocales2 = _interopRequireDefault(_momentLocales);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DateFilter = function (_PureComponent) {
	_inherits(DateFilter, _PureComponent);

	function DateFilter() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, DateFilter);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = DateFilter.__proto__ || Object.getPrototypeOf(DateFilter)).call.apply(_ref, [this].concat(args))), _this), _this.makeOptionLabel = function (dateObj) {
			var localeString = _this.props.localeString;


			_momentLocales2.default.locale(localeString);
			return (0, _momentLocales2.default)(dateObj).format("LL");
		}, _this.handleChange = function (event) {
			var updateSelectedDate = _this.props.updateSelectedDate;


			updateSelectedDate(event.target.value);
		}, _this.render = function () {
			var _this$props = _this.props,
			    selectedDate = _this$props.selectedDate,
			    defaultDateSelectionLabel = _this$props.defaultDateSelectionLabel,
			    dateObjs = _this$props.dateObjs;


			var optionList = dateObjs.map(function (dateObj, index) {
				var label = _this.makeOptionLabel(dateObj);
				var value = (0, _momentLocales2.default)(dateObj).format("YYYY-MM-DD");

				return _react2.default.createElement(
					"option",
					{ key: index, value: value },
					label
				);
			});

			return _react2.default.createElement(
				"div",
				{ className: "filtr filtr--date" },
				_react2.default.createElement(
					"select",
					{ value: selectedDate || "", onChange: _this.handleChange },
					_react2.default.createElement(
						"option",
						{ value: "" },
						defaultDateSelectionLabel
					),
					optionList
				)
			);
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return DateFilter;
}(_react.PureComponent);

DateFilter.propTypes = {
	selectedDate: _propTypes.string,
	defaultDateSelectionLabel: _propTypes.string,
	dateObjs: _propTypes.array,
	updateSelectedDate: _propTypes.func
};
exports.default = DateFilter;

},{"../helpers/momentLocales.js":6,"prop-types":"prop-types","react":"react"}],42:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var KeywordFilter = function (_PureComponent) {
	_inherits(KeywordFilter, _PureComponent);

	function KeywordFilter() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, KeywordFilter);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = KeywordFilter.__proto__ || Object.getPrototypeOf(KeywordFilter)).call.apply(_ref, [this].concat(args))), _this), _this.handleChange = function (event) {
			var updateSelectedKeyword = _this.props.updateSelectedKeyword;


			updateSelectedKeyword(event.target.value);
		}, _this.render = function () {
			var _this$props = _this.props,
			    selectedKeyword = _this$props.selectedKeyword,
			    keywordPlaceholderLabel = _this$props.keywordPlaceholderLabel;


			return _react2.default.createElement("input", { className: "filtr filtr--keyword", type: "text", placeholder: keywordPlaceholderLabel, value: selectedKeyword || "", onChange: _this.handleChange });
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return KeywordFilter;
}(_react.PureComponent);

KeywordFilter.propTypes = {
	selectedKeyword: _propTypes.string,
	keywordPlaceholderLabel: _propTypes.string,
	updateSelectedKeyword: _propTypes.func
};
exports.default = KeywordFilter;

},{"prop-types":"prop-types","react":"react"}],43:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LanguageFilter = function (_PureComponent) {
	_inherits(LanguageFilter, _PureComponent);

	function LanguageFilter() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, LanguageFilter);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = LanguageFilter.__proto__ || Object.getPrototypeOf(LanguageFilter)).call.apply(_ref, [this].concat(args))), _this), _this.handleChange = function (event) {
			var updateSelectedLanguage = _this.props.updateSelectedLanguage;


			updateSelectedLanguage(event.target.value);
		}, _this.render = function () {
			var _this$props = _this.props,
			    selectedLanguage = _this$props.selectedLanguage,
			    defaultLanguageSelectorLabel = _this$props.defaultLanguageSelectorLabel,
			    languages = _this$props.languages;


			var optionList = languages.map(function (item, index) {
				return _react2.default.createElement(
					"option",
					{ key: index, value: item.key },
					item.value
				);
			});

			return _react2.default.createElement(
				"div",
				{ className: "filtr filtr--language" },
				_react2.default.createElement(
					"select",
					{ className: "custom-select", value: selectedLanguage || "", onChange: _this.handleChange },
					optionList,
					_react2.default.createElement(
						"option",
						{ value: "" },
						defaultLanguageSelectorLabel
					)
				)
			);
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return LanguageFilter;
}(_react.PureComponent);

LanguageFilter.propTypes = {
	languages: _propTypes.array,
	defaultLanguageSelectorLabel: _propTypes.string,
	selectedLanguage: _propTypes.string,
	updateSelectedLanguage: _propTypes.func
};
exports.default = LanguageFilter;

},{"prop-types":"prop-types","react":"react"}],44:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Pagination = function (_PureComponent) {
    _inherits(Pagination, _PureComponent);

    function Pagination() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, Pagination);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Pagination.__proto__ || Object.getPrototypeOf(Pagination)).call.apply(_ref, [this].concat(args))), _this), _this.renderPrevButton = function () {
            var _this$props = _this.props,
                page = _this$props.page,
                updatePage = _this$props.updatePage;


            if (page > 0) {
                var destinationPage = page - 1;

                return _react2.default.createElement("button", { className: "pagination__button pagination__button--prev", onClick: function onClick() {
                        return updatePage(destinationPage);
                    } });
            } else {
                return null;
            }
        }, _this.renderNextButton = function (pages) {
            var _this$props2 = _this.props,
                page = _this$props2.page,
                updatePage = _this$props2.updatePage;


            if (page + 1 < pages) {
                var destinationPage = page + 1;

                return _react2.default.createElement("button", { className: "pagination__button pagination__button--next", onClick: function onClick() {
                        return updatePage(destinationPage);
                    } });
            } else {
                return null;
            }
        }, _this.renderPagination = function (pages) {
            var _this$props3 = _this.props,
                page = _this$props3.page,
                pageLabel = _this$props3.pageLabel;


            var currentPageToShow = page + 1;

            return _react2.default.createElement(
                "span",
                null,
                currentPageToShow,
                " ",
                pageLabel,
                " ",
                pages
            );
        }, _this.render = function () {
            var _this$props4 = _this.props,
                perPage = _this$props4.perPage,
                totalResults = _this$props4.totalResults,
                isFetching = _this$props4.isFetching;


            if (isFetching) {
                return null;
            } else {
                var pages = Math.ceil(totalResults / perPage);

                var pagination = _this.renderPagination(pages);
                var prevButton = _this.renderPrevButton();
                var nextButton = _this.renderNextButton(pages);

                if (pages > 0) {
                    return _react2.default.createElement(
                        "div",
                        { className: "pagination" },
                        prevButton,
                        pagination,
                        nextButton
                    );
                } else {
                    return null;
                }
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return Pagination;
}(_react.PureComponent);

Pagination.propTypes = {
    page: _propTypes.number,
    perPage: _propTypes.number,
    totalResults: _propTypes.number,
    pageLabel: _propTypes.string,
    isFetching: _propTypes.bool,
    updatePage: _propTypes.func
};
exports.default = Pagination;

},{"prop-types":"prop-types","react":"react"}],45:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _VideoCard = require("./VideoCard.jsx");

var _VideoCard2 = _interopRequireDefault(_VideoCard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Results = function (_PureComponent) {
	_inherits(Results, _PureComponent);

	function Results() {
		var _ref;

		var _temp, _this, _ret;

		_classCallCheck(this, Results);

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key];
		}

		return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Results.__proto__ || Object.getPrototypeOf(Results)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
			var _this$props = _this.props,
			    results = _this$props.results,
			    noResultsFoundLabel = _this$props.noResultsFoundLabel,
			    localeString = _this$props.localeString,
			    isFetching = _this$props.isFetching;


			if (isFetching) {
				return _react2.default.createElement(
					"div",
					{ className: "small-vids__loading" },
					_react2.default.createElement("img", { src: "/Assets/AMSM/images/loading.gif" })
				);
			} else if (results && results.length > 0) {
				var list = results.map(function (item, index) {
					return _react2.default.createElement(_VideoCard2.default, _extends({
						key: index
					}, item, {
						localeString: localeString
					}));
				});

				return _react2.default.createElement(
					"section",
					{ className: "small-vids" },
					list
				);
			} else {
				return _react2.default.createElement(
					"section",
					{ className: "small-vids" },
					noResultsFoundLabel
				);
			}
		}, _temp), _possibleConstructorReturn(_this, _ret);
	}

	return Results;
}(_react.PureComponent);

Results.propTypes = {
	results: _propTypes.array,
	noResultsFoundLabel: _propTypes.string,
	localeString: _propTypes.string,
	isFetching: _propTypes.bool
};
exports.default = Results;

},{"./VideoCard.jsx":47,"prop-types":"prop-types","react":"react"}],46:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ResultsSummary = function (_PureComponent) {
    _inherits(ResultsSummary, _PureComponent);

    function ResultsSummary() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, ResultsSummary);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ResultsSummary.__proto__ || Object.getPrototypeOf(ResultsSummary)).call.apply(_ref, [this].concat(args))), _this), _this.render = function () {
            var _this$props = _this.props,
                totalResults = _this$props.totalResults,
                selectedKeyword = _this$props.selectedKeyword,
                numberOfResultsLabel = _this$props.numberOfResultsLabel;


            if (totalResults && totalResults > 0 && selectedKeyword && selectedKeyword !== "") {
                var tokensReplacedString = numberOfResultsLabel.replace("{0}", totalResults).replace("{1}", selectedKeyword);

                return _react2.default.createElement(
                    "div",
                    { className: "video-search-app__results-summary" },
                    tokensReplacedString
                );
            } else {
                return null;
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return ResultsSummary;
}(_react.PureComponent);

ResultsSummary.propTypes = {
    totalResults: _propTypes.number,
    selectedKeyword: _propTypes.string,
    numberOfResultsLabel: _propTypes.string
};
exports.default = ResultsSummary;

},{"prop-types":"prop-types","react":"react"}],47:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _momentLocales = require("../helpers/momentLocales.js");

var _momentLocales2 = _interopRequireDefault(_momentLocales);

var _propTypes = require("prop-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var VideoCard = function (_PureComponent) {
    _inherits(VideoCard, _PureComponent);

    function VideoCard() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, VideoCard);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = VideoCard.__proto__ || Object.getPrototypeOf(VideoCard)).call.apply(_ref, [this].concat(args))), _this), _this.convertMS = function (ms) {
            if (ms && ms > 0) {
                var secs = Math.floor(ms / 1000);
                var mins = Math.floor(secs / 60);
                secs = secs % 60;
                var hrs = Math.floor(mins / 60);
                mins = mins % 60;
                hrs = hrs % 24;

                // if less thanan hour, hours doesn't display
                var displayHours = hrs > 0 ? hrs + ":" : "";

                // if at least 1hr long, minutes should be 2-digit
                var displayMinutes = hrs > 0 && mins < 10 ? "0" + mins + ":" : mins + ":";

                // secs should always be 2-digit
                var displaySeconds = secs < 10 ? "0" + secs : secs;

                return "(" + displayHours + displayMinutes + displaySeconds + ") ";
            } else {
                return null;
            }
        }, _this.createVideoUrl = function (vidId) {
            // update (or add) the selected video id 
            // without wiping out any existing params
            var params = new URLSearchParams(location.search);

            if (vidId) {
                params.set("videoId", vidId);
            } else {
                params.delete("videoId");
            }

            return location.pathname + "?" + params;
        }, _this.render = function () {
            var _this$props = _this.props,
                poster = _this$props.poster,
                name = _this$props.name,
                videoDate = _this$props.videoDate,
                videoId = _this$props.videoId,
                localeString = _this$props.localeString,
                duration = _this$props.duration;


            var vidUrl = _this.createVideoUrl(videoId);

            _momentLocales2.default.locale(localeString);
            var vidDate = videoDate && videoDate !== "" ? (0, _momentLocales2.default)(videoDate).format("LL") : "";

            var durationString = _this.convertMS(duration);

            return _react2.default.createElement(
                "div",
                { className: "sm-vid" },
                _react2.default.createElement(
                    "a",
                    { className: "smvid-thumbnail", href: vidUrl },
                    _react2.default.createElement("img", { src: poster }),
                    _react2.default.createElement("span", { className: "play-btn" })
                ),
                _react2.default.createElement(
                    "div",
                    { className: "smvid-caption" },
                    _react2.default.createElement(
                        "h6",
                        null,
                        _react2.default.createElement(
                            "a",
                            { href: vidUrl },
                            name
                        )
                    ),
                    _react2.default.createElement(
                        "p",
                        null,
                        durationString,
                        vidDate
                    )
                )
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return VideoCard;
}(_react.PureComponent);

VideoCard.propTypes = {
    contentType: _propTypes.string,
    description: _propTypes.string,
    language: _propTypes.string,
    name: _propTypes.string,
    poster: _propTypes.string,
    speakers: _propTypes.string,
    thumbnail: _propTypes.string,
    videoDate: _propTypes.string,
    duration: _propTypes.string,
    videoId: _propTypes.string,
    localeString: _propTypes.string
};
exports.default = VideoCard;

},{"../helpers/momentLocales.js":6,"prop-types":"prop-types","react":"react"}],48:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _ramda = require("ramda");

var _ramda2 = _interopRequireDefault(_ramda);

var _propTypes = require("prop-types");

var _getSearchParam = require("../helpers/getSearchParam.js");

var _getSearchParam2 = _interopRequireDefault(_getSearchParam);

var _getValidOptionFromList = require("../helpers/getValidOptionFromList.js");

var _getValidOptionFromList2 = _interopRequireDefault(_getValidOptionFromList);

var _ResultsSummary = require("./ResultsSummary.jsx");

var _ResultsSummary2 = _interopRequireDefault(_ResultsSummary);

var _DateFilter = require("./DateFilter.jsx");

var _DateFilter2 = _interopRequireDefault(_DateFilter);

var _ContentTypeFilter = require("./ContentTypeFilter.jsx");

var _ContentTypeFilter2 = _interopRequireDefault(_ContentTypeFilter);

var _LanguageFilter = require("./LanguageFilter.jsx");

var _LanguageFilter2 = _interopRequireDefault(_LanguageFilter);

var _KeywordFilter = require("./KeywordFilter.jsx");

var _KeywordFilter2 = _interopRequireDefault(_KeywordFilter);

var _ClearAllButton = require("./ClearAllButton.jsx");

var _ClearAllButton2 = _interopRequireDefault(_ClearAllButton);

var _Results = require("./Results.jsx");

var _Results2 = _interopRequireDefault(_Results);

var _Pagination = require("./Pagination.jsx");

var _Pagination2 = _interopRequireDefault(_Pagination);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var VideoSearchAppContainer = function (_Component) {
  _inherits(VideoSearchAppContainer, _Component);

  function VideoSearchAppContainer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, VideoSearchAppContainer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = VideoSearchAppContainer.__proto__ || Object.getPrototypeOf(VideoSearchAppContainer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      selectedDate: "",
      selectedContentType: "",
      selectedLanguage: "en",
      selectedKeyword: "",
      results: null,
      page: 0,
      perPage: 15,
      totalResults: null,
      isFetching: false
    }, _this.componentWillMount = function () {
      var mergedState = _ramda2.default.mergeAll([_this.state, _this.populateInitialState()]);

      _this.setState(mergedState);
    }, _this.componentDidMount = function () {
      _this.fetchData();
    }, _this.populateInitialState = function () {
      var _this$props = _this.props,
          contentTypes = _this$props.contentTypes,
          dates = _this$props.dates,
          languages = _this$props.languages;
      var _this$state = _this.state,
          selectedDate = _this$state.selectedDate,
          selectedContentType = _this$state.selectedContentType,
          selectedLanguage = _this$state.selectedLanguage,
          selectedKeyword = _this$state.selectedKeyword,
          page = _this$state.page,
          perPage = _this$state.perPage;


      var searchParams = new URLSearchParams(window.location);

      // check URL for query params
      var pageQuery = (0, _getSearchParam2.default)("page");
      var perPageQuery = (0, _getSearchParam2.default)("perPage");
      var dateQuery = (0, _getSearchParam2.default)("date");
      var contentTypeQuery = (0, _getSearchParam2.default)("contenttype");
      var languageQuery = (0, _getSearchParam2.default)("language");
      var keywordQuery = (0, _getSearchParam2.default)("keywords");

      // make simple list of languages for checking validity
      var simpleLanguageList = languages.map(function (item, index) {
        return item.key;
      });

      // check if url params are valid options
      var date = (0, _getValidOptionFromList2.default)(selectedDate, dateQuery, dates);
      var type = (0, _getValidOptionFromList2.default)(selectedContentType, contentTypeQuery, contentTypes);
      var language = (0, _getValidOptionFromList2.default)(selectedLanguage, languageQuery, simpleLanguageList);

      return {
        page: parseInt(pageQuery) || page,
        perPage: parseInt(perPageQuery) || perPage,
        selectedDate: date || selectedDate,
        selectedContentType: type || selectedContentType,
        selectedLanguage: languageQuery || _this.props.localeString || selectedLanguage,
        keyword: keywordQuery || selectedKeyword
      };
    }, _this.getDateObjects = function () {
      var dates = _this.props.dates;


      return dates.map(function (date, index) {
        return new Date(date);
      });
    }, _this.fetchData = function () {
      _this.setState({
        isFetching: true
      });

      var serviceWithParams = _this.generateServiceUrl();

      return fetch(serviceWithParams).then(function (response) {
        return response.json();
      }).then(function (data) {
        _this.setState({
          results: data.results,
          totalResults: data.totalResults,
          isFetching: false
        });
      }).catch(function (error) {
        console.error("There has been a problem with your fetch operation: " + error.message);
      });
    }, _this.generateServiceUrl = function () {
      var _this$props2 = _this.props,
          homeNodeGuid = _this$props2.homeNodeGuid,
          serviceUrl = _this$props2.serviceUrl,
          meetingCode = _this$props2.meetingCode,
          databaseName = _this$props2.databaseName;
      var _this$state2 = _this.state,
          selectedDate = _this$state2.selectedDate,
          selectedContentType = _this$state2.selectedContentType,
          selectedLanguage = _this$state2.selectedLanguage,
          selectedKeyword = _this$state2.selectedKeyword,
          page = _this$state2.page,
          perPage = _this$state2.perPage;


      var homeNodeParam = "homeNode={" + homeNodeGuid + "}";
      var meetingParam = "meetingCode=" + meetingCode;
      var pageParam = "page=" + page;
      var perPageParam = "perPage=" + perPage;

      var dateParam = selectedDate ? "date=" + selectedDate : "date=";

      var contentTypeParam = selectedContentType ? "contenttype=" + selectedContentType : "contenttype=";

      var languageParam = selectedLanguage ? "language=" + selectedLanguage : "language=";

      var keywordParam = selectedKeyword ? "keywords=" + selectedKeyword : "keywords=";

      var databaseParam = databaseName ? "databaseName=" + databaseName : "databaseName=";

      var joinedParams = [homeNodeParam, meetingParam, pageParam, perPageParam, dateParam, contentTypeParam, languageParam, keywordParam, databaseParam].join("&");

      return serviceUrl + "?" + joinedParams;
    }, _this.updateVisibleUrl = function (paramString, newValue) {
      // update, add or remove a parameter
      // without wiping out any existing params
      var params = new URLSearchParams(location.search);

      if (newValue && newValue !== "") {
        params.set(paramString, newValue);
      } else {
        params.delete(paramString);
      }

      window.history.replaceState({}, "", location.pathname + "?" + params);
    }, _this.updateSelectedDate = function (newDate) {
      var selectedDate = _this.state.selectedDate;


      _this.updateVisibleUrl("page", "");
      _this.updateVisibleUrl("date", newDate);

      _this.setState({
        selectedDate: newDate,
        page: 0
      }, function () {
        _this.fetchData();
      });
    }, _this.updateSelectedContentType = function (newType) {
      var selectedContentType = _this.state.selectedContentType;


      _this.updateVisibleUrl("page", "");
      _this.updateVisibleUrl("contenttype", newType);

      _this.setState({
        selectedContentType: newType,
        page: 0
      }, function () {
        _this.fetchData();
      });
    }, _this.updateSelectedLanguage = function (newLanguage) {
      var selectedLanguage = _this.state.selectedLanguage;


      _this.updateVisibleUrl("page", "");
      _this.updateVisibleUrl("language", newLanguage);

      _this.setState({
        selectedLanguage: newLanguage,
        page: 0
      }, function () {
        _this.fetchData();
      });
    }, _this.updateSelectedKeyword = function (newKeyword) {
      var selectedKeyword = _this.state.selectedKeyword;


      _this.updateVisibleUrl("page", "");
      _this.updateVisibleUrl("keywords", newKeyword);

      _this.setState({
        selectedKeyword: newKeyword,
        page: 0
      }, function () {
        _this.fetchData();
      });
    }, _this.updatePage = function (newPage) {
      var page = _this.state.page;


      _this.updateVisibleUrl("page", newPage);

      _this.setState({
        page: newPage
      }, function () {
        _this.fetchData();
      });
    }, _this.clearAllFilters = function () {
      // wishing the URLSearchParams delete functionality allowed you
      // to pass multiple param keys, but it doesn't :/
      _this.updateVisibleUrl("date", "");
      _this.updateVisibleUrl("contenttype", "");
      _this.updateVisibleUrl("language", "");
      _this.updateVisibleUrl("keywords", "");
      _this.updateVisibleUrl("page", "");

      _this.setState({
        selectedDate: "",
        selectedContentType: "",
        selectedLanguage: "",
        selectedKeyword: "",
        page: 0
      }, function () {
        _this.fetchData();
      });
    }, _this.render = function () {
      var _this$props3 = _this.props,
          defaultDateSelectionLabel = _this$props3.defaultDateSelectionLabel,
          contentTypes = _this$props3.contentTypes,
          defaultContentTypeSelectionLabel = _this$props3.defaultContentTypeSelectionLabel,
          languages = _this$props3.languages,
          defaultLanguageSelectorLabel = _this$props3.defaultLanguageSelectorLabel,
          keywordPlaceholderLabel = _this$props3.keywordPlaceholderLabel,
          noResultsFoundLabel = _this$props3.noResultsFoundLabel,
          localeString = _this$props3.localeString,
          pageSepatorLabel = _this$props3.pageSepatorLabel,
          clearAllLabel = _this$props3.clearAllLabel,
          numberOfResultsLabel = _this$props3.numberOfResultsLabel;
      var _this$state3 = _this.state,
          selectedDate = _this$state3.selectedDate,
          selectedContentType = _this$state3.selectedContentType,
          selectedLanguage = _this$state3.selectedLanguage,
          selectedKeyword = _this$state3.selectedKeyword,
          results = _this$state3.results,
          page = _this$state3.page,
          perPage = _this$state3.perPage,
          totalResults = _this$state3.totalResults,
          isFetching = _this$state3.isFetching;


      return _react2.default.createElement(
        "section",
        { className: "video-search-app" },
        _react2.default.createElement(_ResultsSummary2.default, {
          totalResults: totalResults,
          selectedKeyword: selectedKeyword,
          numberOfResultsLabel: numberOfResultsLabel
        }),
        _react2.default.createElement(
          "div",
          { className: "video-search-app__filters" },
          _react2.default.createElement(_DateFilter2.default, {
            dateObjs: _this.getDateObjects(),
            defaultDateSelectionLabel: defaultDateSelectionLabel,
            selectedDate: selectedDate,
            localeString: localeString,
            updateSelectedDate: _this.updateSelectedDate
          }),
          _react2.default.createElement(_ContentTypeFilter2.default, {
            contentTypes: contentTypes,
            defaultContentTypeSelectionLabel: defaultContentTypeSelectionLabel,
            selectedContentType: selectedContentType,
            updateSelectedContentType: _this.updateSelectedContentType
          }),
          _react2.default.createElement(_LanguageFilter2.default, {
            languages: languages,
            defaultLanguageSelectorLabel: defaultLanguageSelectorLabel,
            selectedLanguage: selectedLanguage,
            updateSelectedLanguage: _this.updateSelectedLanguage
          }),
          _react2.default.createElement(_KeywordFilter2.default, {
            selectedKeyword: selectedKeyword,
            keywordPlaceholderLabel: keywordPlaceholderLabel,
            updateSelectedKeyword: _this.updateSelectedKeyword
          }),
          _react2.default.createElement(_ClearAllButton2.default, {
            selectedDate: selectedDate,
            selectedContentType: selectedContentType,
            selectedLanguage: selectedLanguage,
            selectedKeyword: selectedKeyword,
            clearAllLabel: clearAllLabel,
            clearAllFilters: _this.clearAllFilters
          })
        ),
        _react2.default.createElement(_Results2.default, {
          results: results,
          noResultsFoundLabel: noResultsFoundLabel,
          localeString: localeString,
          isFetching: isFetching
        }),
        _react2.default.createElement(_Pagination2.default, {
          page: page,
          perPage: perPage,
          totalResults: totalResults,
          pageLabel: pageSepatorLabel,
          isFetching: isFetching,
          updatePage: _this.updatePage
        })
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  return VideoSearchAppContainer;
}(_react.Component);

VideoSearchAppContainer.propTypes = {
  serviceUrl: _propTypes.string,
  homeNodeGuid: _propTypes.string,
  meetingCode: _propTypes.string,
  localeString: _propTypes.string,
  contentTypes: _propTypes.array,
  dates: _propTypes.array,
  languages: _propTypes.array,
  defaultDateSelectionLabel: _propTypes.string,
  defaultContentTypeSelectionLabel: _propTypes.string,
  defaultLanguageSelectorLabel: _propTypes.string,
  keywordPlaceholderLabel: _propTypes.string,
  noResultsFoundLabel: _propTypes.string,
  pageSepatorLabel: _propTypes.string,
  clearAllLabel: _propTypes.string,
  numberOfResultsLabel: _propTypes.string,
  databaseName: _propTypes.string
};
exports.default = VideoSearchAppContainer;

},{"../helpers/getSearchParam.js":4,"../helpers/getValidOptionFromList.js":5,"./ClearAllButton.jsx":39,"./ContentTypeFilter.jsx":40,"./DateFilter.jsx":41,"./KeywordFilter.jsx":42,"./LanguageFilter.jsx":43,"./Pagination.jsx":44,"./Results.jsx":45,"./ResultsSummary.jsx":46,"prop-types":"prop-types","ramda":"ramda","react":"react"}],49:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _reactDom = require("react-dom");

var _reactDom2 = _interopRequireDefault(_reactDom);

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

var _VideoSearchAppContainer = require("./VideoSearchAppContainer.jsx");

var _VideoSearchAppContainer2 = _interopRequireDefault(_VideoSearchAppContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var videoSearchAppClass = ".js-video-search-app";

exports.default = {
    selector: videoSearchAppClass,
    init: init
};


function init() {
    (0, _jquery2.default)(videoSearchAppClass).each(function (index, el) {
        _reactDom2.default.render(_react2.default.createElement(_VideoSearchAppContainer2.default, _extends({
            id: index
        }, window.backendVideoSearchProps)), el);
    });
}

},{"./VideoSearchAppContainer.jsx":48,"jquery":"jquery","react":"react","react-dom":"react-dom"}],50:[function(require,module,exports){
"use strict";

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _jquery2.default)(document).ready(function () {
    loadVideos();
}); //java script files added by Divya-AER-ENHC0019627 - Playback api change
//rightpoint jquery upgrade change - imported jquery as jquery link was removed from indiviual rendering and this js requires jquery

function loadVideos() {
    var playlistId = (0, _jquery2.default)("#VideoPagePlaylistID").val();
    var defaultVideoId = (0, _jquery2.default)("#VideoPageDefaultVideoId").val();
    var accountId = (0, _jquery2.default)("#VideoPagePlayerAccountId").val();
    var maxLimit = (0, _jquery2.default)("#VideoPageMaxPlaylistVideos").val();
    var isSingleVideo = (0, _jquery2.default)("#VideoPageIsSingleVideo").val();
    var isPlaylist = (0, _jquery2.default)("#VideoPageIsPlaylist").val();
    var baseURL = (0, _jquery2.default)("#PlaybackApiBaseURL").val();
    var policykey = (0, _jquery2.default)('#videoBannerPolicyKey').val();
    var singlevideourl = baseURL + "accounts/" + accountId + "/videos/" + defaultVideoId;
    var apiurl = baseURL + "accounts/" + accountId + "/playlists/" + playlistId + "?limit=" + maxLimit;
    var reqHeader = { "BCOV-Policy": policykey };
    if (baseURL !== undefined && baseURL != null && baseURL !== '' && accountId != null && accountId != '' && policykey != null && policykey != '') {

        if (isSingleVideo.toLowerCase() != "false" && defaultVideoId != null && defaultVideoId != '') {

            // call the playback api for single video to fetch *Video Banner* details with the above details  
            _jquery2.default.ajax({
                url: singlevideourl,
                headers: reqHeader,
                method: 'GET',
                dataType: 'JSON',
                data: {},
                success: function success(data) {
                    // on success bind the data to video banner component 
                    var obj = JSON.stringify(data);
                    BindDefaultVideo(obj);
                },
                error: function error(_error) {
                    console.log(_error);
                }
            });
        }
        if (isPlaylist.toLowerCase() != "false" && playlistId != null && playlistId != '') {
            // call the playback api for palylist to fetch *Featured Video* details with the above details  
            _jquery2.default.ajax({
                url: apiurl,
                headers: reqHeader,
                method: 'GET',
                dataType: 'JSON',
                data: {},
                success: function success(data) {
                    // on success if single video is not present, take the first video from fetured video and bind  data to video banner component 
                    var obj = data.videos;
                    var length = obj.length;
                    if (isSingleVideo.toLowerCase() == "false" && length > 0) {
                        BindDefaultVideo(JSON.stringify(obj[0]));
                    }
                    // on success bind the videos to html and add the combined html to Main div (#BannerFeaturedVideoComponentUI)
                    var videoHtml = '';
                    _jquery2.default.each(obj, function (key, value) {
                        var htmlelem = '<li><img  src="posterimage" alt="data.title"/><div class="ftcar-caption"><h6><a href="data.poster">data.name </a></h6></div></li>';
                        htmlelem = htmlelem.toString();
                        var name = value.name;
                        var videoid = value.id;
                        var posterImage = value.poster;
                        var url = "?videoId=" + videoid;
                        htmlelem = htmlelem.replace('data.name', name);
                        htmlelem = htmlelem.replace('posterimage', value.poster_sources[1].src);
                        htmlelem = htmlelem.replace('data.poster', url);
                        htmlelem = htmlelem.replace('data.title', name);
                        videoHtml += htmlelem;
                    });
                    (0, _jquery2.default)("#BannerFeaturedVideoComponentUI").html((0, _jquery2.default)("#BannerFeaturedVideoComponentUI").html() + videoHtml);

                    // call  flexslider for slider 
                    (0, _jquery2.default)('.flexslider').flexslider({
                        animation: "slide",
                        animationLoop: false,
                        itemWidth: 361,
                        itemMargin: 5,
                        minItems: 1,
                        maxItems: 3,
                        pausePlay: false,
                        start: function start(slider) {
                            (0, _jquery2.default)('body').removeClass('loading');
                        }
                    });
                },
                error: function error(_error2) {
                    console.log(_error2);
                }
            });
        }
    }
}

//bind the data to video banner component 
function BindDefaultVideo(jsonString) {
    var json = JSON.parse(jsonString);
    var htmlelem = '<video  controls data-application-id data-video-id="data.vid" data-account="data.accountId"  data-player= "data.playerID"  data-embed="dataEmbed"   class="video-js"  style="videoInlineStyle"></video';
    htmlelem = htmlelem.toString();
    htmlelem = htmlelem.replace('data.vid', json.id);
    htmlelem = htmlelem.replace('data.accountId', (0, _jquery2.default)("#VideoPagePlayerAccountId").val());
    htmlelem = htmlelem.replace('data.playerID', (0, _jquery2.default)('#videoBannerPlayerId').val());
    htmlelem = htmlelem.replace('dataEmbed', (0, _jquery2.default)('#videoBannerDataEmbed').val());
    htmlelem = htmlelem.replace('videoInlineStyle', (0, _jquery2.default)('#videoBannerVideoInlineStyle').val());
    // bind Video to banner 
    (0, _jquery2.default)("#bannerDivVideoPlaceHolder").html((0, _jquery2.default)("#bannerDivVideoPlaceHolder").html() + htmlelem);
    (0, _jquery2.default)("#bannerDivVideoPlaceHolder").removeClass("content-hide");
    // bind Title to banner 
    (0, _jquery2.default)("#h3ActiveVideoTitle").html(json.name);

    // bind Duration  to banner 
    (0, _jquery2.default)("#pActiveVideoLength").append('<span class="vid-duration">(' + formattMiliseconds(json.duration) + ')</span>');
    // bind VideoDate  to banner 
    if (json.custom_fields != null && json.custom_fields.videodate != '') {
        var date = getFormattedDate(json.custom_fields.videodate);
        (0, _jquery2.default)("#pActiveVideoLength").append('<span class="vid-date" id="vid-formatedDate"></span>');
    }
    // bind Description  to banner 
    (0, _jquery2.default)("#pActiveVideoShrtDesc").html(json.description);

    // bind Speakers  to banner
    if (json.custom_fields.speakers != null && json.custom_fields.speakers != '') {
        var html = '<label class="expander__trigger" for="video-speakers">' + (0, _jquery2.default)('#videoBannerSpeakerLabelText').val() + '</label><input class="expander__checkbox" type="checkbox" id="video-speakers" /><div class="expander__hidden-content" id="bannerSpeakerDiv"> <ul id =id="bannerSpeakerUl">ulcontent</ul></div>';
        var ulhtml = '';
        var speaker = json.custom_fields.speakers.split(';');
        _jquery2.default.each(speaker, function (key, value) {
            if (value != '') ulhtml += '<li>' + value + '</li>';
        });
        html = html.replace('ulcontent', ulhtml);
        (0, _jquery2.default)("#pActiveVideoShrtDesc").after(html);
    }

    // bind RealtedLinks  to banner
    if (json.link != null) {
        var html = '<label class="expander__trigger" for="video-link">' + (0, _jquery2.default)('#videoBannervideoRelatedLinks').val() + '</label><input class="expander__checkbox" type="checkbox" id="video-link" /><div class="expander__hidden-content"><a href="' + json.link.url + '">' + json.link.text + '</a></div>';
        (0, _jquery2.default)("#pActiveVideoShrtDesc").after(html);
    }
}

// function to convert millisecond to hh:mm:ss
function formattMiliseconds(duration) {
    if (isNaN(duration) || duration < 0) {
        return '';
    }
    var milliseconds = parseInt(duration % 1000 / 100),
        seconds = parseInt(duration / 1000 % 60),
        minutes = parseInt(duration / (1000 * 60) % 60),
        hours = parseInt(duration / (1000 * 60 * 60) % 24);

    var pad = function pad(n) {
        return n < 10 ? '0' + n : n;
    };
    if (hours >= 1) {
        return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
    } else {
        return pad(minutes) + ":" + pad(seconds);
    }
}

// get the formatted date for Video Banner  video date
function getFormattedDate(datetime) {
    _jquery2.default.get("/api/sitecore/VideoBanner/GetFormattedDateString?date=" + datetime, function (data, status) {
        if (status = "success") {
            (0, _jquery2.default)("#vid-formatedDate").html(data);
        }
    });
    return '';
}

},{"jquery":"jquery"}],51:[function(require,module,exports){
// shim for using process in browser
var process = module.exports = {};

// cached from whatever global is present so that test runners that stub it
// don't break things.  But we need to wrap it in a try catch in case it is
// wrapped in strict mode code which doesn't define any globals.  It's inside a
// function because try/catches deoptimize in certain engines.

var cachedSetTimeout;
var cachedClearTimeout;

function defaultSetTimout() {
    throw new Error('setTimeout has not been defined');
}
function defaultClearTimeout () {
    throw new Error('clearTimeout has not been defined');
}
(function () {
    try {
        if (typeof setTimeout === 'function') {
            cachedSetTimeout = setTimeout;
        } else {
            cachedSetTimeout = defaultSetTimout;
        }
    } catch (e) {
        cachedSetTimeout = defaultSetTimout;
    }
    try {
        if (typeof clearTimeout === 'function') {
            cachedClearTimeout = clearTimeout;
        } else {
            cachedClearTimeout = defaultClearTimeout;
        }
    } catch (e) {
        cachedClearTimeout = defaultClearTimeout;
    }
} ())
function runTimeout(fun) {
    if (cachedSetTimeout === setTimeout) {
        //normal enviroments in sane situations
        return setTimeout(fun, 0);
    }
    // if setTimeout wasn't available but was latter defined
    if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
        cachedSetTimeout = setTimeout;
        return setTimeout(fun, 0);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedSetTimeout(fun, 0);
    } catch(e){
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't trust the global object when called normally
            return cachedSetTimeout.call(null, fun, 0);
        } catch(e){
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error
            return cachedSetTimeout.call(this, fun, 0);
        }
    }


}
function runClearTimeout(marker) {
    if (cachedClearTimeout === clearTimeout) {
        //normal enviroments in sane situations
        return clearTimeout(marker);
    }
    // if clearTimeout wasn't available but was latter defined
    if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
        cachedClearTimeout = clearTimeout;
        return clearTimeout(marker);
    }
    try {
        // when when somebody has screwed with setTimeout but no I.E. maddness
        return cachedClearTimeout(marker);
    } catch (e){
        try {
            // When we are in I.E. but the script has been evaled so I.E. doesn't  trust the global object when called normally
            return cachedClearTimeout.call(null, marker);
        } catch (e){
            // same as above but when it's a version of I.E. that must have the global object for 'this', hopfully our context correct otherwise it will throw a global error.
            // Some versions of I.E. have different rules for clearTimeout vs setTimeout
            return cachedClearTimeout.call(this, marker);
        }
    }



}
var queue = [];
var draining = false;
var currentQueue;
var queueIndex = -1;

function cleanUpNextTick() {
    if (!draining || !currentQueue) {
        return;
    }
    draining = false;
    if (currentQueue.length) {
        queue = currentQueue.concat(queue);
    } else {
        queueIndex = -1;
    }
    if (queue.length) {
        drainQueue();
    }
}

function drainQueue() {
    if (draining) {
        return;
    }
    var timeout = runTimeout(cleanUpNextTick);
    draining = true;

    var len = queue.length;
    while(len) {
        currentQueue = queue;
        queue = [];
        while (++queueIndex < len) {
            if (currentQueue) {
                currentQueue[queueIndex].run();
            }
        }
        queueIndex = -1;
        len = queue.length;
    }
    currentQueue = null;
    draining = false;
    runClearTimeout(timeout);
}

process.nextTick = function (fun) {
    var args = new Array(arguments.length - 1);
    if (arguments.length > 1) {
        for (var i = 1; i < arguments.length; i++) {
            args[i - 1] = arguments[i];
        }
    }
    queue.push(new Item(fun, args));
    if (queue.length === 1 && !draining) {
        runTimeout(drainQueue);
    }
};

// v8 likes predictible objects
function Item(fun, array) {
    this.fun = fun;
    this.array = array;
}
Item.prototype.run = function () {
    this.fun.apply(null, this.array);
};
process.title = 'browser';
process.browser = true;
process.env = {};
process.argv = [];
process.version = ''; // empty string to avoid regexp issues
process.versions = {};

function noop() {}

process.on = noop;
process.addListener = noop;
process.once = noop;
process.off = noop;
process.removeListener = noop;
process.removeAllListeners = noop;
process.emit = noop;
process.prependListener = noop;
process.prependOnceListener = noop;

process.listeners = function (name) { return [] }

process.binding = function (name) {
    throw new Error('process.binding is not supported');
};

process.cwd = function () { return '/' };
process.chdir = function (dir) {
    throw new Error('process.chdir is not supported');
};
process.umask = function() { return 0; };

},{}],52:[function(require,module,exports){
(function (setImmediate,clearImmediate){
var nextTick = require('process/browser.js').nextTick;
var apply = Function.prototype.apply;
var slice = Array.prototype.slice;
var immediateIds = {};
var nextImmediateId = 0;

// DOM APIs, for completeness

exports.setTimeout = function() {
  return new Timeout(apply.call(setTimeout, window, arguments), clearTimeout);
};
exports.setInterval = function() {
  return new Timeout(apply.call(setInterval, window, arguments), clearInterval);
};
exports.clearTimeout =
exports.clearInterval = function(timeout) { timeout.close(); };

function Timeout(id, clearFn) {
  this._id = id;
  this._clearFn = clearFn;
}
Timeout.prototype.unref = Timeout.prototype.ref = function() {};
Timeout.prototype.close = function() {
  this._clearFn.call(window, this._id);
};

// Does not start the time, just sets up the members needed.
exports.enroll = function(item, msecs) {
  clearTimeout(item._idleTimeoutId);
  item._idleTimeout = msecs;
};

exports.unenroll = function(item) {
  clearTimeout(item._idleTimeoutId);
  item._idleTimeout = -1;
};

exports._unrefActive = exports.active = function(item) {
  clearTimeout(item._idleTimeoutId);

  var msecs = item._idleTimeout;
  if (msecs >= 0) {
    item._idleTimeoutId = setTimeout(function onTimeout() {
      if (item._onTimeout)
        item._onTimeout();
    }, msecs);
  }
};

// That's not how node.js implements it but the exposed api is the same.
exports.setImmediate = typeof setImmediate === "function" ? setImmediate : function(fn) {
  var id = nextImmediateId++;
  var args = arguments.length < 2 ? false : slice.call(arguments, 1);

  immediateIds[id] = true;

  nextTick(function onNextTick() {
    if (immediateIds[id]) {
      // fn.call() is faster so we optimize for the common use-case
      // @see http://jsperf.com/call-apply-segu
      if (args) {
        fn.apply(null, args);
      } else {
        fn.call(null);
      }
      // Prevent ids from leaking
      exports.clearImmediate(id);
    }
  });

  return id;
};

exports.clearImmediate = typeof clearImmediate === "function" ? clearImmediate : function(id) {
  delete immediateIds[id];
};
}).call(this,require("timers").setImmediate,require("timers").clearImmediate)
},{"process/browser.js":51,"timers":52}]},{},[9]);
